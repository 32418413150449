import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    dashboard: {
                        nameIdentifier: "Name or identifier",
                        efficiencyAll: "Efficiency of all machines",
                        onlyErrors: "Only errors",
                        toggleDisconnected: "Toggle disconnected machines",
                        productionCombined: "Combined production (Wh)",
                        realisedTechnicalEfficiency: "Realised / Technical efficiency (%)",
                        efficiencyCombined: "Realised efficiency (%)",
                        technicalEfficiencyCombined: "Technical efficiency (%)",
                        bgp: "Bgp (m³/ton)",
                        consumption: "Consumption (Wh)",
                        manureLifeTime: "Manure life time (days)",
                        feeding: "Feeding (kg)"
                    },
                    common: {
                        on: "On",
                        off: "Off",
                        add: "Add",
                        save: "Save",
                        monday: "Monday",
                        tuesday: "Tuesday",
                        wednesday: "Wednesday",
                        thursday: "Thursday",
                        friday: "Friday",
                        saturday: "Saturday",
                        sunday: "Sunday",
                        from: "From",
                        to: "To",
                        hours: "hours",
                        reset: "Reset",
                        confirmation: "Confirmation",
                        cancel: "Cancel",
                        set: "Adjust",
                        auto: "Auto",
                        manual: "Manual",
                        minutes: "minutes",
                        seconds: "seconds",
                        turnOn: "Turn on",
                        turnOff: "Turn off",
                        graphLoading: "Graphs loading...",
                        noData: "No data Available...",
                        hoursPerYear: "hours / year",
                        datetime: "Datetime",
                        warning: "Warning",
                        error: "Error",
                        type: "Type",
                        startdate: "Start date",
                        enddate: "End date",
                        all: "All",
                        engine1: "Engine 1",
                        engine2: "Engine 2",
                        ongoing: "Ongoing",
                        both: "Both",
                        days: "Days",
                        today: "Today",
                        sendAnswers: "Send answers",
                        employee: "Employee",
                        customer: "Customer",
                        sales: "Sales",
                        technician: "Technician",
                        admin: "Admin",
                        noDateKnown: "No date known",
                        command: "Command",
                        user: "User",
                        fillInSurvey: "Please fill in the survey",
                        tons: "tons",
                        count: "Count",
                        disabled: "Disabled",
                        addField: "Add field",
                        deleteField: "Delete field",
                        releases: "Release notes",
                        open: "Open",
                        close: "Close",
                    },
                    menu: {
                        dashboard: 'Dashboard',
                        machines: 'Machines',
                        questionnaires: 'Questionnaires',
                        banners: "Banners",
                        errors: "Errors and warnings",
                        pcb: "Printed circuit boards",
                        tasks: "My tasks",
                        releases: "Release notes",
                        resellers: "Resellers",
                        users: "Users",
                        recommendations: "Recommendations",
                        suggestions: "Recommendation messages",
                        language: "Language",
                        reconnection: "This machine is currently offline. Try getting a new connection with the button below.",
                        profile: "Profile",
                        logout: "Log out",
                        firmware: "Firmware",
                        sms: "SMS",
                        advanced: "Advanced",
                        errors: "Errors",
                        warnings: "Warnings",
                    },
                    statistics: {
                        label: "Statistics",
                        group1: "Group 1",
                        group2: "Group 2",
                        addProperty: "Add property",
                        removeProperty: "Remove Property",
                        startTutorial: "Start tutorial",
                        exitTutorial: "Exit tutorial",

                        welcomeStep: {
                            title: "Welcome to the statistics page",
                            content: "Here, you can compare different values and trends with ease."
                        },
                        propertyStep: {
                            title: "Property selection",
                            content: "To get started, simply select a property that interests you from the select box."
                        },
                        addPropertyStep: {
                            title: "Adding and removing properties",
                            content: "Try adding or removing a property, you can add up to 4 properties per group."
                        },
                        groupStep: {
                            title: "Enabling group 2",
                            content: "Click the 'Add Property' button to select a property for Group 2. Properties in this group will be shown on the right side of the graph."
                        },
                        dateRangeStep: {
                            title: "Selecting a date range",
                            content: "Use this button to choose a date range."
                        },
                        zoomInStep: {
                            title: "Zooming in",
                            content: "Drag your mouse over the graph to select an area for zooming in."
                        },
                        zoomOutStep: {
                            title: "Zooming out",
                            content: "Reset to the previously selected date range by using the 'Zoom Out' button."
                        },
                        domainStep: {
                            title: "Adjusting domains",
                            content: "You can customize the maximum and minimum domain per group by using these buttons."
                        },
                        csvStep: {
                            title: "Download csv data",
                            content: "Download the currently displayed graph data in CSV format"
                        },
                        imageStep: {
                            title: "Download image",
                            content: "Download the currently displayed graph as an image"
                        },
                        controlCardTemp: "Control card temperature",
                        lowTensionError: "Low tension error",
                        overTempFlueGasHeatExch: "Over temperature flue gas heat exchanger",
                        feedingCycleLevel: "Feeding cycle level",
                        batteryVoltage: "Battery voltage",
                        waterPressure: "Water pressure",
                        shortInterval: "10 Minute Intervals",
                        dailyInterval: "Daily Intervals",
                    },
                    smart: {
                        label: "Smart",
                        heightOffset: "Height offset",
                        active: "Active",
                        passive: "Passive",
                        preActive: "Pre Active",
                        prePassive: "Pre Passive",
                        postActive: "Post Active",
                        postPassive: "Post Passive",
                        activeDesc: "During an active period, the planned energy production is maximized. Before each active period, a “pre-active” time slot is automatically provided in which the gas buffer is built up. The maximum duration of an active period is determined by the volume of the gas buffer, the number and type of installed CHP(s) and the biogas production profile of the installation. When the minimum filling level of the gas buffer is reached, energy production will be stopped (post-active period).",
                        passiveDesc: "During a passive period, energy is only produced if there is a surplus of gas. Before each passive period, a time slot is automatically provided during which the gas buffer is reduced. The maximum duration of a passive period is determined by the volume of the gas buffer and the biogas production profile of the installation. When the maximum filling level of the gas buffer is reached, energy production will start (post-passive period).",
                        permanence: "Stimulate constant production by dynamically running on one engine with a non-maximum gas buffer",
                        permanency: "Permanence",
                        activateStep: {
                            title: "Activating SMART",
                            content: "Activate SMART to control the production profile of the installation"
                        },
                        timeslotStep: {
                            title: "Add time slot",
                            content: "Add an active or passive time slot"
                        },
                        fromToStep: {
                            title: "From / To",
                            content: "You set both the start and end day and time"
                        },
                        activePassiveStep: {
                            title: "Active or Passive",
                            content: "Select whether you want to set an active or passive time slot"
                        },
                        saveStep: {
                            title: "Save time slots",
                            content: "Save your changed settings"
                        },
                        removeStep: {
                            title: "Remove time slots",
                            content: "Remove your time slot here"
                        },
                        visualStep: {
                            title: "Visual representation",
                            content: "This graph compares the SMART settings with the production profile of the past week"
                        },
                        daySwitchStep: {
                            title: "Visual representation",
                            content: "Easily navigate to the next or previous day"
                        },
                    },
                    gas: {
                        label: "Gas",
                        minGasBufferLevel: "Gas buffer min",
                        maxGasBufferLevel: "Gas buffer max",
                        gasBufferLevel: "Actual gas buffer ",
                        defaultMinGasBufferLevel: "Default gas buffer min",
                        defaultMaxGasBufferLevel: "Default gas buffer max",
                        airInjectionTime: "Aeration",
                        maxAirInjectionTime: "Max aeration",
                        torch: "Flare",
                        torchType: "Flare type",
                        torchStatus: "Flare status",
                        torchError: "Flare error",
                        h2s: "H₂S",
                        h2sLevelReactor: "H₂S reactor",
                        h2sLevelMotor: "H₂S engine",
                        filterLoad: "Carbon filter load",
                        sample: "Sample",
                        carbonFilterSize: "Carbon filter size",
                        h2sMeasurements: "H₂S measurements",
                        measurements: "Measurements",
                        changeTo: "Change to",
                        none: "None",
                        container: "Container",
                        extern: "Extern",
                        foamBeaterInstalled: "Foam beater installed",
                        antiFoamInjectionsInstalled: "Anti foam injections installed",
                    },
                    engine: {
                        label: "engine",
                        startEngine: "Start Engine",
                        stopEngine: "Stop Engine",
                        power: "Power",
                        desiredPower: "Set Power",
                        maxPower: "Maximum power",
                        rpm: "Rotations per minute (RPM)",
                        lambda: "Lambda",
                        throttleValve: "Servo position",
                        engineType: "Engine type",
                        engineStatus: "Engine status",
                        engineError: "Engine Errors",
                        producedEnergy: "Total energy produced",
                        operatingHours: "Operating hours",
                        totalOperatingHours: "Total operating hours",
                        gasUsage: "Gas consumption",
                        throttlePosition: "Throttle position",
                        noErrors: "All systems operational.",
                        production: "Production",
                        servoManually: "Servo is set manually",
                        servoAuto: "Servo is set automatically",
                        servoControl: "Servo control",
                        temperature: "Engine Temperature",
                        restart: "Restart automatically when gas available.",
                        idle: "Idle",
                        running: "Running",
                        waiting: "Waiting for boot up",
                        booting: "Booting up",
                        shuttingDown: "Shutting down",
                        advanced: "Advanced",
                        numberOfEngines: "Number of engines",
                        predeterminedRunHours: "Predetermined run hours",
                        easyStart1: "Easy start engine 1",
                        easyStart2: "Easy start engine 2",
                        setError1: "Set error engine 1",
                        setError2: "Set error engine 2",
                        fuel: "Fuel",
                        airIntakeOpen: "Air intake open",
                        airIntakeClosed: "Air intake closed",
                    },
                    service: {
                        label: "Service",
                        lastMaintenance: "Operating hours until next oil change",
                        currentlyInstalled: "Operating hours currently installed",
                        total: "Total operating hours",
                        mixer: "Mixer",
                        foamBeater: "Foam beater",
                        intakePump: "Intake pump",
                        digestatePump: "Digestate pump",
                        newEngine: "New engine installed",
                        newMixer: "New mixer installed",
                        newEngine: "New engine installed",
                        newFoamBeater: "New foam beater installed",
                        newIntakePump: "New intake pump installed",
                        newDigestatePump: "New digestate pump installed",
                        confirmationMessage: "Are you sure you want to reset the {{type}}",
                        maintenanceMessage: "Maintenance hours has been reset for {{type}}",
                        newEquipmentMessage: "New equipment installed:",
                        prints: "Prints",
                        firstOnline: "First online",
                        lastOnline: "Last online",
                        serial: "Serial",
                        type: "Type",
                        engine: "Engine",
                        engine1: "Engine 1",
                        engine2: "Engine 2",
                        h2s1: "H₂S Engine 1",
                        h2s2: "H₂S Engine 2",
                        resetTo: "Reset to x hours (default 0)",
                        timeFilterReplace: "Time since filter replacement",
                        daysSinceOil: "Days since oil change",
                        newGenerator: "New generator installed",
                        generator: "Generator",
                        generator1: "Generator 1",
                        generator2: "Generator 2",
                        daysSinceOilChange: "Days since oil change",
                        videoMessage: "Video on changing oil => Documents page, under General videos.",
                        videoRoom: "A service technician has opened a video room for this machine, Click here to join",
                        videoButton: "Start a video room for this machine",
                        videoButtonSub: "Users with access can join the call from their service page",
                        videoClose: "Close video room"
                    },
                    net: {
                        net: "Grid",
                        meterSettings: "Meter settings",
                        voltagePerPhase1: "Voltage per phase (1)",
                        voltagePerPhase2: "Voltage per phase (2)",
                        voltagePerPhase3: "Voltage per phase (3)",
                        frequencyPerPhase: "Frequency per phase",
                        angleBetweenPhase1And2: "Angle between phase 1 and 2",
                        ownPowerConsumption: "Power own consumption",
                        ownEnergyConsumption: "Energy own consumption",
                        bioguardVersion: "Bioguard version",
                        brutoProducedEnergy: "Gross produced energy",
                        nettoProducedEnergy: "Net produced energy",
                        producedEnergy: "Produced energy",
                        producedEnergyEngine1: "Produced energy engine 1",
                        producedEnergyEngine2: "Produced energy engine 2",
                        monitoringRelay: "Monitoring relay",
                    },
                    reactor: {
                        label: "Reactor",
                        reactorTemp: "Temperature reactor",
                        reactorTempTarget: "Target reactor temperature",
                        reactorLevel: "Desired reactor level",
                        hydrostaticPressure: "Reactor level actual",
                        siloType: "Silo type",
                        extraHeating: "Extra heating active",
                        antiFrost: "Anti frost",
                        antiFrostIndex: "Anti frost index",
                        antiFrostAuto: "The frost index is set automatically based on the local temperature",
                        antiFrostMessageNew: "Each frost-index cycle will activate the intake pump for 4 s and the exit pump will evacuate approx. 100 l of digestate, based on the set flow rate.",
                        antiFrostMessageOld: "Each frost-index cycle will activate the intake pump for 4 s and the exit pump for 120 s.",
                        coolwater: "Coolwater",
                        statusCoolingValve: "Coolwater valve",
                        waterTempIn: "Temperature coolwater reactor in",
                        waterTempOut: "Temperature coolwater reactor out",
                        waterTempMotorOut: "Temperature coolwater engine 1 out",
                        waterTempMotorOut2: "Temperature coolwater engine 2 out",
                        coolingWaterTemp: "Desired coolwater temperature",
                        maxCoolingWaterTemp: "Maximum coolwater temperature",
                        waterPressure: "Water pressure in the cold water circuit",
                        foamBeater: "Foam",
                        foambeatEveryXMinutes: "Beat foam every",
                        foambeatForXSeconds: "For",
                        mixer: "Mixer",
                        mixerMaxPower: "Mixer maximum power",
                        mixerActive: "Mixer active",
                        mixEveryXMinutes: "Mix every",
                        mixForXSeconds: "For",
                        blockMixingFrom: "No mixing from",
                        blockMixingTo: "To",
                        mixerInfo: "Mixer is switched on automatically for selected duration with selected time interval. During mixer block time window, the mixer is stopped when the installed mixer power exceeds the current power production of the installation. Mixer is temporarily stopped during CHP start-up until 50% of nominal power is reached to allow inrush current",
                        mix1Hour: "Mix for 1 hour",
                        coolwaterValveInstalled: "Regulated 3-way valve",
                        bypassFan: "Control valve by-pass / fan",
                        digester: "Control valve digester / external heat consumption",
                        silo: "Silo",
                        bag: "Bag",
                        plateHeatExchanger: "Plate heat exchanger",
                        timesPerDay: "times / day",
                        intern: "Internally",
                        extern: "Externally",
                        tempHigh: "Temperature high",
                        externMessage: "The following error will not be triggered if mixer is set to externally: Mixer not working, please reset thermal.",
                        bypass: "By-pass",
                        ventilator: "Ventilator",
                    },
                    efficiency: {
                        label: "Efficiency",
                        production: "Energy production last 30 days",
                        consumption: "Energy consumption last 30 days",
                        nettoProduction: "Netto energy production last 30 days",
                        averageEfficiency: "Average efficiency last 30 days",
                        averageTechnicalEfficiency: "Average technical availability last 30 days",
                        bgp: "Manure quality (BGP batch)",
                        top10: "Top 10 projects",
                        realised: "Realised efficiency",
                        technical: "Technical availability",
                        bgpInfo: "Estimate of the BGP [m³/ton]. Does not take into account losses caused by downtime, overproduction or flaring.",
                        pointPerDay: "%/day",
                        trendline: "Trend line",
                    },
                    general: {
                        label: "General",
                        h2sSensorFirmwareVersion: "H₂S sensor firmware version",
                        idH2s: "S/N H₂S ID",
                        bioId: "S/N Biotronic ID",
                        identifier: "Installation identifier",
                        collabContract: "Collaboration contract",
                        own: "Maintenance contract",
                        sold: "Sold installation",
                        productionDate: "Production date",
                        commissioningDate: "Commissioning date",
                        controlCardTemp: "Control card temperature",
                        batteryVoltage: "Battery voltage",
                        motorVoltage: "Motor voltage",
                        overtempFlueGasHeatExch: "Over temperature flue gas heat exchanger motor",
                        rebootMainBoard: "Reboot main board",
                        rebootNow: "Reboot now",
                        utcOffset: "UTC offset",
                        location: "Location",
                        network: "Network",
                        ip: "IP",
                        mask: "Mask",
                        gateway: "Gateway",
                        dns: "DNS",
                        pcb: "PCB",
                        firmware: "Firmware",
                        mainControlUnit: "Main control unit",
                        bioguard: "Bioguard",
                        biotronicEngine1: "Biotronic engine 1",
                        biotronicEngine2: "Biotronic engine 2",
                        h2sEngine1: "H₂S engine 1",
                        h2sEngine2: "H₂S engine 2",
                        bioIdEngine1: "Bio id engine 1",
                        bioIdEngine2: "Bio id engine 2",
                        modificationsToMachine: "Modifications to the machine",
                        name: "Name",
                        normal: "Normal",
                        lowError: "Low (error)"
                    },
                    feeding: {
                        label: "Feeding",
                        feedingVolume: "Intake amount per cycle",
                        feedingCyclesPerDay: "Feeding cycles per day",
                        feedingBalance: "Feeding balance",
                        autoFeedingActive: "Auto feeding active",
                        autoFeedingStartHour: "Auto feeding starting time",
                        exitPumpRunning: "Pump out manually",
                        feedingCycle: "Feeding cycle",
                        onlyInput: "Pump in only",
                        onlyOutput: "Pump out only",
                        startFeeding: "Start feeding",
                        stopFeeding: "Stop feeding",
                        pumpOutFlowRate: "Desired flow rate for pumping out",
                        kiloPerPulse: "Flow meter pulse",
                        intakeTimeout: "Time-out pumping in",
                        averageDailyFeeding: "Avg. daily feeding last month",
                        retentionTime: "Retention time",
                        statusValvePumpIn: "Valve fresh in - 2",
                        statusValvePumpOut: "Valve exit reactor - 3",
                        statusValveRetourReactor: "Valve retour reactor - 4",
                        valveCurrentActuator: "Valve current",
                        lastFeeding: "Actual - last",
                        startHourLastFeeding: "Latest feeding time start",
                        feedingStatus: "Feeding state",
                        feedingError: "Reactor error",
                        intakePumpTime: "Intake time",
                        intakePumpAmount: "Intake amount",
                        exitPumpTime: "Exit time",
                        exitPumpAmount: "Exit amount",
                        feedingHistory: "Feeding history",
                        startedOn: "Started on",
                        temperature: "Temperature",
                        reactorLevel: "Reactor level",
                        balance: "Balance",
                        acknowledge: "Acknowledge error",
                        intakeSpeed: "Intake speed",
                        balanceWarning: "Warning, this will not start a normal feeding cycle but instead pump in the remaining balance value into the reactor",
                        automaticFeedingSettings: "Automatic feeding settings",
                        manualFeeding: "Manual feeding",
                        pumpSettings: "Pump settings",
                        status: {
                            "0": "Idle",
                            "1": "Level sensing",
                            "2": "Exit",
                            "3": "Intake",
                            "5": "Defrost",
                            "6": "Gas release",
                            "7": "Manual exit",
                            "10": "Booting",
                            "2,001": "Exit: Premixing",
                            "2,002": "Exit",
                            "2,003": "Exit: Open exit valve",
                            "2,004": "Exit: Open exit valve",
                            "2,005": "Exit: starting exit pump",
                            "2,006": "Exit",
                            "2,007": "Exit: Pumping manure",
                            "2,008": "Exit: Stop exit",
                            "2,009": "Exit: Stop exit",
                            "2,010": "Exit: Close exit valve",
                            "2,011": "Exit: Close exit valve",
                            "2,012": "Exit: Stop exit",
                            "3,001": "Intake: Preparing",
                            "3,002": "Intake: Preparing",
                            "3,003": "Intake: Preparing",
                            "3,004": "Intake: Pumping manure",
                            "3,005": "Intake: Pumping manure",
                            "3,006": "Intake: Finishing",
                            "3,007": "Intake: Finishing",
                            "3,008": "Intake: Stop pump",
                            "3,009": "Intake: Stop pump",
                            "3,010": "Intake: Stop pump",
                            "3,011": "Intake: Stop pump",
                            "3,012": "Intake: Finishing",
                            "5,001": "Defrost: Testing intake",
                            "5,002": "Defrost: Testing intake",
                            "5,003": "Defrost: Testing intake",
                            "5,004": "Defrost: Testing intake",
                            "5,005": "Defrost: Stopping intake",
                            "5,006": "Defrost: Opening exit valve",
                            "5,007": "Defrost: Opening exit valve",
                            "5,008": "Defrost: Testing exit",
                            "5,009": "Defrost: Testing exit",
                            "5,010": "Defrost: Testing exit",
                            "5,011": "Defrost: Testing exit",
                            "5,012": "Defrost: Testing exit",
                            "5,013": "Defrost: Closing exit valve",
                            "5,014": "Defrost: Closing exit valve",
                            "5,015": "Defrost: Finishing",
                            "6,001": "Gas release: Open exit valve",
                            "6,002": "Gas release: Open exit valve",
                            "6,003": "Gas release: Close exit valve",
                            "6,004": "Gas release: Close exit valve",
                            "6,005": "Gas release",
                            "7,001": "Manual exit: Open exit valve",
                            "7,002": "Manual exit: Open exit valve",
                            "7,003": "Manual exit: Pumping manure",
                            "7,004": "Manual exit: Stop pump",
                            "7,005": "Manual exit: Close exit valve",
                            "7,006": "Manual exit: Close exit valve",
                            "7,007": "Manual exit: Finishing",
                            "7,008": "Manual exit: Finishing",
                            "10,001": "Booting: Close valve 2",
                            "10,002": "Booting: Close valve 2",
                            "10,003": "Booting: Close exit valve",
                            "10,004": "Booting: Close exit valve",
                            "10,005": "Booting: Close valve 4",
                            "10,006": "Booting: Close valve 4",
                            "10,007": "Booting: Checking electrical connection",
                            "10,008": "Booting: Checking electrical connection",
                            "10,009": "Booting"
                        }
                    },
                    users: {
                        label: "Users",
                        customers: "Linked customers",
                        technicians: "Linked technicians",
                        resellers: "Linked resellers",
                    },
                    recommendations: {
                        label: "Recommendations",
                    },
                    documents: {
                        label: "Documents"
                    },
                    warnings: {
                        "W001": "Energy consumption exceeding threshold in the past 24 hours (72 kWh for 10-44kW installation, 250 kWh for 60-74kW installation)",
                        "W002": "Reactor temperature has dropped dramatically",
                        "W003": "Service within 48 hours",
                        "W004": "Carbon filter engine 1 needs to be replaced",
                        "W005": "Carbon filter engine 2 needs to be replaced",
                        "W006": "Gas buffer measurement frozen",
                        "W007": "Gas buffer measurement defect",
                        "W008": "Not fed yesterday",
                        "W009": "Pump speed last feeding was 50% faster than the average for the last month",
                        "W010": "H₂S value too high (>1500)",
                        "W011": "Aeration is 60",
                        "W012": "Aeration is lower than or equal to 3",
                        "W013": "Efficiency yesterday was 0 and autofeed is on",
                        "W014": "H₂S reactor measurement - difference exceeds 400ppm",
                        "W015": "Air injection very high - mind gas quality",
                        "W016": "H₂S measurement failed for 24h",
                        "W017": "Mixer oil too low",
                        "W018": "Engine oil low",
                        "W020": "Water pressure low",
                        "W104": "H₂S value too high (>200) or aeration = 60 or 3",
                        "W105": "Load of the carbon filter to high",
                        "W114": "Efficiency yesterday was not 0 and autofeed is off",
                    }
                }
            },
            nl: {
                translation: {
                    dashboard: {
                        nameIdentifier: "Naam of identificatie",
                        efficiencyAll: "Efficiëntie van alle machines",
                        onlyErrors: "Alleen fouten",
                        toggleDisconnected: "Wissel losgekoppelde machines",
                        productionCombined: "Gecombineerde productie (Wh)",
                        realisedTechnicalEfficiency: "Gerealiseerde / Technische efficiëntie (%)",
                        efficiencyCombined: "Gerealiseerde efficiëntie (%)",
                        technicalEfficiencyCombined: "Technische efficiëntie (%)",
                        bgp: "Bgp (m³/ton)",
                        consumption: "Verbruik (Wh)",
                        manureLifeTime: "Levensduur mest (dagen)",
                        feeding: "Voeding (kg)"
                    },
                    common: {
                        on: "Aan",
                        off: "Uit",
                        add: "Toevoegen",
                        save: "Opslaan",
                        monday: "Maandag",
                        tuesday: "Dinsdag",
                        wednesday: "Woensdag",
                        thursday: "Donderdag",
                        friday: "Vrijdag",
                        saturday: "Zaterdag",
                        sunday: "Zondag",
                        from: "Van",
                        to: "Tot",
                        hours: "uren",
                        reset: "Resetten",
                        confirmation: "Bevestiging",
                        cancel: "Annuleren",
                        set: "instellen",
                        auto: "Auto",
                        manual: "Manueel",
                        minutes: "minuten",
                        seconds: "seconden",
                        turnOn: "Zet aan",
                        turnOff: "Zet uit",
                        graphLoading: "Grafieken laden...",
                        noData: "Geen gegevens beschikbaar...",
                        hoursPerYear: "uren / jaar",
                        datetime: "Datum en tijd",
                        warning: "Waarschuwing",
                        error: "Fout",
                        type: "Type",
                        startdate: "Startdatum",
                        enddate: "Einddatum",
                        all: "Alle",
                        engine1: "Motor 1",
                        engine2: "Motor 2",
                        ongoing: "Lopend",
                        both: "Beide",
                        days: "Dagen",
                        today: "Vandaag",
                        sendAnswers: "Antwoorden versturen",
                        employee: "Medewerker",
                        customer: "Klant",
                        sales: "Verkoop",
                        technician: "Technicus",
                        admin: "Beheerder",
                        noDateKnown: "Geen datum bekend",
                        command: "Opdracht",
                        user: "Gebruiker",
                        fillInSurvey: "Gelieve de enquête in te vullen",
                        tons: "ton",
                        count: "Aantal",
                        disabled: "Uitgeschakeld",
                        addField: "Veld toevoegen",
                        deleteField: "Veld verwijderen",
                        releases: "Releases",
                        open: "Openen",
                        close: "Sluiten",
                    },
                    menu: {
                        dashboard: 'Dashboard',
                        machines: 'Machines',
                        questionnaires: 'Vragenlijsten',
                        banners: "Banners",
                        errors: " Fouten en waarschuwingen",
                        pcb: "Printplaten",
                        tasks: "Mijn taken",
                        releases: "Release notes",
                        resellers: "Wederverkopers",
                        users: "Gebruikers",
                        recommendations: "Aanbevelingen",
                        suggestions: "Aanbevelingsberichten",
                        language: "Taal",
                        reconnection: "Deze machine is momenteel offline. Probeer een nieuwe verbinding te maken met de knop hieronder.",
                        profile: "Profiel",
                        logout: "Uitloggen",
                        firmware: "Firmware",
                        sms: "SMS",
                        advanced: "Geavanceerd",
                        errors: "Fouten",
                        warnings: "Waarschuwingen"
                    },
                    statistics: {
                        label: "Statistieken",
                        group1: "Groep 1",
                        group2: "Groep 2",
                        addProperty: "Eigenschap toevoegen",
                        removeProperty: "Eigenschap verwijderen",
                        startTutorial: "Start tutorial",
                        exitTutorial: "Tutorial verlaten",

                        welcomeStep: {
                            title: "Welkom op de statistiekenpagina",
                            content: "Hier kunt u eenvoudig verschillende waarden en trends vergelijken."
                        },
                        propertyStep: {
                            title: "Selectie van eigenschappen",
                            content: "Om te beginnen, selecteer eenvoudig een eigenschap die u interesseert uit het keuzemenu."
                        },
                        addPropertyStep: {
                            title: "Eigenschappen toevoegen en verwijderen",
                            content: "Probeer een eigenschap toe te voegen of te verwijderen. U kunt maximaal 4 eigenschappen per groep toevoegen."
                        },
                        groupStep: {
                            title: "Groep 2 inschakelen",
                            content: "Klik op de knop 'Eigenschap toevoegen' om een eigenschap te selecteren voor Groep 2. Eigenschappen in deze groep worden aan de rechterkant van de grafiek weergegeven."
                        },
                        dateRangeStep: {
                            title: "Een datumbereik selecteren",
                            content: "Gebruik deze knop om een datumbereik te kiezen."
                        },
                        zoomInStep: {
                            title: "Inzoomen",
                            content: "Sleep met uw muis over de grafiek om een gebied te selecteren om in te zoomen."
                        },
                        zoomOutStep: {
                            title: "Uitzoomen",
                            content: "Herstel naar het eerder geselecteerde datumbereik door de knop 'Uitzoomen' te gebruiken."
                        },
                        domainStep: {
                            title: "Aanpassen van domeinen",
                            content: "U kunt het maximale en minimale domein per groep aanpassen met behulp van deze knoppen."
                        },
                        csvStep: {
                            title: "CSV-gegevens downloaden",
                            content: "Download de momenteel weergegeven grafiekgegevens in CSV-formaat."
                        },
                        imageStep: {
                            title: "Afbeelding downloaden",
                            content: "Download de momenteel weergegeven grafiek als afbeelding."
                        },
                        controlCardTemp: "Temperatuur besturingskaart",
                        lowTensionError: "Fout lage spanning",
                        overTempFlueGasHeatExch: "Overtemperatuur rookgaswarmtewisselaar",
                        feedingCycleLevel: "Niveaumeting voedercyclus",
                        batteryVoltage: "Batterijspanning",
                        waterPressure: "Waterdruk",
                        shortInterval: "10 Minuten Intervallen",
                        dailyInterval: "Dagelijkse Intervallen"
                    },
                    smart: {
                        label: "Smart",
                        heightOffset: "Hoogte afwijking",
                        active: "Actief",
                        passive: "Passief",
                        preActive: "Pre Actief",
                        prePassive: "Pre Passief",
                        postActive: "Post Actief",
                        postPassive: "Post Passief",
                        activeDesc: "Tijdens een actieve periode wordt de geplande energieproductie gemaximaliseerd. Voorafgaand aan elke actieve periode wordt automatisch een “pre-actief” tijdsslot voorzien waarin de gasbuffer opgebouwd wordt. De maximumduur van een actieve periode wordt bepaald door het volume van de gasbuffer, het aantal en type van de geïnstalleerde WKK(s) en het biogas-productieprofiel van de installatie. Wanneer de minimum vulgraad van de gasbuffer bereikt wordt, zal de energieproductie gestopt worden (post-actieve periode).",
                        passiveDesc: "Tijdens een passieve periode wordt enkel energie geproduceerd als er een overschot aan gas is. Voorafgaand aan elke passieve periode wordt automatisch een tijdsslot voorzien waarin de gasbuffer afgebouwd wordt. De maximumduur van een passieve periode wordt bepaald door het volume van de gasbuffer en het biogas-productieprofiel van de installatie. Wanneer de maximum vulgraad van de gasbuffer bereikt wordt, zal de energieproductie gestart worden (post-passieve periode).",
                        permanence: "Constante productie stimuleren door dynamisch op één motor te draaien bij niet maximale gasbuffer",
                        permanency: "Permanentie",
                        activateStep: {
                            title: "Activeren van SMART",
                            content: "Activeer SMART om het productieprofiel van de installatie te sturen"
                        },
                        timeslotStep: {
                            title: "Tijdslot toevoegen",
                            content: "Voeg een actief of passief tijdslot toe"
                        },
                        fromToStep: {
                            title: "Van / Tot",
                            content: "Je stelt zowel de start- als einddag en -tijd in"
                        },
                        activePassiveStep: {
                            title: "Actief of Passief",
                            content: "Selecteer of je een actief of passief tijdslot wil instellen"
                        },
                        saveStep: {
                            title: "Tijdsloten opslaan",
                            content: "Sla je gewijzigde instellingen op"
                        },
                        removeStep: {
                            title: "Tijdslot verwijderen",
                            content: "Verwijder je tijdslot hier"
                        },
                        visualStep: {
                            title: "Visuele representatie",
                            content: "Op deze grafiek worden de SMART-instellingen vergeleken met het productieprofiel van de afgelopen week"
                        },
                        daySwitchStep: {
                            title: "Visuele representatie",
                            content: "Navigeer eenvoudig naar de volgende of vorige dag"
                        },
                    },
                    gas: {
                        label: "Gas",
                        minGasBufferLevel: "Min gasbufferniveau",
                        maxGasBufferLevel: "Max gasbufferniveau",
                        gasBufferLevel: "Actueel gasbufferniveau",
                        defaultMinGasBufferLevel: "Standaard min gasbufferniveau",
                        defaultMaxGasBufferLevel: "Standaard max gasbufferniveau",
                        airInjectionTime: "Beluchting per minuut",
                        maxAirInjectionTime: "Maximale beluchting per minuut",
                        torch: "Fakkel",
                        torchType: "Fakkel type",
                        torchStatus: "Fakkel status",
                        torchError: "Fakkel fout",
                        h2s: "H₂S",
                        h2sLevelReactor: "H₂S niveau reactor",
                        h2sLevelMotor: "H₂S niveau motor",
                        filterLoad: "Carbon filter belasting",
                        sample: "Monster",
                        carbonFilterSize: "Carbon filter grootte",
                        h2sMeasurements: "H₂S-metingen",
                        measurements: "Metingen",
                        changeTo: "Wijzig naar",
                        none: "Geen",
                        container: "Container",
                        extern: "Extern",
                        foamBeaterInstalled: "Schuimbestrijder geïnstalleerd",
                        antiFoamInjectionsInstalled: "Antischuim-injecties geïnstalleerd",
                    },
                    engine: {
                        label: "motor",
                        startEngine: "Start motor",
                        stopEngine: "Stop motor",
                        power: "Vermogen",
                        desiredPower: "Gewenst vermogen",
                        maxPower: "Maximaal vermogen",
                        rpm: "Toerental motor (RPM)",
                        lambda: "Lambda",
                        throttleValve: "Servo positie",
                        engineType: "Motortype",
                        engineStatus: "Status motor",
                        engineError: "Motorfouten",
                        producedEnergy: "Totaal geproduceerde energie",
                        operatingHours: "Draaiuren",
                        totalOperatingHours: "Draaiuren totaal",
                        gasUsage: "Gas verbruik",
                        throttlePosition: "Throttle positie",
                        noErrors: "Alle systemen operationeel.",
                        production: "Productie",
                        servoAuto: "Servo wordt automatisch ingesteld",
                        servoControl: "Aansturing servo",
                        temperature: "Motor temperatuur",
                        restart: "Herstart automatisch wanneer er gas beschikbaar is",
                        idle: "Inactief",
                        running: "Draait",
                        waiting: "Wachten op opstarten",
                        booting: "Opstarten",
                        shuttingDown: "Afsluiten",
                        advanced: "Geavanceerd",
                        numberOfEngines: "Aantal motoren",
                        predeterminedRunHours: "Vooraf bepaalde draaiuren",
                        easyStart1: "Easy start motor 1",
                        easyStart2: "Easy start motor 2",
                        setError1: "Ingestelde fout motor 1",
                        setError2: "Ingestelde fout motor 2",
                        fuel: "Brandstof",
                        airIntakeOpen: "Luchtinlaat open",
                        airIntakeClosed: "Luchtinlaat gesloten",
                    },
                    service: {
                        label: "Onderhoud",
                        lastMaintenance: "Draaiuren tot volgende oliewissel",
                        currentlyInstalled: "Draaiuren van huidige apparatuur",
                        total: "Totaal aantal draaiuren",
                        mixer: "Mixer",
                        foamBeater: "Schuimklopper",
                        intakePump: "Invoerpomp",
                        digestatePump: "Digestaatpomp",
                        newMixer: "Nieuwe mixer geïnstalleerd",
                        newEngine: "Nieuwe motor geïnstalleerd",
                        newFoamBeater: "Nieuwe schuimklopper geïnstalleerd",
                        newIntakePump: "Nieuwe inlaatpomp geïnstalleerd",
                        newDigestatePump: "Nieuwe digestaatpomp geïnstalleerd",
                        confirmationMessage: "Bent u zeker over het resetten van de {{type}}",
                        maintenanceMessage: "Onderhoudsuren zijn gereset voor {{type}}",
                        newEquipmentMessage: "Nieuwe apparatuur geïnstalleerd:",
                        prints: "Prints",
                        firstOnline: "Eerst online",
                        lastOnline: "Laatst online",
                        serial: "Serienummer",
                        type: "Type",
                        engine: "Motor",
                        engine1: "Motor 1",
                        engine2: "Motor 2",
                        h2s1: "H₂S Motor 1",
                        h2s2: "H₂S Motor 2",
                        resetTo: "Reset naar x uren (standaard 0)",
                        timeFilterReplace: "Tijd sinds vervangen filter",
                        daysSinceOil: "Dagen sinds vervangen olie",
                        newGenerator: "Nieuwe generator geïnstalleerd",
                        generator: "Generator",
                        generator1: "Generator 1",
                        generator2: "Generator 2",
                        daysSinceOilChange: "Dagen sinds olieverversing",
                        videoMessage: "Video over het verversen van olie => Documentenpagina, onder Algemene video's.",
                        videoRoom: "Een servicetechnicus heeft een videozaal voor deze machine geopend. Klik hier om deel te nemen.",
                        videoButton: "Start een videozaal voor deze machine",
                        videoButtonSub: "Gebruikers met toegang kunnen de oproep vanaf hun servicepagina joinen.",
                        videoClose: "Sluit videozaal"
                    },
                    net: {
                        net: "Net",
                        meterSettings: "Meterstanden",
                        voltagePerPhase1: "Spanning per fase (1)",
                        voltagePerPhase2: "Spanning per fase (2)",
                        voltagePerPhase3: "Spanning per fase (3)",
                        frequencyPerPhase: "Frequentie per fase",
                        angleBetweenPhase1And2: "Hoek tussen fase 1 en 2",
                        ownPowerConsumption: "Vermogen eigenverbruik",
                        ownEnergyConsumption: "Tellerstand eigenverbruik",
                        bioguardVersion: "Bioguard versie",
                        brutoProducedEnergy: "Bruto geproduceerde energie",
                        nettoProducedEnergy: "Netto geproduceerde energie",
                        producedEnergy: "Geproduceerde energie",
                        producedEnergyEngine1: "Geproduceerde energie motor 1",
                        producedEnergyEngine2: "Geproduceerde energie motor 2",
                        monitoringRelay: "Monitoringsrelais",
                    },
                    reactor: {
                        label: "Reactor",
                        reactorTemp: "Reactortemperatuur",
                        reactorTempTarget: "Gewenste reactortemperatuur",
                        reactorLevel: "Gewenst reactorniveau",
                        hydrostaticPressure: "Actueel reactorniveau",
                        siloType: "Silo type",
                        extraHeating: "Extra verwarming actief",
                        antiFrost: "Antivries",
                        antiFrostIndex: "Antivries index",
                        antiFrostAuto: "De vorstindex wordt automatisch ingesteld op basis van de plaatselijke temperatuur",
                        antiFrostMessageNew: "Elke vorst-indexcyclus activeert de inlaatpomp gedurende 4 s en de uitlaatpomp voert ongeveer 100 l digestaat af, gebaseerd op het ingestelde debiet.",
                        antiFrostMessageOld: "Elke vorst-indexcyclus activeert de inlaatpomp gedurende 4 s en de uitlaatpomp gedurende 120 s.",
                        coolwater: "Koelwater",
                        statusCoolingValve: "Koelwater klep",
                        waterTempIn: "Koelwatertemperatuur reactor in",
                        waterTempOut: "Koelwatertemperatuur reactor uit",
                        waterTempMotorOut: "Koelwatertemperatuur motor 1 uit",
                        waterTempMotorOut2: "Koelwatertemperatuur motor 2 uit",
                        coolingWaterTemp: "Gewenste koelwatertemperatuur",
                        maxCoolingWaterTemp: "Maximum koelwatertemperatuur",
                        waterPressure: "Waterdruk in het koelwatercircuit",
                        foamBeater: "Schuim",
                        foambeatEveryXMinutes: "Breekt schuim om de",
                        foambeatForXSeconds: "Gedurende",
                        mixer: "Mixer",
                        mixerMaxPower: "Mixer maximum vermogen",
                        mixerActive: "Mixer actief",
                        mixEveryXMinutes: "Mix iedere",
                        mixForXSeconds: "Gedurende",
                        blockMixingFrom: "Niet Mixen van",
                        blockMixingTo: "Tot",
                        mixerInfo: "Mixer wordt automatisch ingeschakeld voor geselecteerde duur met geselecteerd tijdsinterval. Tijdens het tijdvenster van het mixerblok wordt de mixer gestopt wanneer het geïnstalleerde mixervermogen de huidige stroomproductie van de installatie overschrijdt. De menger wordt tijdens het opstarten van de WKK tijdelijk gestopt totdat 50% van het nominale vermogen is bereikt om inschakelstroom mogelijk te maken",
                        mix1Hour: "Mix voor 1 uur",
                        coolwaterValveInstalled: "Regelbare 3-weg klep",
                        bypassFan: "Regelklep by-pass / ventilator",
                        digester: "Regelklep vergister / externe afname warmte",
                        silo: "Silo",
                        bag: "Zak",
                        plateHeatExchanger: "Platenwarmtewisselaar",
                        timesPerDay: "keer / dag",
                        intern: "Intern",
                        extern: "Extern",
                        tempHigh: "Temperatuur hoog",
                        externMessage: "De volgende foutmelding zal niet worden getriggerd als de mixer op extern staat: Mixer werkt niet, gelieve thermisch te resetten.",
                        bypass: "Bypass",
                        ventilator: "Ventilator",
                    },
                    efficiency: {
                        label: "Rendement",
                        production: "Energie productie afgelopen 30 dagen",
                        consumption: "Eigen energie verbruik afgelopen 30 dagen",
                        nettoProduction: "Netto energie productie afgelopen 30 dagen",
                        averageEfficiency: "Gemiddeld rendement afgelopen 30 dagen",
                        averageTechnicalEfficiency: "Gemiddelde technische beschikbaarheid afgelopen 30 dagen",
                        bgp: "Mestkwaliteit (BGP batch)",
                        top10: "Top 10 projecten",
                        realised: "Gerealiseerd rendement",
                        technical: "Technische beschikbaarheid",
                        bgpInfo: "Schatting van de BGP [m³/ton]. Houdt geen rekening met verliezen door stilstand, overproductie of affakkelen.",
                        pointPerDay: "%/dag",
                        trendline: "Trendlijn",
                    },
                    general: {
                        label: "Algemeen",
                        h2sSensorFirmwareVersion: "H₂S-sensor firmwareversie",
                        idH2s: "S/N H₂S ID",
                        bioId: "S/N Biotronic ID",
                        identifier: "Installatie-ID",
                        collabContract: "Samenwerkingscontract",
                        own: "Onderhoudscontract",
                        sold: "Verkochte installatie",
                        productionDate: "Productiedatum",
                        commissioningDate: "Ingebruiknamedatum",
                        controlCardTemp: "Stuurkaart temperatuur",
                        batteryVoltage: "Batterij spanning",
                        motorVoltage: "Spanning motor",
                        overtempFlueGasHeatExch: "Overtemperatuur rookgaswarmtewisselaar motor",
                        rebootMainBoard: "Herstart hoofdbord",
                        rebootNow: "Nu herstarten",
                        utcOffset: "UTC-offset",
                        location: "Locatie",
                        network: "Netwerk",
                        ip: "IP",
                        mask: "Masker",
                        gateway: "Gateway",
                        dns: "DNS",
                        pcb: "PCB",
                        firmware: "Firmware",
                        mainControlUnit: "Hoofd besturingseenheid",
                        bioguard: "Bioguard",
                        biotronicEngine1: "Biotronic motor 1",
                        biotronicEngine2: "Biotronic motor 2",
                        h2sEngine1: "H₂S motor 1",
                        h2sEngine2: "H₂S motor 2",
                        bioIdEngine1: "Bio ID motor 1",
                        bioIdEngine2: "Bio ID motor 2",
                        modificationsToMachine: "Aanpassingen aan de machine",
                        name: "Naam",
                        normal: "Normaal",
                        lowError: "Laag (fout)"
                    },
                    feeding: {
                        label: "Voeding",
                        feedingVolume: "Hoeveelheid verse voeding per cyclus",
                        feedingCyclesPerDay: "Dagelijkse voedingscycli",
                        feedingBalance: "Voeding saldo",
                        autoFeedingActive: "Automatische voeding",
                        autoFeedingStartHour: "Automatische voeding startuur",
                        exitPumpRunning: "Manueel uitpompen",
                        feedingCycle: "Voedingscyclus",
                        onlyInput: "Enkel inpompen",
                        onlyOutput: "Enkel uitpompen",
                        startFeeding: "Start voeding",
                        stopFeeding: "Stop voeding",
                        pumpOutFlowRate: "Theoretisch uitpomp debiet",
                        kiloPerPulse: "Debietmeter puls",
                        intakeTimeout: "Voeding: time-out verse voeding",
                        averageDailyFeeding: "Gem. dagelijkse voeding laatste maand",
                        retentionTime: "Standtijd",
                        statusValvePumpIn: "Klep vers in - 2",
                        statusValvePumpOut: "Klep reactor uit - 3",
                        statusValveRetourReactor: "Klep retour reactor - 4",
                        valveCurrentActuator: "Stroom klep actuator",
                        lastFeeding: "Huidige / Laatste voeding",
                        startHourLastFeeding: "Start van laatste voeding",
                        feedingStatus: "Voedingspompen status",
                        feedingError: "Reactor error",
                        intakePumpTime: "Inpomp tijd",
                        intakePumpAmount: "Inpomp volume",
                        exitPumpTime: "Uitpomp tijd",
                        exitPumpAmount: "Theoretisch uitpompvolume",
                        feedingHistory: "Voeding geschiedenis",
                        startedOn: "Gestart op",
                        temperature: "Temperatuur",
                        reactorLevel: "Reactorniveau",
                        balance: "Saldo",
                        acknowledge: "Erken error",
                        intakeSpeed: "Pompsnelheid",
                        balanceWarning: "Waarschuwing, dit zal geen normale voedcyclus starten maar in plaats daarvan de resterende balanswaarde in de reactor pompen",
                        automaticFeedingSettings: "Instellingen automatische voeding",
                        manualFeeding: "Handmatige voeding",
                        pumpSettings: "Pompinstellingen",
                        status: {
                            "0": "Inactief",
                            "1": "Niveaumeting",
                            "2": "Uitpompen",
                            "3": "Inpompen",
                            "5": "Ontdooien",
                            "6": "Gasverwijdering",
                            "7": "Manueel uitpompen",
                            "10": "Opstart",
                            "2.001": "Uitpompen: Voormixen",
                            "2.002": "Uitpompen",
                            "2.003": "Uitpompen: Open uitklep",
                            "2.004": "Uitpompen: Open uitklep",
                            "2.005": "Uitpompen: Starten pomp",
                            "2.006": "Uitpompen",
                            "2.007": "Uitpompen: Mest pompen",
                            "2.008": "Uitpompen: Stop uitpompcyclus",
                            "2.009": "Uitpompen: Stop uitpompcyclus",
                            "2.010": "Uitpompen: Sluit uitklep",
                            "2.011": "Uitpompen: Sluit uitklep",
                            "2.012": "Uitpompen: Stop uitpompcyclus",
                            "3.001": "Inpompen: Voorbereiden",
                            "3.002": "Inpompen: Voorbereiden",
                            "3.003": "Inpompen: Voorbereiden",
                            "3.004": "Inpompen: Mest pompen",
                            "3.005": "Inpompen: Mest pompen",
                            "3.006": "Inpompen: Afronden",
                            "3.007": "Inpompen: Afronden",
                            "3.008": "Inpompen: Stop pomp",
                            "3.009": "Inpompen: Stop pomp",
                            "3.010": "Inpompen: Stop pomp",
                            "3.011": "Inpompen: Stop pomp",
                            "3.012": "Inpompen: Afronden",
                            "5.001": "Ontdooien: Inpompen testen",
                            "5.002": "Ontdooien: Inpompen testen",
                            "5.003": "Ontdooien: Inpompen testen",
                            "5.004": "Ontdooien: Inpompen testen",
                            "5.005": "Ontdooien: Inpompen stoppen",
                            "5.006": "Ontdooien: Open uitklep",
                            "5.007": "Ontdooien: Open uitklep",
                            "5.008": "Ontdooien: Uitpompen testen",
                            "5.009": "Ontdooien: Uitpompen testen",
                            "5.010": "Ontdooien: Uitpompen testen",
                            "5.011": "Ontdooien: Uitpompen testen",
                            "5.012": "Ontdooien: Uitpompen testen",
                            "5.013": "Ontdooien: Sluit uitklep",
                            "5.014": "Ontdooien: Sluit uitklep",
                            "5.015": "Ontdooien: Afronden",
                            "6.001": "Gasverwijdering: Open uitklep",
                            "6.002": "Gasverwijdering: Open uitklep",
                            "6.003": "Gasverwijdering: Sluit uitklep",
                            "6.004": "Gasverwijdering: Sluit uitklep",
                            "6.005": "Gasverwijdering",
                            "7.001": "Manueel uitpompen: Open uitklep",
                            "7.002": "Manueel uitpompen: Open uitklep",
                            "7.003": "Manueel uitpompen: Mest pompen",
                            "7.004": "Manueel uitpompen: Stop pomp",
                            "7.005": "Manueel uitpompen: Sluit uitklep",
                            "7.006": "Manueel uitpompen: Sluit uitklep",
                            "7.007": "Manueel uitpompen: Afronden",
                            "7.008": "Manueel uitpompen: Afronden",
                            "10.001": "Opstart: Sluit klep 2",
                            "10.002": "Opstart: Sluit klep 2",
                            "10.003": "Opstart: Sluit uitklep",
                            "10.004": "Opstart: Sluit uitklep",
                            "10.005": "Opstart: Sluit klep 4",
                            "10.006": "Opstart: Sluit klep 4",
                            "10.007": "Opstart: Controleren net verbinding",
                            "10.008": "Opstart: Controleren net verbinding",
                            "10.009": "Booting"
                        }

                    },
                    users: {
                        label: "Gebruikers",
                        customers: "gekoppelde klanten",
                        technicians: "gekoppelde techniekers",
                        resellers: "gekoppelde resellers",
                    },
                    recommendations: {
                        label: "Aanbevelingen",
                    },
                    documents: {
                        label: "Documenten"
                    },
                    warnings: {
                        "W001": "Eigen verbruik groter dan drempelwaarde in de afgelopen 24 uren (72 kWh voor 10-44kW installatie, 250 kWh for 60-74kW installatie)",
                        "W002": "Reactortemperatuur is dramatisch gezakt",
                        "W003": "Service binnen de 48 uren",
                        "W004": "Carbonfilter motor 1 te vervangen",
                        "W005": "Carbonfilter motor 2 te vervangen",
                        "W006": "Gasbuffer meting bevroren",
                        "W007": "Gasbuffer meting defect",
                        "W008": "Gisteren niet gevoed",
                        "W009": "Pompsnelheid laatste voeding ging 50% sneller dan het gemiddelde van de laatste maand",
                        "W010": "H₂S waarde te hoog (>1500)",
                        "W011": "Aeratie is 60",
                        "W012": "Aeratie is lager of gelijk aan 3",
                        "W013": "Rendement was gisteren 0 en autofeed staat aan",
                        "W014": "H₂S reactor meting - afwijking overschrijdt 400ppm",
                        "W015": "Luchtinjectie zeer hoog - opgepast voor gaskwaliteit",
                        "W016": "H₂S meting faalt gedurende 24h",
                        "W017": "Mixer olie te laag",
                        "W018": "Motorolie te laag",
                        "W020": "Lage waterdruk",
                        "W104": "H₂S waarde te hoog (>200) of aeratie is 60 of 3",
                        "W105": "Belading van de carbon filter te hoog",
                        "W114": "Rendement was gisteren geen 0 en autofeed staat uit"
                    }
                }
            },
            fr: {
                translation: {
                    dashboard: {
                        nameIdentifier: "Nom ou identifiant",
                        efficiencyAll: "Efficacité de toutes les machines",
                        onlyErrors: "Uniquement les erreurs",
                        toggleDisconnected: "Basculer les machines déconnectées",
                        productionCombined: "Production combinée (Wh)",
                        realisedTechnicalEfficiency: "Efficacité réalisée / Efficacité technique (%)",
                        efficiencyCombined: "Efficacité réalisée (%)",
                        technicalEfficiencyCombined: "Efficacité technique (%)",
                        bgp: "Bgp (m³/tonne)",
                        consumption: "Consommation (Wh)",
                        manureLifeTime: "Durée de vie du fumier (jours)",
                        feeding: "Alimentation (kg)"
                    },
                    common: {
                        on: "Allumé",
                        off: "Éteint",
                        add: "Ajouter",
                        save: "Enregistrer",
                        monday: "Lundi",
                        tuesday: "Mardi",
                        wednesday: "Mercredi",
                        thursday: "Jeudi",
                        friday: "Vendredi",
                        saturday: "Samedi",
                        sunday: "Dimanche",
                        from: "De",
                        to: "À",
                        hours: "heures",
                        reset: "Réinitialiser",
                        confirmation: "Confirmation",
                        cancel: "Annuler",
                        set: "Ajuster",
                        auto: "Auto",
                        manual: "Manuel",
                        minutes: "minutes",
                        seconds: "secondes",
                        turnOn: "Allumer",
                        turnOff: "Éteindre",
                        graphLoading: "Chargement des graphiques...",
                        noData: "Pas de données disponibles...",
                        hoursPerYear: "heures / année",
                        datetime: "Date et heure",
                        warning: "Avertissement",
                        error: "Erreur",
                        type: "Type",
                        startdate: "Date de début",
                        enddate: "Date de fin",
                        all: "Tous",
                        engine1: "Moteur 1",
                        engine2: "Moteur 2",
                        ongoing: "En cours",
                        both: "Les deux",
                        days: "Jours",
                        today: "Aujourd'hui",
                        sendAnswers: "Envoyer les réponses",
                        employee: "Employé",
                        customer: "Client",
                        sales: "Ventes",
                        technician: "Technicien",
                        admin: "Administrateur",
                        noDateKnown: "Date inconnue",
                        command: "Commande",
                        user: "Utilisateur",
                        fillInSurvey: "Veuillez remplir le sondage",
                        tons: "tonne",
                        count: "Compte",
                        disabled: "Désactivé",
                        addField: "Ajouter un champ",
                        deleteField: "Supprimer le champ",
                        releases: "Versions",
                        open: "Ouvrir",
                        close: "Fermer",
                    },
                    menu: {
                        dashboard: 'Tableau de bord',
                        machines: 'Machinerie',
                        questionnaires: 'Des questionnaires',
                        banners: "Bannières",
                        errors: "Erreurs et avertissements",
                        firmware: "Historique du micrologiciel",
                        tasks: "Mes tâches",
                        releases: "Notes de version",
                        resellers: "Revendeurs",
                        users: "Utilisateurs",
                        recommendations: "Recommandations",
                        suggestions: "Messages de recommandation",
                        language: "Langue",
                        reconnection: "Cette machine est actuellement hors ligne. Essayez d'établir une nouvelle connexion avec le bouton ci-dessus.",
                        profile: "Profil",
                        logout: "Se déconnecter",
                        firmware: "Firmware",
                        sms: "SMS",
                        advanced: "Avancé",
                        errors: "Erreurs",
                        warnings: "Avertissements"
                    },
                    statistics: {
                        label: "Statistiques",
                        group1: "Groupe 1",
                        group2: "Groupe 2",
                        addProperty: "Ajouter une propriété",
                        removeProperty: "Supprimer la propriété",
                        startTutorial: "Commencer le tutoriel",
                        exitTutorial: "Quitter le tutoriel",

                        welcomeStep: {
                            title: "Bienvenue sur la page des statistiques",
                            content: "Ici, vous pouvez comparer différentes valeurs et tendances facilement."
                        },
                        propertyStep: {
                            title: "Sélection de propriété",
                            content: "Pour commencer, il vous suffit de sélectionner une propriété qui vous intéresse dans la liste déroulante."
                        },
                        addPropertyStep: {
                            title: "Ajout et suppression de propriétés",
                            content: "Essayez d'ajouter ou de supprimer une propriété. Vous pouvez ajouter jusqu'à 4 propriétés par groupe."
                        },
                        groupStep: {
                            title: "Activation du groupe 2",
                            content: "Cliquez sur le bouton 'Ajouter une propriété' pour sélectionner une propriété pour le Groupe 2. Les propriétés de ce groupe seront affichées à droite du graphique."
                        },
                        dateRangeStep: {
                            title: "Sélection d'une plage de dates",
                            content: "Utilisez ce bouton pour choisir une plage de dates."
                        },
                        zoomInStep: {
                            title: "Zoomer",
                            content: "Faites glisser votre souris sur le graphique pour sélectionner une zone à zoomer."
                        },
                        zoomOutStep: {
                            title: "Dézoomer",
                            content: "Réinitialisez la plage de dates précédemment sélectionnée en utilisant le bouton 'Dézoomer'."
                        },
                        domainStep: {
                            title: "Ajustement des domaines",
                            content: "Vous pouvez personnaliser le domaine maximal et minimal par groupe en utilisant ces boutons."
                        },
                        csvStep: {
                            title: "Télécharger les données au format CSV",
                            content: "Téléchargez les données du graphique actuellement affichées au format CSV."
                        },
                        imageStep: {
                            title: "Télécharger une image",
                            content: "Téléchargez le graphique actuellement affiché sous forme d'image."
                        },
                        controlCardTemp: "Température de la carte de contrôle",
                        lowTensionError: "Erreur de tension basse",
                        overTempFlueGasHeatExch: "Surchauffe de l'échangeur de chaleur des gaz de combustion",
                        feedingCycleLevel: "Niveau du cycle d'alimentation",
                        batteryVoltage: "Tension de la batterie",
                        waterPressure: "Pression de l'eau",
                        shortInterval: "Intervalles de 10 minutes",
                        dailyInterval: "Intervalles quotidiens"
                    },
                    smart: {
                        label: "Smart",
                        heightOffset: "Décalage de hauteur",
                        active: "Actif",
                        passive: "Passif",
                        preActive: "Pré-actif",
                        prePassive: "Pré-passif",
                        postActive: "Post-actif",
                        postPassive: "Post-passif",
                        activeDesc: "Pendant une période active, la production d'énergie prévue est maximisée. Avant chaque période active, un créneau temporel « pré-actif » est automatiquement prévu pour accumuler du gaz dans le tampon. La durée maximale d'une période active est déterminée par le volume du tampon de gaz, le nombre et le type de CHP installés, ainsi que le profil de production de biogaz de l'installation. Lorsque le niveau de remplissage minimum du tampon de gaz est atteint, la production d'énergie s'arrête (période post-active).",
                        passiveDesc: "Pendant une période passive, l'énergie n'est produite que s'il y a un excédent de gaz. Avant chaque période passive, un créneau temporel est automatiquement prévu pour réduire le tampon de gaz. La durée maximale d'une période passive est déterminée par le volume du tampon de gaz et le profil de production de biogaz de l'installation. Lorsque le niveau de remplissage maximum du tampon de gaz est atteint, la production d'énergie démarre (période post-passive).",
                        permanence: "Stimuler la production constante en fonctionnant dynamiquement sur un moteur avec un tampon de gaz non maximal",
                        permanency: "Permanence",
                        activateStep: {
                            title: "Activation de l'option SMART",
                            content: "Activez l'option SMART pour contrôler le profil de production de l'installation"
                        },
                        timeslotStep: {
                            title: "Ajouter une période",
                            content: "Ajoutez une période active ou passive"
                        },
                        fromToStep: {
                            title: "De / À",
                            content: "Vous définissez à la fois le jour de début et de fin ainsi que l'heure"
                        },
                        activePassiveStep: {
                            title: "Actif ou Passif",
                            content: "Sélectionnez si vous souhaitez définir une période active ou passive"
                        },
                        saveStep: {
                            title: "Enregistrer les périodes",
                            content: "Enregistrez vos paramètres modifiés"
                        },
                        removeStep: {
                            title: "Supprimer les périodes",
                            content: "Supprimez votre période ici"
                        },
                        visualStep: {
                            title: "Représentation visuelle",
                            content: "Ce graphique compare les paramètres SMART avec le profil de production de la semaine passée"
                        },
                        daySwitchStep: {
                            title: "Représentation visuelle",
                            content: "Naviguez facilement vers le jour suivant ou précédent"
                        },
                    },
                    gas: {
                        label: "Gaz",
                        minGasBufferLevel: "Niveau de gaz minimum",
                        maxGasBufferLevel: "Niveau de gaz maximum",
                        gasBufferLevel: "Niveau actuel du réservoir de gaz",
                        defaultMinGasBufferLevel: "Défaut niveau de gaz minimum",
                        defaultMaxGasBufferLevel: "Défaut niveau de gaz maximum",
                        airInjectionTime: "Aération",
                        maxAirInjectionTime: "Aération maximum",
                        torch: "Torche",
                        torchType: "Type de torche",
                        torchStatus: "Etat de la torche",
                        torchError: "Erreur de la torche",
                        h2s: "H₂S",
                        h2sLevelReactor: "H₂S réacteur",
                        h2sLevelMotor: "H₂S Moteur",
                        filterLoad: "Chargement du filtre",
                        sample: "Échantillon",
                        carbonFilterSize: "Taille du filtre à charbon",
                        h2sMeasurements: "Mesures H₂S",
                        measurements: "Mesures",
                        changeTo: "Changer en",
                        none: "Aucun",
                        container: "Conteneur",
                        extern: "Externe",
                        foamBeaterInstalled: "Battre-mousse installé",
                        antiFoamInjectionsInstalled: "Injections anti-mousse installées",

                    },
                    engine: {
                        label: "moteur",
                        startEngine: "Démarrage moteur",
                        stopEngine: "Arrêt moteur",
                        power: "Puissance",
                        desiredPower: "Puissance désirée",
                        maxPower: "Puissance maximale",
                        rpm: "Tours par minute (RPM)",
                        lambda: "Lambda",
                        throttleValve: "Position du servo",
                        engineType: "Type de moteur",
                        engineStatus: "État moteur",
                        engineError: "Erreurs moteur",
                        producedEnergy: "Énergie totale produite",
                        operatingHours: "Heures de marche du moteur",
                        totalOperatingHours: "Heures de marche totale du moteur",
                        gasUsage: "Consommation de gaz",
                        throttlePosition: "Position du throttle",
                        noErrors: "Tous les systèmes sont opérationnels.",
                        production: "Production",
                        servoAuto: "Le servo est réglé automatiquement",
                        servoControl: "Contrôle servo",
                        temperature: "Température moteur",
                        restart: "Redémarrage automatique lorsque du gaz sera disponible",
                        idle: "Inactif",
                        running: "En cours",
                        waiting: "En attente de démarrage",
                        booting: "Démarrage",
                        shuttingDown: "Arrêt",
                        advanced: "Avancé",
                        numberOfEngines: "Nombre de moteurs",
                        predeterminedRunHours: "Heures de fonctionnement prédéterminées",
                        easyStart1: "Easy start moteur 1",
                        easyStart2: "Easy start moteur 2",
                        setError1: "Erreur de réglage moteur 1",
                        setError2: "Erreur de réglage moteur 1",
                        fuel: "Carburant",
                        airIntakeOpen: "Prise d'air ouverte",
                        airIntakeClosed: "Prise d'air fermée",
                    },
                    service: {
                        label: "Entretien",
                        lastMaintenance: "Heures de fonctionnement jusqu'à la prochaine vidange d'huile",
                        currentlyInstalled: "Heures de fonctionnement actuellement installées",
                        total: "Nombre total d'heures de fonctionnement",
                        mixer: "Mélangeur",
                        foamBeater: "Batteur à mousse",
                        intakePump: "Pompe d'admission",
                        digestatePump: "Pompe de digestat",
                        newMixer: "Nouveau mélangeur installé",
                        newEngine: "Nouveau moteur installé",
                        newFoamBeater: "Nouveau batteur à mousse installé",
                        newIntakePump: "Nouvelle pompe d'admission installée",
                        newDigestatePump: "Nouvelle pompe de digestat installée",
                        confirmationMessage: "Êtes-vous sûr de vouloir réinitialiser le {{type}}",
                        maintenanceMessage: "Les heures de maintenance ont été réinitialisées pour {{type}}",
                        newEquipmentMessage: "Nouvel équipement installé :",
                        prints: "Prints",
                        firstOnline: "Première connexion en ligne",
                        lastOnline: "Dernière connexion en ligne",
                        serial: "Numéro de série",
                        type: "Type",
                        engine: "Moteur",
                        engine1: "Moteur 1",
                        engine2: "Moteur 2",
                        h2s1: "Moteur H₂S 1",
                        h2s2: "Moteur H₂S 2",
                        resetTo: "Réinitialiser à x heures (par défaut 0)",
                        timeFilterReplace: "Temps écoulé depuis le remplacement du filtre",
                        daysSinceOil: "Jours depuis le changement d'huile",
                        newGenerator: "Nouvelle génératrice installée",
                        generator: "Générateur",
                        generator1: "Générateur 1",
                        generator2: "Générateur 2",
                        daysSinceOilChange: "Jours depuis la vidange d'huile",
                        videoMessage: "Vidéo sur le changement d'huile => Page des documents, sous Vidéos générales.",
                        videoRoom: "Un technicien de service a ouvert une salle vidéo pour cette machine. Cliquez ici pour rejoindre.",
                        videoButton: "Démarrer une salle vidéo pour cette machine",
                        videoButtonSub: "Les utilisateurs ayant accès peuvent rejoindre l'appel depuis leur page de service.",
                        videoClose: "Fermer la salle vidéo"
                    },
                    net: {
                        net: "Réseau",
                        meterSettings: "Valeur du compteur",
                        voltagePerPhase1: "Voltage par phase (1)",
                        voltagePerPhase2: "Voltage par phase (2)",
                        voltagePerPhase3: "Voltage par phase (3)",
                        frequencyPerPhase: "Frequance par phase",
                        angleBetweenPhase1And2: "Angle entre phase (1) et (2)",
                        ownPowerConsumption: "Puissance de consommation propre",
                        ownEnergyConsumption: "Consommation propre d'énergie",
                        bioguardVersion: "Version bioguard",
                        brutoProducedEnergy: "Énergie brute produite",
                        nettoProducedEnergy: "Énergie nette produite",
                        producedEnergyEngine1: "Énergie produite du moteur 1",
                        producedEnergyEngine2: "Énergie produite du moteur 2",
                        monitoringRelay: "Relais de surveillance ",
                    },
                    reactor: {
                        label: "Réacteur",
                        reactorTemp: "Témperature du réacteur",
                        reactorTempTarget: "Température du réacteur désirée",
                        reactorLevel: "Niveau désiré",
                        hydrostaticPressure: "Niveau du réacteur actuel",
                        siloType: "Silo type",
                        extraHeating: "Chauffage",
                        antiFrost: "Antigel",
                        antiFrostIndex: "Index antigel",
                        antiFrostAuto: "Le index de gel est configure automatiquement et se base sur les températures locales",
                        antiFrostMessageNew: "Chaque cycle d'index de gel activera la pompe à lisier frais pour 4'' et la pompe à digestat évacuera approximativement 100l de digestat, base sur les flux pré-paramétrés.",
                        antiFrostMessageOld: "Chaque cycle d'index de gel activera la pompe à lisier frais pour 4'' et la pompe à digestat pour 120’’",
                        coolwater: "Eau de refroidissement",
                        statusCoolingValve: "Vanne d'eau de refroidissement",
                        waterTempIn: "Température d'eau de refroidissement entré du réacteur",
                        waterTempOut: "Température d'eau de refroidissement à la sortie réacteur",
                        waterTempMotorOut: "Température d'eau de refroidissement à la sortie moteur 1",
                        waterTempMotorOut2: "Température d'eau de refroidissement à la sortie moteur 2",
                        coolingWaterTemp: "Réglage de la température d'eau de refroidissement",
                        maxCoolingWaterTemp: "Température d'eau de refroidissement maximum",
                        waterPressure: "Pression dans le circuit d'eau de refroidissement",
                        foamBeater: "Mousse",
                        foambeatEveryXMinutes: "Détruire mousse toutes les",
                        foambeatForXSeconds: "Pour",
                        mixer: "Mélangeur",
                        mixerMaxPower: "Puissance maximale du mélangeur",
                        mixerActive: "Mélangeur actif",
                        mixEveryXMinutes: "Mélanger toutes les",
                        mixForXSeconds: "Pour",
                        blockMixingFrom: "Ne pas mélanger de",
                        blockMixingTo: "à",
                        mixerInfo: "Le mélangeur est allumé automatiquement pour la durée sélectionnée avec l'intervalle de temps sélectionné. Pendant la fenêtre de temps de blocage du mélangeur, le mélangeur est arrêté lorsque la puissance installée du mélangeur dépasse la production électrique actuelle de l'installation. Le mélangeur est temporairement arrêté pendant le démarrage de la cogénération jusqu'à ce que 50 % de la puissance nominale soient atteints pour permettre le courant d'appel",
                        mix1Hour: "Mélanger 1 heure",
                        coolwaterValveInstalled: "Vanne 3 voies réglée",
                        bypassFan: "Contrôle soupape by-pass / ventilateur",
                        digester: "Contrôle soupape digesteur/consummation chaleur externe",
                        silo: "Silo",
                        bag: "Sac",
                        plateHeatExchanger: "Échangeur de chaleur à plaques",
                        timesPerDay: "fois / jour",
                        intern: "Interne",
                        extern: "Externe",
                        tempHigh: "Température élevée",
                        externMessage: "L'erreur suivante ne sera pas déclenchée si le mélangeur est réglé sur externe : Mélangeur ne fonctionne pas, veuillez réinitialiser le thermique.",
                        bypass: "Dérivation",
                        ventilator: "Ventilateur",
                    },
                    efficiency: {
                        label: "Efficacité",
                        production: "Production d'énergie des 30 derniers jours",
                        consumption: "Consommation d'énergie des 30 derniers jours",
                        nettoProduction: "Netto production d'énergie des 30 derniers jours",
                        averageEfficiency: "Éfficacité moyen des 30 derniers jours",
                        averageTechnicalEfficiency: "Disponibilité technique moyen des 30 derniers jours",
                        bgp: "Qualité du fumier (BGP batch)",
                        top10: "Top 10 projets",
                        realised: "Efficacité réalisée",
                        technical: "Disponibilité technique",
                        bgpInfo: "Estimation BGP. Ne prend pas en compte les partes causées par les arrêts moteur, la surproduction de gaz ou le fonctionnement de la torchère",
                        pointPerDay: "%/jour",
                        trendline: "Ligne de tendance",
                    },
                    general: {
                        label: "Général",
                        h2sSensorFirmwareVersion: "Version du firmware du capteur H₂S",
                        idH2s: "S/N ID H₂S",
                        bioId: "S/N ID Biotronic",
                        identifier: "Identifiant de l'installation",
                        collabContract: "Contrat de collaboration",
                        own: "Contrat de maintenance",
                        sold: "Installation vendue",
                        productionDate: "Date de production",
                        commissioningDate: "Date de mise en service",
                        controlCardTemp: "Température de la carte de contrôle",
                        batteryVoltage: "Tension de la batterie",
                        motorVoltage: "Tension du moteur",
                        overtempFlueGasHeatExch: "Surchauffe moteur de l'échangeur de chaleur des gaz de fumée",
                        rebootMainBoard: "Redémarrage de la carte principale",
                        rebootNow: "Redémarrer maintenant",
                        utcOffset: "Décalage UTC",
                        location: "Emplacement",
                        network: "Réseau",
                        ip: "IP",
                        mask: "Masque",
                        gateway: "Passerelle",
                        dns: "DNS",
                        pcb: "PCB",
                        firmware: "Firmware",
                        mainControlUnit: "Unité de contrôle principale",
                        bioguard: "Bioguard",
                        biotronicEngine1: "Moteur Biotronic 1",
                        biotronicEngine2: "Moteur Biotronic 2",
                        h2sEngine1: "Moteur H₂S 1",
                        h2sEngine2: "Moteur H₂S 2",
                        bioIdEngine1: "Moteur Bio ID 1",
                        bioIdEngine2: "Moteur Bio ID 2",
                        modificationsToMachine: "Modifications à la machine",
                        name: "Nom",
                        normal: "Normal",
                        lowError: "Bas (erreur)",
                    },
                    feeding: {
                        label: "Pompage",
                        feedingVolume: "Quantité d'alimentation par cycle",
                        feedingCyclesPerDay: "Cycles de pompage par jour",
                        feedingBalance: "Solde de pompage",
                        autoFeedingActive: "Pompage automatique",
                        autoFeedingStartHour: "Pompage automatique heure de début",
                        exitPumpRunning: "Evacuation manuelle",
                        feedingCycle: "Cycle de pompage",
                        onlyInput: "Seulement alimentation",
                        onlyOutput: "Seulement évacuation",
                        startFeeding: "Démarrage",
                        stopFeeding: "Arrêt pompage",
                        pumpOutFlowRate: "Débit d'évacuation",
                        kiloPerPulse: "Pulse du débitmètre",
                        intakeTimeout: "Time-out alimentation",
                        averageDailyFeeding: "Alimentation quotidienne moyenne du mois dernier",
                        retentionTime: "Rétention",
                        statusValvePumpIn: "Vanne lisier frais - 2",
                        statusValvePumpOut: "Vanne sortie réacteur - 3",
                        statusValveRetourReactor: "Vanne retour réacteur - 4",
                        valveCurrentActuator: "Courant actuateur de vanne",
                        lastFeeding: "Actuel - dernier",
                        startHourLastFeeding: "Temps de pompage dernier",
                        feedingStatus: "État de pompage",
                        feedingError: "Erreur du réacteur",
                        intakePumpTime: "Temps alimentation",
                        intakePumpAmount: "Quantité d'alimentation",
                        exitPumpTime: "Temps d'évacuation",
                        exitPumpAmount: "Quantité d'évacuation",
                        feedingHistory: "Histoire de pompage",
                        startedOn: "Commencé à",
                        temperature: "Température",
                        reactorLevel: "Niveau",
                        balance: "Solde",
                        acknowledge: "Reconnaissance de l'erreur",
                        intakeSpeed: "Vitesse du pompage",
                        balanceWarning: "Avertissement, cela ne lancera pas un cycle d'alimentation normal mais pompera à la place la valeur restante du solde dans le réacteur",
                        automaticFeedingSettings: "Paramètres d'alimentation automatique",
                        manualFeeding: "Alimentation manuelle",
                        pumpSettings: "Paramètres de la pompe",
                        status: {
                            "0": "Inactif",
                            "1": "Détection de niveau",
                            "2": "Sortie",
                            "3": "Admission",
                            "5": "Déverrouillage",
                            "6": "Libération de gaz",
                            "7": "Sortie manuelle",
                            "10": "Démarrage",
                            "2.001": "Sortie : pré-mélange",
                            "2.002": "Sortie",
                            "2.003": "Sortie : ouverture de la vanne de sortie",
                            "2.004": "Sortie : ouverture de la vanne de sortie",
                            "2.005": "Sortie : démarrage de la pompe de sortie",
                            "2.006": "Sortie",
                            "2.007": "Sortie : pompage du lisier",
                            "2.008": "Sortie : arrêt de la sortie",
                            "2.009": "Sortie : arrêt de la sortie",
                            "2.010": "Sortie : fermeture de la vanne de sortie",
                            "2.011": "Sortie : fermeture de la vanne de sortie",
                            "2.012": "Sortie : arrêt de la sortie",
                            "3.001": "Admission : préparation",
                            "3.002": "Admission : préparation",
                            "3.003": "Admission : préparation",
                            "3.004": "Admission : pompage du lisier",
                            "3.005": "Admission : pompage du lisier",
                            "3.006": "Admission : finition",
                            "3.007": "Admission : finition",
                            "3.008": "Admission : arrêt de la pompe",
                            "3.009": "Admission : arrêt de la pompe",
                            "3.010": "Admission : arrêt de la pompe",
                            "3.011": "Admission : arrêt de la pompe",
                            "3.012": "Admission : finition",
                            "5.001": "Déverrouillage : test de l'admission",
                            "5.002": "Déverrouillage : test de l'admission",
                            "5.003": "Déverrouillage : test de l'admission",
                            "5.004": "Déverrouillage : test de l'admission",
                            "5.005": "Déverrouillage : arrêt de l'admission",
                            "5.006": "Déverrouillage : ouverture de la vanne de sortie",
                            "5.007": "Déverrouillage : ouverture de la vanne de sortie",
                            "5.008": "Déverrouillage : test de sortie",
                            "5.009": "Déverrouillage : test de sortie",
                            "5.010": "Déverrouillage : test de sortie",
                            "5.011": "Déverrouillage : test de sortie",
                            "5.012": "Déverrouillage : test de sortie",
                            "5.013": "Déverrouillage : fermeture de la vanne de sortie",
                            "5.014": "Déverrouillage : fermeture de la vanne de sortie",
                            "5.015": "Déverrouillage : finition",
                            "6.001": "Libération de gaz : ouverture de la vanne de sortie",
                            "6.002": "Libération de gaz : ouverture de la vanne de sortie",
                            "6.003": "Libération de gaz : fermeture de la vanne de sortie",
                            "7.001": "Sortie manuelle : Ouvrir la vanne de sortie",
                            "7.002": "Sortie manuelle : Ouvrir la vanne de sortie",
                            "7.003": "Sortie manuelle : Pomper le lisier",
                            "7.004": "Sortie manuelle : Arrêter la pompe",
                            "7.005": "Sortie manuelle : Fermer la vanne de sortie",
                            "7.006": "Sortie manuelle : Fermer la vanne de sortie",
                            "7.007": "Sortie manuelle : Terminer",
                            "7.008": "Sortie manuelle : Terminer",
                            "10.001": "Démarrage : Fermer la vanne 2",
                            "10.002": "Démarrage : Fermer la vanne 2",
                            "10.003": "Démarrage : Fermer la vanne de sortie",
                            "10.004": "Démarrage : Fermer la vanne de sortie",
                            "10.005": "Démarrage : Fermer la vanne 4",
                            "10.006": "Démarrage : Fermer la vanne 4",
                            "10.007": "Démarrage : Vérifier la connexion électrique",
                            "10.008": "Démarrage : Vérifier la connexion électrique",
                            "10.009": "Démarrage"
                        }
                    },
                    users: {
                        label: "Utilisateurs",
                        customers: "Clients connectés",
                        technicians: "Techniciens connectés",
                        resellers: "Partenaires connectés",
                    },
                    recommendations: {
                        label: "Recommandations",
                    },
                    documents: {
                        label: "Documents"
                    },
                    warnings: {
                        "W001": "Consommation d'énergie dépassant le seuil au cours des dernières 24 heures (72 kWh pour une installation de 10 à 44 kW, 250 kWh pour une installation de 60 à 74 kW)",
                        "W002": "La température du réacteur a chuté de façon spectaculaire",
                        "W003": "Service dans les 48 heures",
                        "W004": "Filtre à charbon moteur 1 à remplacer",
                        "W005": "Filtre à charbon moteur 2 à remplacer",
                        "W006": "Mesure du tampon de gaz congelé",
                        "W007": "Défaut de mesure du tampon de gaz",
                        "W008": "Non nourri hier",
                        "W009": "Dernier repas de vitesse de la pompe était de 50% plus rapide que la moyenne pour le dernier mois",
                        "W010": "H₂S valeur trop élevée (>1500)",
                        "W011": "Aération est 60",
                        "W012": "Aération est moins ou égal à 3",
                        "W013": "Efficacité était 0 hier et pompage automatique est allumé",
                        "W014": "H₂S réacteur - difference au dela de 400ppm",
                        "W015": "Injection d'air tres elevee - attention pour la qualite du gaz",
                        "W016": "Defaut de mesure de H₂S",
                        "W017": "Huile du mixer trop fabre",
                        "W018": "Huile moteur bas",
                        "W020": "Basse pression de l'eau",
                        "W104": "H₂S valeur trop élevée (>200) ou aération est 60 ou 3",
                        "W105": "Charge du filtre à charbon à haut",
                        "W114": "Efficacité n'était pas 0 hier et pompage automatique est éteint",
                    }
                }
            },
            de: {
                translation: {
                    dashboard: {
                        nameIdentifier: "Name oder Kennung",
                        efficiencyAll: "Effizienz aller Maschinen",
                        onlyErrors: "Nur Fehler",
                        toggleDisconnected: "Getrennte Maschinen umschalten",
                        productionCombined: "Kombinierte Produktion (Wh)",
                        realisedTechnicalEfficiency: "Realisierte / Technische Effizienz (%)",
                        efficiencyCombined: "Realisierte Effizienz (%)",
                        technicalEfficiencyCombined: "Technische Effizienz (%)",
                        bgp: "Bgp (m³/Tonne)",
                        consumption: "Verbrauch (Wh)",
                        manureLifeTime: "Lebensdauer des Mistes (Tage)",
                        feeding: "Fütterung (kg)"
                    },
                    common: {
                        on: "An",
                        off: "Aus",
                        add: "Hinzufügen",
                        save: "Speichern",
                        monday: "Montag",
                        tuesday: "Dienstag",
                        wednesday: "Mittwoch",
                        thursday: "Donnerstag",
                        friday: "Freitag",
                        saturday: "Samstag",
                        sunday: "Sonntag",
                        from: "Von",
                        to: "Bis",
                        hours: "Stunden",
                        reset: "Zurücksetzen",
                        confirmation: "Bestätigung",
                        cancel: "Abbrechen",
                        set: "Anpassen",
                        auto: "Automatisch",
                        manual: "Manuell",
                        minutes: "Minuten",
                        seconds: "Sekunden",
                        turnOn: "Anmachen",
                        turnOff: "Abschalten",
                        graphLoading: "Laden von Diagrammen...",
                        noData: "Keine Daten verfügbar...",
                        hoursPerYear: "Stunden / Jahr",
                        datetime: "Datum und Uhrzeit",
                        warning: "Warnung",
                        error: "Fehler",
                        type: "Typ",
                        startdate: "Startdatum",
                        enddate: "Enddatum",
                        all: "Alle",
                        engine1: "Motor 1",
                        engine2: "Motor 2",
                        ongoing: "Laufend",
                        both: "Beide",
                        days: "Tage",
                        today: "Heute",
                        sendAnswers: "Antworten senden",
                        employee: "Mitarbeiter",
                        customer: "Kunde",
                        sales: "Vertrieb",
                        technician: "Techniker",
                        admin: "Administrator",
                        noDateKnown: "Kein Datum bekannt",
                        command: "Befehl",
                        user: "Benutzer",
                        fillInSurvey: "Bitte füllen Sie die Umfrage aus",
                        tons: "Tonne",
                        count: "Zählen",
                        disabled: "Deaktiviert",
                        addField: "Feld hinzufügen",
                        deleteField: "Feld löschen",
                        releases: "Veröffentlichungen",
                        open: "Öffnen",
                        close: "Schließen",
                    },
                    menu: {
                        dashboard: 'Armaturenbrett',
                        machines: 'Maschinen',
                        questionnaires: 'Fragebögen',
                        banners: "Banner",
                        errors: "Fehlermeldungen",
                        pcb: "Cartes de circuits imprimés",
                        tasks: "Meine Aufgaben",
                        releases: "Versionshinweise",
                        resellers: "Wiederverkäufer",
                        users: "Benutzer",
                        recommendations: "Empfehlungen",
                        suggestions: "Empfehlungsnachrichten",
                        language: "Sprache",
                        reconnection: "Diese Maschine ist derzeit offline. Versuchen Sie, mit der Schaltfläche oben eine neue Verbindung herzustellen.",
                        profile: "Profil",
                        logout: "Abmelden",
                        firmware: "Firmware",
                        sms: "SMS",
                        advanced: "Erweitert",
                        errors: "Fehler",
                        warnings: "Warnungen"
                    },
                    statistics: {
                        label: "Statistiken",
                        group1: "Gruppe 1",
                        group2: "Gruppe 2",
                        addProperty: "Eigenschaft hinzufügen",
                        removeProperty: "Eigenschaft entfernen",
                        startTutorial: "Tutorial starten",
                        exitTutorial: "Tutorial verlassen",

                        welcomeStep: {
                            title: "Willkommen auf der Statistikseite",
                            content: "Hier können Sie verschiedene Werte und Trends einfach vergleichen."
                        },
                        propertyStep: {
                            title: "Eigenschaftsauswahl",
                            content: "Um zu beginnen, wählen Sie einfach eine Eigenschaft aus, die Sie interessiert, aus dem Auswahlfeld."
                        },
                        addPropertyStep: {
                            title: "Hinzufügen und Entfernen von Eigenschaften",
                            content: "Versuchen Sie, eine Eigenschaft hinzuzufügen oder zu entfernen. Sie können bis zu 4 Eigenschaften pro Gruppe hinzufügen."
                        },
                        groupStep: {
                            title: "Aktivieren von Gruppe 2",
                            content: "Klicken Sie auf die Schaltfläche 'Eigenschaft hinzufügen', um eine Eigenschaft für Gruppe 2 auszuwählen. Eigenschaften in dieser Gruppe werden auf der rechten Seite des Diagramms angezeigt."
                        },
                        dateRangeStep: {
                            title: "Auswahl eines Datumsbereichs",
                            content: "Verwenden Sie diese Schaltfläche, um einen Datumsbereich auszuwählen."
                        },
                        zoomInStep: {
                            title: "Hineinzoomen",
                            content: "Ziehen Sie Ihre Maus über das Diagramm, um einen Bereich zum Hineinzoomen auszuwählen."
                        },
                        zoomOutStep: {
                            title: "Herauszoomen",
                            content: "Setzen Sie die zuvor ausgewählte Datumsbereich durch Verwenden der 'Herauszoomen'-Schaltfläche zurück."
                        },
                        domainStep: {
                            title: "Anpassen von Domänen",
                            content: "Sie können das maximale und minimale Domänen pro Gruppe anpassen, indem Sie diese Schaltflächen verwenden."
                        },
                        csvStep: {
                            title: "CSV-Daten herunterladen",
                            content: "Laden Sie die aktuell angezeigten Diagrammdaten im CSV-Format herunter."
                        },
                        imageStep: {
                            title: "Bild herunterladen",
                            content: "Laden Sie das aktuell angezeigte Diagramm als Bild herunter."
                        },
                        controlCardTemp: "Temperatur der Steuerkarte",
                        lowTensionError: "Niederspannungsfehler",
                        overTempFlueGasHeatExch: "Überhitzung des Abgaswärmetauschers",
                        feedingCycleLevel: "Fütterungszykluspegel",
                        batteryVoltage: "Batteriespannung",
                        waterPressure: "Wasserdruck",
                        shortInterval: "10 Minuten Intervalle",
                        dailyInterval: "Tägliche Intervalle"
                    },
                    smart: {
                        label: "Smart",
                        heightOffset: "Höhenversatz",
                        active: "Aktiv",
                        passive: "Passiv",
                        preActive: "Vor-Aktiv",
                        prePassive: "Vor-Passiv",
                        postActive: "Nach-Aktiv",
                        postPassive: "Nach-Passiv",
                        activeDesc: "Während einer aktiven Phase wird die geplante Energieerzeugung maximiert. Vor jeder aktiven Phase wird automatisch ein „Vor-Aktiv“-Zeitfenster bereitgestellt, in dem der Gaspuffer aufgebaut wird. Die maximale Dauer einer aktiven Phase wird durch das Volumen des Gaspuffers, die Anzahl und Art der installierten BHKW sowie das Biogasproduktionsprofil der Anlage bestimmt. Wenn der minimale Füllstand des Gaspuffers erreicht ist, wird die Energieerzeugung gestoppt (nach der aktiven Phase).",
                        passiveDesc: "Während einer passiven Phase wird Energie nur erzeugt, wenn ein Gasüberschuss besteht. Vor jeder passiven Phase wird automatisch ein Zeitfenster eingerichtet, in dem der Gaspuffer reduziert wird. Die maximale Dauer einer passiven Phase wird durch das Volumen des Gaspuffers und das Biogasproduktionsprofil der Anlage bestimmt. Wenn der maximale Füllstand des Gaspuffers erreicht ist, startet die Energieerzeugung (nach der passiven Phase).",
                        permanence: "Ständige Produktion anregen, indem dynamisch auf einem Motor bei nicht maximalem Gasvorrat betrieben wird",
                        permanency: "Dauerhafter Betrieb",
                        activateStep: {
                            title: "SMART aktivieren",
                            content: "Aktivieren Sie SMART, um das Produktionsprofil der Anlage zu steuern"
                        },
                        timeslotStep: {
                            title: "Zeitfenster hinzufügen",
                            content: "Fügen Sie ein aktives oder passives Zeitfenster hinzu"
                        },
                        fromToStep: {
                            title: "Von / Bis",
                            content: "Sie legen sowohl den Start- als auch den Endtag sowie die Uhrzeit fest"
                        },
                        activePassiveStep: {
                            title: "Aktiv oder Passiv",
                            content: "Wählen Sie, ob Sie ein aktives oder passives Zeitfenster festlegen möchten"
                        },
                        saveStep: {
                            title: "Zeitfenster speichern",
                            content: "Speichern Sie Ihre geänderten Einstellungen"
                        },
                        removeStep: {
                            title: "Zeitfenster entfernen",
                            content: "Entfernen Sie hier Ihr Zeitfenster"
                        },
                        visualStep: {
                            title: "Visuelle Darstellung",
                            content: "Dieses Diagramm vergleicht die SMART-Einstellungen mit dem Produktionsprofil der letzten Woche"
                        },
                        daySwitchStep: {
                            title: "Visuelle Darstellung",
                            content: "Wechseln Sie einfach zum nächsten oder vorherigen Tag"
                        },
                    },
                    gas: {
                        label: "Gas",
                        minGasBufferLevel: "Gaspuffer min",
                        maxGasBufferLevel: "Gaspuffer max",
                        gasBufferLevel: "Gaspuffer aktuell",
                        defaultMinGasBufferLevel: "Standardmäßiger gaspuffer min",
                        defaultMaxGasBufferLevel: "Standardmäßiger gaspuffer min",
                        airInjectionTime: "Belüftung",
                        maxAirInjectionTime: "Maximale Belüftung",
                        torch: "Fackel",
                        torchType: "Fackel-Typ",
                        torchStatus: "Status Fackel",
                        torchError: "Fehler Fackel",
                        h2s: "H₂S",
                        h2sLevelReactor: "H₂S-Reaktor",
                        h2sLevelMotor: "H₂S Motor",
                        filterLoad: "Filterbelastung",
                        sample: "Probe",
                        carbonFilterSize: "Größe Kohlefilter",
                        h2sMeasurements: "H₂S-Messungen",
                        measurements: "Messungen",
                        changeTo: "Ändern zu",
                        none: "Keine",
                        container: "Behälter",
                        extern: "Extern",
                        foamBeaterInstalled: "Schaumschläger installiert",
                        antiFoamInjectionsInstalled: "Antischauminjektionen installiert",

                    },
                    engine: {
                        label: "motor",
                        startEngine: "Motor starten",
                        stopEngine: "Motor abstellen",
                        power: "Leistung",
                        desiredPower: "Leistung einstellen",
                        maxPower: "Maximum power",
                        rpm: "Drehzahl (n/min)",
                        lambda: "Lambda",
                        throttleValve: "Position Servo",
                        engineType: "Engine type",
                        engineStatus: "Motorzustand",
                        engineError: "Motorfehler",
                        producedEnergy: "Produzierte grüne Energie",
                        operatingHours: "Betriebsstunden des Motors",
                        totalOperatingHours: "Gesamtbetriebsstunden des Motors",
                        gasUsage: "Gasverbrauch",
                        throttlePosition: "Position Throttle",
                        noErrors: "All systems operational.",
                        production: "Production",
                        servoAuto: "Der Servo wird automatisch eingestellt",
                        servoControl: "Servosteuerung",
                        temperature: "Motortemperatur",
                        restart: "Automatischer Motorstart, wenn Gas verfügbar ist",
                        idle: "Leerlauf",
                        running: "Läuft",
                        waiting: "Warten auf das Hochfahren",
                        booting: "Hochfahren",
                        shuttingDown: "Herunterfahren",
                        advanced: "Erweitert",
                        numberOfEngines: "Anzahl der Motoren",
                        predeterminedRunHours: "Vorgegebene Betriebsstunden",
                        easyStart1: "Easy start motor 1",
                        easyStart2: "Easy start motor 2",
                        setError1: "Fehler Motor 1 einstellen",
                        setError2: "Fehler Motor 2 einstellen",
                        fuel: "Kraftstoff",
                        airIntakeOpen: "Lufteinlass offen",
                        airIntakeClosed: "Lufteinlass geschlossen",
                    },
                    service: {
                        label: "Wartung",
                        lastMaintenance: "Betriebsstunden bis zum nächsten Ölwechsel",
                        currentlyInstalled: "Aktuell installierte Betriebsstunden",
                        total: "Gesamt-Betriebsstunden",
                        mixer: "Mischer",
                        foamBeater: "Schaumschläger",
                        intakePump: "Einsaugpumpe",
                        digestatePump: "Gärrestpumpe",
                        newMixer: "Neuer Mischer installiert",
                        newEngine: "Neuer motor installiert",
                        newFoamBeater: "Neuer Schaumschläger installiert",
                        newIntakePump: "Neue Einsaugpumpe installiert",
                        newDigestatePump: "Neue Gärrestpumpe installiert",
                        confirmationMessage: "Sind Sie sicher, dass Sie zurücksetzen wollen, den {{type}}",
                        maintenanceMessage: "Wartungsstunden wurden zurückgesetzt für {{type}}",
                        newEquipmentMessage: "Neue Ausrüstung installiert:",
                        prints: "Prints",
                        firstOnline: "Erstes Online",
                        lastOnline: "Letztes Online",
                        serial: "Seriennummer",
                        type: "Typ",
                        engine: "Motor",
                        engine1: "Motor 1",
                        engine2: "Motor 2",
                        h2s1: "H₂S-Motor 1",
                        h2s2: "H₂S-Motor 2",
                        resetTo: "Zurücksetzen auf x Stunden (Standard 0)",
                        timeFilterReplace: "Zeit seit Filterwechsel",
                        daysSinceOil: "Tage seit Ölwechsel",
                        newGenerator: "Neuer Generator installiert",
                        generator: "Generator",
                        generator1: "Generator 1",
                        generator2: "Generator 2",
                        daysSinceOilChange: "Tage seit dem Ölwechsel",
                        videoMessage: "Video zum Ölwechsel => Dokumentenseite, unter Allgemeine Videos.",
                        videoRoom: "Ein Servicetechniker hat einen Video-Raum für diese Maschine geöffnet. Klicken Sie hier, um beizutreten.",
                        videoButton: "Einen Video-Raum für diese Maschine starten",
                        videoButtonSub: "Benutzer mit Zugriff können den Anruf von ihrer Service-Seite aus beitreten.",
                        videoClose: "Video-Raum schließen"
                    },
                    net: {
                        net: "Netzanschluss",
                        meterSettings: "Zählereinstellungen",
                        voltagePerPhase1: "Spannung pro Phase (1)",
                        voltagePerPhase2: "Spannung pro Phase (2)",
                        voltagePerPhase3: "Spannung pro Phase (3)",
                        frequencyPerPhase: "Frequenz pro Phase",
                        angleBetweenPhase1And2: "Winkel zwischen Phase 1 und 2",
                        ownPowerConsumption: "Eigener Stromverbrauch",
                        ownEnergyConsumption: "Eigener Energieverbrauch",
                        bioguardVersion: "Bioguard-Version",
                        brutoProducedEnergy: "Brutto produzierte Energie",
                        nettoProducedEnergy: "Netto produzierte Energie",
                        producedEnergy: "Produzierte Energie",
                        producedEnergyEngine1: "Produzierte Energie Motor 1",
                        producedEnergyEngine2: "Produzierte Energie Motor 2",
                        monitoringRelay: "Überwachungsrelais",
                    },
                    reactor: {
                        label: "Reaktor",
                        reactorTemp: "Temperatur Reaktor",
                        reactorTempTarget: "Soll-Reaktortemperatur",
                        reactorLevel: "Sollwert Reaktorfüllstand",
                        hydrostaticPressure: "Reaktorfüllstand aktuell",
                        siloType: "Silo-Typ",
                        extraHeating: "Zusätzliche Heizung",
                        antiFrost: "Anti frost",
                        antiFrostIndex: "Anti frost index",
                        antiFrostAuto: "Der Frostindex wird automatisch basierend auf der lokalen Temperatur eingestellt",
                        antiFrostMessageNew: "Bei jedem Frostindexzyklus wird die Güllepumpe für 4 Sekunden aktiviert und die Gärrestpumpe pimpt ca. 100 l Gärrest, bezogen auf den eingestellten Durchfluss.",
                        antiFrostMessageOld: "Bei jedem Frostindexzyklus wird die Güllepumpe für 4 s und die Gärrestpumpe für 120 s aktiviert.",
                        coolwater: "Kühlwasser",
                        statusCoolingValve: "Kühlwasserventil",
                        waterTempIn: "Kühlwassertemperatur Reaktoreingang",
                        waterTempOut: "Kühlwassertemperatur Reaktorausgang",
                        waterTempMotorOut: "Kühlwassertemperatur Ausgang Motor 1",
                        waterTempMotorOut2: "Kühlwassertemperatur Ausgang Motor 2",
                        coolingWaterTemp: "Einstellung Kühlwassertemperatur",
                        maxCoolingWaterTemp: "Grenzwert Kühlwassertemperatur",
                        waterPressure: "Wasserdruck im Kaltwasserkreislauf",
                        foamBeater: "Antischaum",
                        foambeatEveryXMinutes: "Schaum brechen alle",
                        foambeatForXSeconds: "Für",
                        mixer: "Mixer",
                        mixerMaxPower: "Maximale Leistung Mixer",
                        mixerActive: "Mixer aktiv",
                        mixEveryXMinutes: "Mischzeitpunkt: Alle",
                        mixForXSeconds: "Mischdauer",
                        blockMixingFrom: "Kein Mischen ab ",
                        blockMixingTo: "Bis",
                        mixerInfo: "Der Mixer wird automatisch für die ausgewählte Dauer mit dem ausgewählten Zeitintervall eingeschaltet. Während des Mischersperrzeitfensters wird der Mischer gestoppt, wenn die Mischerleistung die aktuelle Stromerzeugung der Anlage übersteigt. Der Mischer wird während des BHKW-Starts vorübergehend angehalten, bis 50 % der Nennleistung erreicht sind, um den Einschaltstrom zu ermöglichen",
                        mix1Hour: "Mix 1 stunde",
                        coolwaterValveInstalled: "Geregeltes 3-Wege-Ventil",
                        bypassFan: "Steuerventil-Bypass/Lüfter",
                        digester: "Regelventil Fermenter / externer Wärmeverbrauch",
                        silo: "Silo",
                        bag: "Beutel",
                        plateHeatExchanger: "Plattenwärmetauscher",
                        timesPerDay: "mal / Tag",
                        intern: "Intern",
                        extern: "Extern",
                        tempHigh: "Temperatur hoch",
                        externMessage: "Der folgende Fehler wird nicht ausgelöst, wenn der Mischer auf extern eingestellt ist: Mischer funktioniert nicht, bitte thermisch zurücksetzen.",
                        bypass: "Bypass",
                        ventilator: "Ventilator",
                    },
                    efficiency: {
                        label: "Effizienz",
                        production: "Energieerzeugung der letzten 30 Tage",
                        consumption: "Energieverbrauch der letzten 30 Tage",
                        nettoProduction: "Nettoenergieerzeugung der letzten 30 Tage",
                        averageEfficiency: "Durchschnittliche Effizienz der letzten 30 Tage",
                        averageTechnicalEfficiency: "Durchschnittliche technische Verfügbarkeit der letzten 30 Tage",
                        bgp: "Güllequalität (BGP-Charge)",
                        top10: "Top 10 Projekte",
                        realised: "Realisierte Effizienz",
                        technical: "Technische Verfügbarkeit",
                        bgpInfo: "Schätzung des Biogaspotenzials [m³/Tonne]. Verluste, die durch Ausfallzeiten, Überproduktion und oder Abfackeln entstehen, werden nicht berücksichtigt",
                        pointPerDay: "%/Tag",
                        trendline: "Trendlinie",
                    },
                    general: {
                        label: "Allgemein",
                        h2sSensorFirmwareVersion: "H₂S-Sensor Firmware-Version",
                        idH2s: "S/N H₂S-ID",
                        bioId: "S/N Biotronic-ID",
                        identifier: "Installationskennung",
                        collabContract: "Kooperationsvertrag",
                        own: "Wartungsvertrag",
                        sold: "Verkaufte Installation",
                        productionDate: "Produktionsdatum",
                        commissioningDate: "Inbetriebnahmedatum",
                        controlCardTemp: "Steuerkartentemperatur",
                        batteryVoltage: "Batteriespannung",
                        motorVoltage: "Motorspannung",
                        overtempFlueGasHeatExch: "Übertemperatur Rauchgaswärmetauscher Motor",
                        rebootMainBoard: "Hauptplatine neu starten",
                        rebootNow: "Jetzt neu starten",
                        utcOffset: "UTC-Offset",
                        location: "Standort",
                        network: "Netzwerk",
                        ip: "IP",
                        mask: "Maske",
                        gateway: "Gateway",
                        dns: "DNS",
                        pcb: "Leiterplatte",
                        firmware: "Firmware",
                        mainControlUnit: "Hauptsteuerungseinheit",
                        bioguard: "Bioguard",
                        biotronicEngine1: "Biotronic Motor 1",
                        biotronicEngine2: "Biotronic Motor 2",
                        h2sEngine1: "H₂S Motor 1",
                        h2sEngine2: "H₂S Motor 2",
                        bioIdEngine1: "Bio ID Motor 1",
                        bioIdEngine2: "Bio ID Motor 2",
                        modificationsToMachine: "Änderungen an der Maschine",
                        name: "Name",
                        normal: "Normal",
                        lowError: "Niedrig (Fehler)",
                    },
                    feeding: {
                        label: "Fütterung",
                        feedingVolume: "Aufnahmemenge pro Zyklus",
                        feedingCyclesPerDay: "Fütterungszyklen pro Tag",
                        feedingBalance: "Fütterungssaldo",
                        autoFeedingActive: "Automatische Fütterung",
                        autoFeedingStartHour: "Automatische Fütterung",
                        exitPumpRunning: "Manuelles Abpumpen",
                        feedingCycle: "Fütterungszyklus",
                        onlyInput: "Nur Einpumpen",
                        onlyOutput: "Nur Auspumpen",
                        startFeeding: "Anfang",
                        stopFeeding: "Fütterung stoppen",
                        pumpOutFlowRate: "Auspump-Einstellungen",
                        kiloPerPulse: "Impuls des Durchflussmessers",
                        intakeTimeout: "Fütterung: Zeitüberschreitung Fütterung",
                        averageDailyFeeding: "Durchschn. Fütterung pro Tag im letzten Monat",
                        retentionTime: "Aufbewahrungszeit",
                        statusValvePumpIn: "Ventil Reaktoreingang - 2",
                        statusValvePumpOut: "Ventil Reaktorausgang - 3",
                        statusValveRetourReactor: "Ventil Reaktorrücklauf - 4",
                        valveCurrentActuator: "Ventilstrom",
                        lastFeeding: "Tatsächlich - zuletzt",
                        startHourLastFeeding: "Beginn der letzten Fütterung",
                        feedingStatus: "Fütterungszustand",
                        feedingError: "Reaktor Fehler",
                        intakePumpTime: "Fütterungsdauer",
                        intakePumpAmount: "Einnahmemenge",
                        exitPumpTime: "Auspumpdauer",
                        exitPumpAmount: "Auspumpmenge",
                        feedingHistory: "Fütterungsverlauf",
                        startedOn: "Begann am",
                        temperature: "Temperatur",
                        reactorLevel: "Reaktorfüllstand3",
                        balance: "Balance",
                        acknowledge: "Fehler quittieren",
                        intakeSpeed: "Fütterungsgeschwindigkeit",
                        balanceWarning: "Warnung, dies wird keinen normalen Fütterungszyklus starten sondern stattdessen den verbleibenden Restwert in den Reaktor pumpen",
                        automaticFeedingSettings: "Automatische Fütterungseinstellungen",
                        manualFeeding: "Manuelle Fütterung",
                        pumpSettings: "Pumpeneinstellungen",
                        status: {
                            "0": "Leerlauf",
                            "1": "Füllstandsmessung",
                            "2": "Auspumpen",
                            "3": "Einpumpen",
                            "5": "Abtauen",
                            "6": "Gasabgabe",
                            "7": "Manuelles Auspumpen",
                            "10": "Starten",
                            "2.001": "Auspumpen: Vormischung",
                            "2.002": "Auspumpen",
                            "2.003": "Auspumpen: Auslassventil öffnen",
                            "2.004": "Auspumpen: Auslassventil öffnen",
                            "2.005": "Auspumpen: Starten der Gärrestpumpe",
                            "2.006": "Auspumpen",
                            "2.007": "Auspumpen: Pumpen von Gülle",
                            "2.008": "Auspumpen: Auspumpen stoppen",
                            "2.009": "Auspumpen: Auspumpen stoppen",
                            "2.010": "Auspumpen: Auslassventil schließen",
                            "2.011": "Auspumpen: Auslassventil schließen",
                            "2.012": "Auspumpen: Auspumpen stoppen",
                            "3.001": "Einpumpen: Vorbereitung",
                            "3.002": "Einpumpen: Vorbereitung",
                            "3.003": "Einpumpen: Vorbereitung",
                            "3.004": "Einpumpen: Pumpen von Gülle",
                            "3.005": "Einpumpen: Pumpen von Gülle",
                            "3.006": "Einpumpen: Abschließen",
                            "3.007": "Einpumpen: Abschließen",
                            "3.008": "Einpumpen: Pumpe stoppen",
                            "3.009": "Einpumpen: Pumpe stoppen",
                            "3.010": "Einpumpen: Pumpe stoppen",
                            "3.011": "Einpumpen: Pumpe stoppen",
                            "3.012": "Einpumpen: Abschließen",
                            "5.001": "Abtauen: Einpumpen testen",
                            "5.002": "Abtauen: Einpumpen testen",
                            "5.003": "Abtauen: Einpumpen testen",
                            "5.004": "Abtauen: Einpumpen testen",
                            "5.005": "Abtauen: Einpumpen stoppen",
                            "5.006": "Abtauen: Auslassventil öffnen",
                            "5.007": "Abtauen: Auslassventil öffnen",
                            "5.008": "Abtauen: Auspumpen testen",
                            "5.009": "Abtauen: Auspumpen testen",
                            "5.010": "Abtauen: Auspumpen testen",
                            "5.011": "Abtauen: Auspumpen testen",
                            "5.012": "Abtauen: Auspumpen testen",
                            "5.013": "Abtauen: Auslassventil schließen",
                            "5.014": "Abtauen: Auslassventil schließen",
                            "5.015": "Abtauen: Abschließen",
                            "6.001": "Gasabgabe: Auslassventil öffnen",
                            "6.002": "Gasabgabe: Auslassventil öffnen",
                            "6.003": "Gasabgabe: Auslassventil schließen",
                            "6.004": "Gasabgabe: Auslassventil schließen",
                            "6.005": "Gasabgabe",
                            "7.001": "Manuelles Auspumpen: Auslassventil öffnen",
                            "7.002": "Manuelles Auspumpen: Auslassventil öffnen",
                            "7.003": "Manuelles Auspumpen: Gülle pumpen",
                            "7.004": "Manuelles Auspumpen: Pumpe stoppen",
                            "7.005": "Manuelles Auspumpen: Auslassventil schließen",
                            "7.006": "Manuelles Auspumpen: Auslassventil schließen",
                            "7.007": "Manuelles Auspumpen: Abschließen",
                            "7.008": "Manuelles Auspumpen: Abschließen",
                            "10.001": "Starten: Ventil 2 schließen",
                            "10.002": "Starten: Ventil 2 schließen",
                            "10.003": "Starten: Auslassventil schließen",
                            "10.004": "Starten: Auslassventil schließen",
                            "10.005": "Starten: Ventil 4 schließen",
                            "10.006": "Starten: Ventil 4 schließen",
                            "10.007": "Starten: Elektrische Verbindung prüfen",
                            "10.008": "Starten: Elektrische Verbindung prüfen",
                            "10.009": "Starten"
                        }
                    },
                    users: {
                        label: "Benutzer",
                        customers: "Verknüpfung Kunde",
                        technicians: "Link zum Techniker",
                        resellers: "Link zum Wiederverkäufer",
                    },
                    recommendations: {
                        label: "Empfehlungen",
                    },
                    documents: {
                        label: "Dokumente"
                    },
                    warnings: {
                        "W001": "Energieverbrauch über dem Schwellenwert in den letzten 24 Stunden (72 kWh für 10-44-kW-Installation, 250 kWh für 60-74-kW-Installation)",
                        "W002": "Die Reaktortemperatur ist dramatisch gesunken",
                        "W003": "Service innerhalb von 48 Stunden",
                        "W004": "Kohlefilter Motor 1 muss ersetzt werden",
                        "W005": "Kohlefilter Motor 2 muss ersetzt werden",
                        "W006": "Gaspuffermessung eingefroren",
                        "W007": "Gaspuffermessung defekt",
                        "W008": "Gestern nicht gefüttert",
                        "W009": "Die Geschwindigkeit der letzten Fütterung lag 50% über de Durchschnitt des letzten Monats",
                        "W010": "H₂S-Wert zu hoch (>1500 ppm)",
                        "W011": "Belüftung ist 60",
                        "W012": "Die Belüftung ist kleiner oder gleich 3",
                        "W013": "Effizienz lag gestern bei 0% und Autofeed ist eingeschaltet",
                        "W014": "H₂S-Reaktormessung – Differenz übersteigt 400 ppm",
                        "W015": "Luftzufuhr sehr hoch - Gasqualität beachten",
                        "W016": "H₂S-Messung seit 24 h fehlgeschlagen",
                        "W017": "Mischeröl zu niedrig",
                        "W018": "Motoröl niedrig",
                        "W020": "Wasserdruck niedrig",
                        "W104": "H₂S-Wert zu hoch (>200) oder Belüftung = 60 oder 3",
                        "W105": "Belastung des Kohlefilters zu hoch",
                        "W114": "Effizienz war gestern nicht 0 und Autofeed ist aus",
                    }
                }
            },
            it: {
                translation: {
                    dashboard: {
                        nameIdentifier: "Nome o identificatore",
                        efficiencyAll: "Efficienza di tutte le macchine",
                        onlyErrors: "Solo errori",
                        toggleDisconnected: "Attiva/disattiva macchine scollegate",
                        productionCombined: "Produzione combinata (Wh)",
                        realisedTechnicalEfficiency: "Efficienza realizzata / Efficienza tecnica (%)",
                        efficiencyCombined: "Efficienza realizzata (%)",
                        technicalEfficiencyCombined: "Efficienza tecnica (%)",
                        bgp: "Bgp (m³/ton)",
                        consumption: "Consumo (Wh)",
                        manureLifeTime: "Durata del letame (giorni)",
                        feeding: "Alimentazione (kg)"
                    },
                    common: {
                        on: "Acceso",
                        off: "Spento",
                        add: "Aggiungi",
                        save: "Salva",
                        monday: "Lunedì",
                        tuesday: "Martedì",
                        wednesday: "Mercoledì",
                        thursday: "Giovedì",
                        friday: "Venerdì",
                        saturday: "Sabato",
                        sunday: "Domenica",
                        from: "Da",
                        to: "A",
                        hours: "ore",
                        reset: "Reset",
                        confirmation: "Conferma",
                        cancel: "Annulla",
                        set: "Regola",
                        auto: "Auto",
                        manual: "Manual",
                        minutes: "minuti",
                        seconds: "secondi",
                        turnOn: "Accendere",
                        turnOff: "Spegnere",
                        graphLoading: "Caricamento dei grafici...",
                        noData: "Nessun dato disponibile...",
                        hoursPerYear: "ore / anno",
                        datetime: "Data e ora",
                        warning: "Avvertimento",
                        error: "Errore",
                        type: "Tipo",
                        startdate: "Data di inizio",
                        enddate: "Data di fine",
                        all: "Tutti",
                        engine1: "Motore 1",
                        engine2: "Motore 2",
                        ongoing: "In corso",
                        both: "Entrambi",
                        days: "Giorni",
                        today: "Oggi",
                        sendAnswers: "Invia risposte",
                        employee: "Dipendente",
                        customer: "Cliente",
                        sales: "Vendite",
                        technician: "Tecnico",
                        admin: "Amministratore",
                        noDateKnown: "Data sconosciuta",
                        command: "Comando",
                        user: "Utente",
                        fillInSurvey: "Per favore, compila il sondaggio",
                        tons: "tonnellata",
                        count: "Conteggio",
                        disabled: "Disabilitato",
                        addField: "Aggiungi campo",
                        deleteField: "Elimina campo",
                        releases: "Rilasci",
                        open: "Aprire",
                        close: "Chiudere",
                    },
                    menu: {
                        dashboard: 'Pannello di controllo',
                        machines: 'Macchinari',
                        questionnaires: 'Questionari',
                        banners: "Banner",
                        errors: "Fehler und warnungen",
                        pcb: "Circuiti stampati",
                        tasks: "I miei compiti",
                        releases: "Note di rilascio",
                        resellers: "Rivenditori",
                        users: "Utenti",
                        recommendations: "Raccomandazioni",
                        suggestions: "Messaggi di raccomandazione",
                        language: "Lingua",
                        reconnection: "Questa macchina è attualmente offline. Prova a ottenere una nuova connessione con il pulsante sopra.",
                        profile: "Profilo",
                        logout: "Disconnettersi",
                        firmware: "Firmware",
                        sms: "SMS",
                        advanced: "Avanzato",
                        errors: "Errori",
                        warnings: "Avvisi"
                    },
                    statistics: {
                        label: "Statistiche",
                        group1: "Gruppo 1",
                        group2: "Gruppo 2",
                        addProperty: "Aggiungi proprietà",
                        removeProperty: "Rimuovi Proprietà",
                        startTutorial: "Inizia il tutorial",
                        exitTutorial: "Esci dal tutorial",

                        welcomeStep: {
                            title: "Benvenuti nella pagina delle statistiche",
                            content: "Qui è possibile confrontare facilmente diversi valori e tendenze."
                        },
                        propertyStep: {
                            title: "Selezione della proprietà",
                            content: "Per iniziare, selezionate semplicemente una proprietà che vi interessa dalla casella di selezione."
                        },
                        addPropertyStep: {
                            title: "Aggiunta e rimozione di proprietà",
                            content: "Provate ad aggiungere o rimuovere una proprietà, è possibile aggiungere fino a 4 proprietà per gruppo."
                        },
                        groupStep: {
                            title: "Attivazione del gruppo 2",
                            content: "Fare clic sul pulsante 'Aggiungi proprietà' per selezionare una proprietà per il Gruppo 2. Le proprietà in questo gruppo verranno mostrate sul lato destro del grafico."
                        },
                        dateRangeStep: {
                            title: "Selezione di un intervallo di date",
                            content: "Usate questo pulsante per scegliere un intervallo di date."
                        },
                        zoomInStep: {
                            title: "Ingrandimento",
                            content: "Trascinate il mouse sul grafico per selezionare un'area da ingrandire."
                        },
                        zoomOutStep: {
                            title: "Riduzione",
                            content: "Ripristinate l'intervallo di date precedentemente selezionato utilizzando il pulsante 'Riduzione'."
                        },
                        domainStep: {
                            title: "Regolazione dei domini",
                            content: "È possibile personalizzare il dominio massimo e minimo per gruppo utilizzando questi pulsanti."
                        },
                        csvStep: {
                            title: "Scarica dati in formato CSV",
                            content: "Scarica i dati attualmente visualizzati nel grafico in formato CSV."
                        },
                        imageStep: {
                            title: "Scarica immagine",
                            content: "Scarica il grafico attualmente visualizzato come immagine."
                        },
                        controlCardTemp: "Temperatura della scheda di controllo",
                        lowTensionError: "Errore di bassa tensione",
                        overTempFlueGasHeatExch: "Sovratermostato scambiatore di calore dei gas di scarico",
                        feedingCycleLevel: "Livello del ciclo di alimentazione",
                        batteryVoltage: "Tensione della batteria",
                        waterPressure: "Pressione dell'acqua",
                        shortInterval: "Intervalli di 10 minuti",
                        dailyInterval: "Intervalli giornalieri"
                    },
                    smart: {
                        label: "Smart",
                        heightOffset: "Offset altezza",
                        active: "Attivo",
                        passive: "Passivo",
                        preActive: "Pre-attivo",
                        prePassive: "Pre-passivo",
                        postActive: "Post-attivo",
                        postPassive: "Post-passivo",
                        activeDesc: "Durante un periodo attivo, la produzione energetica pianificata viene massimizzata. Prima di ogni periodo attivo, viene automaticamente fornito uno slot temporale 'pre-attivo' in cui viene accumulato il gas nel buffer. La durata massima di un periodo attivo è determinata dal volume del buffer del gas, dal numero e tipo di CHP installati e dal profilo di produzione del biogas dell'installazione. Quando viene raggiunto il livello minimo di riempimento del buffer del gas, la produzione di energia si interromperà (periodo post-attivo).",
                        passiveDesc: "Durante un periodo passivo, l'energia viene prodotta solo se c'è un surplus di gas. Prima di ogni periodo passivo, viene automaticamente fornito uno slot temporale durante il quale il buffer del gas viene ridotto. La durata massima di un periodo passivo è determinata dal volume del buffer del gas e dal profilo di produzione del biogas dell'installazione. Quando viene raggiunto il livello massimo di riempimento del buffer del gas, inizia la produzione di energia (periodo post-passivo).",
                        permanence: "Stimolare la produzione costante funzionando dinamicamente su un motore con un buffer di gas non massimo",
                        permanency: "Permanenza",
                        activateStep: {
                            title: "Attivazione di SMART",
                            content: "Attiva SMART per controllare il profilo di produzione dell'installazione"
                        },
                        timeslotStep: {
                            title: "Aggiungi slot temporale",
                            content: "Aggiungi uno slot temporale attivo o passivo"
                        },
                        fromToStep: {
                            title: "Da / A",
                            content: "Imposta sia l'inizio che la fine del giorno e l'ora"
                        },
                        activePassiveStep: {
                            title: "Attivo o Passivo",
                            content: "Seleziona se vuoi impostare uno slot temporale attivo o passivo"
                        },
                        saveStep: {
                            title: "Salva slot temporali",
                            content: "Salva le tue impostazioni modificate"
                        },
                        removeStep: {
                            title: "Rimuovi slot temporali",
                            content: "Rimuovi qui il tuo slot temporale"
                        },
                        visualStep: {
                            title: "Rappresentazione visuale",
                            content: "Questo grafico confronta le impostazioni SMART con il profilo di produzione della settimana scorsa"
                        },
                        daySwitchStep: {
                            title: "Rappresentazione visuale",
                            content: "Passa facilmente al giorno successivo o precedente"
                        },
                    },
                    gas: {
                        label: "Gas",
                        minGasBufferLevel: "Livello gas min",
                        maxGasBufferLevel: "Livello gas max",
                        gasBufferLevel: "Livello gas attuale",
                        defaultMinGasBufferLevel: "Predefinito livello gas min",
                        defaultMaxGasBufferLevel: "Predefinito livello gas max",
                        airInjectionTime: "Aerazione",
                        maxAirInjectionTime: "Aerazione massima",
                        torch: "Flare",
                        torchType: "Flare type",
                        torchStatus: "Flare status",
                        torchError: "Flare errore",
                        h2s: "H₂S",
                        h2sLevelReactor: "H₂S reattore",
                        h2sLevelMotor: "H₂S Motore",
                        filterLoad: "Saturazione filtro",
                        sample: "Campiona",
                        carbonFilterSize: "Dimensioni del filtro al carbone",
                        h2sMeasurements: "Misurazioni H₂S",
                        measurements: "Misurazioni",
                        changeTo: "Cambia in",
                        none: "Nessuno",
                        container: "Contenitore",
                        extern: "Esterno",
                        foamBeaterInstalled: "Battitore di schiuma installato",
                        antiFoamInjectionsInstalled: "Iniezioni antischiuma installate",

                    },
                    engine: {
                        label: "motore",
                        startEngine: "Start motore",
                        stopEngine: "Arresta motore",
                        power: "Potenza",
                        desiredPower: "Potenza impostata",
                        maxPower: "Potenza massima",
                        rpm: "Giri/min",
                        lambda: "Lambda",
                        throttleValve: "Posizione del servo",
                        engineType: "Tipo di motore",
                        engineStatus: "Stato motore",
                        engineError: "Errore del motore",
                        producedEnergy: "Produzione di energia pulita",
                        operatingHours: "Ore motore in marcia",
                        totalOperatingHours: "Ore totali funzionamento motore",
                        gasUsage: "Consumo di gas",
                        throttlePosition: "Posizione del throttle",
                        noErrors: "Tutti i sistemi operativi.",
                        production: "Produzione",
                        servoAuto: "Il servo è impostato automaticamente",
                        servoControl: "Servocomando",
                        temperature: "Temperatura del motore",
                        restart: "Riavvio automatico quando il gas è disponibile.",
                        idle: "Inattivo",
                        running: "In esecuzione",
                        waiting: "In attesa di avvio",
                        booting: "Avvio",
                        shuttingDown: "Spegnimento",
                        advanced: "Avanzato",
                        numberOfEngines: "Numero di motori",
                        predeterminedRunHours: "Ore di funzionamento predeterminate",
                        easyStart1: "Easy start motore 1",
                        easyStart2: "Easy start motore 2",
                        setError1: "Errore di impostazione motore 1",
                        setError2: "Errore di impostazione motore 2",
                        fuel: "Carburante",
                        airIntakeOpen: "Presa d'aria aperta",
                        airIntakeClosed: "Presa d'aria chiusa",
                    },
                    service: {
                        label: "Manutenzione",
                        lastMaintenance: "Ore di funzionamento fino al prossimo cambio dell'olio",
                        currentlyInstalled: "Ore di funzionamento attualmente installate",
                        total: "Totale ore di funzionamento",
                        mixer: "Miscelatore",
                        foamBeater: "Frustino per schiuma",
                        intakePump: "Pompa di aspirazione",
                        digestatePump: "Pompa di digestato",
                        newMixer: "Nuovo miscelatore installato",
                        newEngine: "Nuovo motore installato",
                        newFoamBeater: "Nuovo frustino per schiuma installato",
                        newIntakePump: "Nuova pompa di aspirazione installata",
                        newDigestatePump: "Nuova pompa di digestato installata",
                        confirmationMessage: "Sei sicuro di voler ripristinare il {{type}}",
                        maintenanceMessage: "Le ore di manutenzione sono state ripristinate per {{type}}",
                        newEquipmentMessage: "Nuova attrezzatura installata:",
                        prints: "Prints",
                        firstOnline: "Primo online",
                        lastOnline: "Ultimo online",
                        serial: "Numero seriale",
                        type: "Tipo",
                        engine: "Motore",
                        engine1: "Motore 1",
                        engine2: "Motore 2",
                        h2s1: "Motore H₂S 1",
                        h2s2: "Motore H₂S 2",
                        resetTo: "Resetta a x ore (predefinito 0)",
                        timeFilterReplace: "Tempo trascorso dalla sostituzione del filtro",
                        daysSinceOil: "Giorni dal cambio dell'olio",
                        newGenerator: "Installazione di un nuovo generatore",
                        generator: "Generatore",
                        generator1: "Generatore 1",
                        generator2: "Generatore 2",
                        daysSinceOilChange: "Giorni dall'ultimo cambio dell'olio",
                        videoMessage: "Video sul cambio dell'olio => Pagina dei documenti, sotto Video generali.",
                        videoRoom: "Un tecnico di servizio ha aperto una stanza video per questa macchina. Clicca qui per partecipare.",
                        videoButton: "Avvia una stanza video per questa macchina",
                        videoButtonSub: "Gli utenti con accesso possono unirsi alla chiamata dalla loro pagina di servizio.",
                        videoClose: "Chiudi la stanza video"
                    },
                    net: {
                        net: "Rete",
                        meterSettings: "Impostazioni contatore",
                        voltagePerPhase1: "Voltaggio per fase (1)",
                        voltagePerPhase2: "Voltaggio per fase (2)",
                        voltagePerPhase3: "Voltaggio per fase (3)",
                        frequencyPerPhase: "Frequenza per fase",
                        angleBetweenPhase1And2: "Angolo tra fase 1 e 2",
                        ownPowerConsumption: "Potenza autoconsumo",
                        ownEnergyConsumption: "Energia autoconsumo",
                        bioguardVersion: "Versione bioguard",
                        brutoProducedEnergy: "Energia prodotta lorda",
                        nettoProducedEnergy: "Energia prodotta netta",
                        producedEnergy: "Energia prodotta",
                        producedEnergyEngine1: "Energia prodotta motore 1",
                        producedEnergyEngine2: "Energia prodotta motore 2",
                        monitoringRelay: "Relè di monitoraggio",
                    },
                    reactor: {
                        label: "Reattore",
                        reactorTemp: "Temperatura reattore",
                        reactorTempTarget: "Temperatura reattore obiettivo",
                        reactorLevel: "Livello impostato",
                        hydrostaticPressure: "Livello reattore attuale",
                        siloType: "Tipo reattore",
                        extraHeating: "Riscaldamento extra",
                        antiFrost: "Anticongelamento",
                        antiFrostIndex: "Indice anticongelamento",
                        antiFrostAuto: "L'indice di gelo viene impostato automaticamente in base alla temperatura locale.",
                        antiFrostMessageNew: "Ogni ciclo di indice di gelo attiverà la pompa di alimentazione per 4 s e la pompa di uscita scarica circa 100 l di digestato, in base alla portata impostata.",
                        antiFrostMessageOld: "Ogni ciclo di indice di gelo attiverà la pompa di alimentazione per 4 s e la pompa di uscita per 120 s.",
                        coolwater: "Acqua di raffreddamento",
                        statusCoolingValve: "Valvola a 3 vie acqua",
                        waterTempIn: "Temperatura acqua ingresso reattore",
                        waterTempOut: "Temperatura acqua uscita reattore",
                        waterTempMotorOut: "Temperatura acqua uscita motore 1",
                        waterTempMotorOut2: "Temperatura acqua uscita motore 2",
                        coolingWaterTemp: "Impostazione temperatura acqua",
                        maxCoolingWaterTemp: "Limite temperatura acqua",
                        waterPressure: "Pressione dell'acqua nel circuito idraulico",
                        foamBeater: "Schiuma",
                        foambeatEveryXMinutes: "Rompere la schiuma ogni",
                        foambeatForXSeconds: "Per",
                        mixer: "Miscelatore",
                        mixerMaxPower: "Potenza massima del mixer",
                        mixerActive: "Mixer aktiv",
                        mixEveryXMinutes: "Miscelare ogni",
                        mixForXSeconds: "Per",
                        blockMixingFrom: "Nessuna miscelazione da",
                        blockMixingTo: "A",
                        mixerInfo: "Il mixer si accende automaticamente per la durata selezionata con l'intervallo di tempo selezionato. Durante la finestra temporale di blocco del mixer, il mixer viene arrestato quando la potenza installata del mixer supera l'attuale produzione di energia dell'impianto. Il miscelatore viene temporaneamente arrestato durante l'avvio CHP fino al raggiungimento del 50% della potenza nominale per consentire la corrente di spunto",
                        mix1Hour: "Miscelare 1 ora",
                        coolwaterValveInstalled: "Valvola a 3 vie regolabile",
                        bypassFan: "Valvola di controllo by-pass / ventilatore",
                        digester: "Valvola di controllo digestore / consumo di calore esterno",
                        silo: "Silo",
                        bag: "Sacco",
                        plateHeatExchanger: "Scambiatore di calore a piastre",
                        timesPerDay: "volte / giorno",
                        intern: "Internamente",
                        extern: "Esternamente",
                        tempHigh: "Temperatura alta",
                        externMessage: "Il seguente errore non verrà attivato se il miscelatore è impostato su esterno: Miscelatore non funziona, si prega di resettare il termico.",
                        bypass: "Bypass",
                        ventilator: "Ventilatore",
                    },
                    efficiency: {
                        label: "Efficienza",
                        production: "Produzione di energia negli ultimi 30 giorni",
                        consumption: "Consumo di energia negli ultimi 30 giorni",
                        nettoProduction: "Produzione enlettrica netta negli ultimi 30 giorni",
                        averageEfficiency: "Efficienza media negli ultimi 30 giorni",
                        averageTechnicalEfficiency: "Disponibilità tecnica media negli ultimi 30 giorni",
                        bgp: "Qualità del letame (BGP batch)",
                        top10: "I migliori 10 impianti",
                        realised: "efficienza normalizzata",
                        technical: "Disponibilità tecnica",
                        bgpInfo: "Stima del BGP [m³/ton]. Non tiene conto delle perdite causate da tempi di inattività, sovrapproduzione o combustione alla torcia",
                        pointPerDay: "%/giorno",
                        trendline: "Linea di tendenza",
                    },
                    general: {
                        label: "Generale",
                        h2sSensorFirmwareVersion: "Versione firmware sensore H₂S",
                        idH2s: "S/N ID H₂S",
                        bioId: "S/N ID Biotronic",
                        identifier: "Identificatore installazione",
                        collabContract: "Contratto di collaborazione",
                        own: "Contratto di manutenzione",
                        sold: "Installazione venduta",
                        productionDate: "Data di produzione",
                        commissioningDate: "Data di messa in servizio",
                        controlCardTemp: "Temperatura della scheda di controllo",
                        batteryVoltage: "Tensione della batteria",
                        motorVoltage: "Tensione del motore",
                        overtempFlueGasHeatExch: "Surriscaldamento motore scambiatore di calore dei fumi",
                        rebootMainBoard: "Riavvio scheda principale",
                        rebootNow: "Riavvia ora",
                        utcOffset: "Offset UTC",
                        location: "Posizione",
                        network: "Rete",
                        ip: "IP",
                        mask: "Maschera",
                        gateway: "Gateway",
                        dns: "DNS",
                        pcb: "PCB",
                        firmware: "Firmware",
                        mainControlUnit: "Unità di controllo principale",
                        bioguard: "Bioguard",
                        biotronicEngine1: "Motore Biotronic 1",
                        biotronicEngine2: "Motore Biotronic 2",
                        h2sEngine1: "Motore H₂S 1",
                        h2sEngine2: "Motore H₂S 2",
                        bioIdEngine1: "Motore Bio ID 1",
                        bioIdEngine2: "Motore Bio ID 2",
                        modificationsToMachine: "Modifiche alla macchina",
                        name: "Nome",
                        normal: "Normale",
                        lowError: "Basso (errore)",
                    },
                    feeding: {
                        label: "Alimentazione",
                        feedingVolume: "Quantità aliementata per ciclo",
                        feedingCyclesPerDay: "Cicli di alimentazione per giorno",
                        feedingBalance: "Bilancio alimentazione",
                        autoFeedingActive: "Alimentazione automatica",
                        autoFeedingStartHour: "Alimentazione automatica",
                        exitPumpRunning: "Avviare il pompaggio manuale",
                        feedingCycle: "Ciclo di alimentazione",
                        onlyInput: "Solo carico",
                        onlyOutput: "Solo scarico",
                        startFeeding: "Start alimentazione",
                        stopFeeding: "Stop alimentazione",
                        pumpOutFlowRate: "Impostazione portata di scarico",
                        kiloPerPulse: "Pulsazione flussometro",
                        intakeTimeout: "Alimentazione: time-out alimentazione",
                        averageDailyFeeding: "Alimentazione giornaliera media il mese scorso",
                        retentionTime: "Ritenzione",
                        statusValvePumpIn: "Valvola liquame fresco - 2",
                        statusValvePumpOut: "Valvola scarico reattore - 3",
                        statusValveRetourReactor: "Valvola ricircolo reattore - 4",
                        valveCurrentActuator: "Corrente valvola",
                        lastFeeding: "Attuale - ultimo",
                        startHourLastFeeding: "Ora di inizio ultimo ciclo",
                        feedingStatus: "Stato del ciclo",
                        feedingError: "Errore di alimentazione",
                        intakePumpTime: "Tempo di alimentazione",
                        intakePumpAmount: "Quantità alimenata",
                        exitPumpTime: "Tempo di scarico",
                        exitPumpAmount: "Quantità scaricata",
                        feedingHistory: "Storico alimentazione",
                        startedOn: "Avviato il",
                        temperature: "Temperatura",
                        reactorLevel: "Livello",
                        balance: "Recupera",
                        acknowledge: "Identificazione dell'errore",
                        intakeSpeed: "Velocità di alimentazione",
                        balanceWarning: "Avviso, questo non avvierà un ciclo di alimentazione normale ma invece pomperà il valore rimanente del saldo nel reattore",
                        automaticFeedingSettings: "Impostazioni di alimentazione automatica",
                        manualFeeding: "Alimentazione manuale",
                        pumpSettings: "Impostazioni della pompa",
                        status: {
                            "0": "Inattivo",
                            "1": "Rilevamento del livello",
                            "2": "Uscita",
                            "3": "Aspirazione",
                            "5": "Sbrinamento",
                            "6": "Rilascio gas",
                            "7": "Uscita manuale",
                            "10": "Avvio",
                            "2.001": "Uscita: Pre-miscelazione",
                            "2.002": "Uscita",
                            "2.003": "Uscita: Apertura valvola di uscita",
                            "2.004": "Uscita: Apertura valvola di uscita",
                            "2.005": "Uscita: Avvio pompa di uscita",
                            "2.006": "Uscita",
                            "2.007": "Uscita: Pompa liquame in uscita",
                            "2.008": "Uscita: Stop uscita",
                            "2.009": "Uscita: Stop uscita",
                            "2.010": "Uscita: Chiusura valvola di uscita",
                            "2.011": "Uscita: Chiusura valvola di uscita",
                            "2.012": "Uscita: Stop uscita",
                            "3.001": "Aspirazione: Preparazione",
                            "3.002": "Aspirazione: Preparazione",
                            "3.003": "Aspirazione: Preparazione",
                            "3.004": "Aspirazione: Pompa liquame in aspirazione",
                            "3.005": "Aspirazione: Pompa liquame in aspirazione",
                            "3.006": "Aspirazione: Finitura",
                            "3.007": "Aspirazione: Finitura",
                            "3.008": "Aspirazione: Stop pompa",
                            "3.009": "Aspirazione: Stop pompa",
                            "3.010": "Aspirazione: Stop pompa",
                            "3.011": "Aspirazione: Stop pompa",
                            "3.012": "Aspirazione: Finitura",
                            "5.001": "Sbrinamento: Verifica aspirazione",
                            "5.002": "Sbrinamento: Verifica aspirazione",
                            "5.003": "Sbrinamento: Verifica aspirazione",
                            "5.004": "Sbrinamento: Verifica aspirazione",
                            "5.005": "Sbrinamento: Stop aspirazione",
                            "5.006": "Sbrinamento: Apertura valvola di uscita",
                            "5.007": "Sbrinamento: Apertura valvola di uscita",
                            "5.008": "Sbrinamento: Verifica aspirazione",
                            "5.009": "Sbrinamento: Verifica aspirazione",
                            "5.010": "Sbrinamento: Verifica aspirazione",
                            "5.011": "Sbrinamento: Verifica aspirazione",
                            "5.012": "Sbrinamento: Verifica aspirazione",
                            "5.013": "Sbrinamento: Chiusura valvola di uscita",
                            "5.014": "Sbrinamento: Chiusura valvola di uscita",
                            "5.015": "Sbrinamento: Finitura",
                            "6.001": "Rilascio gas: Apertura valvola di uscita",
                            "6.002": "Rilascio gas: Apertura valvola di uscita",
                            "6.003": "Rilascio gas: Chiusura valvola di uscita",
                            "6.004": "Rilascio gas: Chiusura valvola di uscita",
                            "6.005": "Rilascio gas",
                            "7.001": "Uscita manuale: Apertura valvola di uscita",
                            "7.002": "Uscita manuale: Apertura valvola di uscita",
                            "7.003": "Uscita manuale: Pompa di liquami",
                            "7.004": "Uscita manuale: Arresto pompa",
                            "7.005": "Uscita manuale: Chiusura valvola di uscita",
                            "7.006": "Uscita manuale: Chiusura valvola di uscita",
                            "7.007": "Uscita manuale: Finitura",
                            "7.008": "Uscita manuale: Finitura",
                            "10.001": "Avvio: Chiusura valvola 2",
                            "10.002": "Avvio: Chiusura valvola 2",
                            "10.003": "Avvio: Chiusura valvola di uscita",
                            "10.004": "Avvio: Chiusura valvola di uscita",
                            "10.005": "Avvio: Chiusura valvola 4",
                            "10.006": "Avvio: Chiusura valvola 4",
                            "10.007": "Avvio: Verifica connessione elettrica",
                            "10.008": "Avvio: Verifica connessione elettrica",
                            "10.009": "Avvio"
                        }
                    },
                    users: {
                        label: "Utenti",
                        customers: "Clienti collegati",
                        technicians: "Tecnici collegati",
                        resellers: "Rivenditori collegati",
                    },
                    recommendations: {
                        label: "Raccomandazioni",
                    },
                    documents: {
                        label: "Documenti"
                    },
                    warnings: {
                        "W001": "Consumo energetico superiore alla soglia nelle ultime 24 ore (72 kWh per installazione 10-44kW, 250 kWh per installazione 60-74kW)",
                        "W002": "Temperatura del reattore diminuita drasticamente",
                        "W003": "Intervallo di manutenzione entro 48 ore",
                        "W004": "I carboni attivi del motore 1 devono essere sostituiti",
                        "W005": "I carboni attivi del motore 2 devono essere sostituiti",
                        "W006": "Misurazione del livello gas congelata",
                        "W007": "Misurazione del livello gas difettosa",
                        "W008": "Non alimentato ieri",
                        "W009": "La velocità di alimentazione dell'ultimo pompaggio è stata del 50% più veloce della media mensile",
                        "W010": "Valore H₂S troppo alto (> 1500)",
                        "W011": "L'aerazione è a 60",
                        "W012": "L'aerazione è inferiore o uguale a 3",
                        "W013": "Ieri l'efficienza è stata 0 e l'alimentazione automatica è attiva",
                        "W014": "H₂S reactor measurement - difference exceeds 400ppm",
                        "W015": "Iniezione dell'aria molto alta - ricorda la qualità del gas",
                        "W016": "Misurazione H₂S fallita da 24 ore",
                        "W017": "Olio del mixer troppo basso",
                        "W018": "Olio del motore basso",
                        "W020": "Pressione dell'acqua bassa",
                        "W104": "Valore H₂S troppo alto (> 200) o aerazione = 60 o 3",
                        "W105": "Saturazione del filtro a carboni alta",
                        "W114": "Ieri l'efficienza non è stata 0 e l'alimentazione automatica era spenta",
                    }

                }
            },
            se: {
                translation: {
                    dashboard: {
                        nameIdentifier: "Namn eller identifierare",
                        efficiencyAll: "Effektivitet för alla maskiner",
                        onlyErrors: "Endast fel",
                        toggleDisconnected: "Växla bortkopplade maskiner",
                        productionCombined: "Kombinerad produktion (Wh)",
                        realisedTechnicalEfficiency: "Uppnådd / Teknisk effektivitet (%)",
                        efficiencyCombined: "Uppnådd effektivitet (%)",
                        technicalEfficiencyCombined: "Teknisk effektivitet (%)",
                        bgp: "Bgp (m³/ton)",
                        consumption: "Förbrukning (Wh)",
                        manureLifeTime: "Gödsellivstid (dagar)",
                        feeding: "Utfodring (kg)"
                    },
                    common: {
                        on: "På",
                        off: "Av",
                        add: "Lägg till",
                        save: "Spara",
                        monday: "Måndag",
                        tuesday: "Tisdag",
                        wednesday: "Onsdag",
                        thursday: "Torsdag",
                        friday: "Fredag",
                        saturday: "Lördag",
                        sunday: "Söndag",
                        from: "Från",
                        to: "Till",
                        hours: "timmar",
                        reset: "Återställ",
                        confirmation: "Bekräftelse",
                        cancel: "Avbryt",
                        set: "Justera",
                        auto: "Auto",
                        manual: "Manuel",
                        minutes: "Minuter",
                        seconds: "Sekunder",
                        turnOn: "Sätta på",
                        turnOff: "Stäng av",
                        graphLoading: "Laddning av grafer...",
                        noData: "Ingen data tillgänglig...",
                        hoursPerYear: "timmar / år",
                        datetime: "Datum och tid",
                        warning: "Varning",
                        error: "Fel",
                        type: "Typ",
                        startdate: "Startdatum",
                        enddate: "Slutdatum",
                        all: "Alla",
                        engine1: "Motor 1",
                        engine2: "Motor 2",
                        ongoing: "Pågående",
                        both: "Båda",
                        days: "Dagar",
                        today: "Idag",
                        sendAnswers: "Skicka svar",
                        employee: "Anställd",
                        customer: "Kund",
                        sales: "Försäljning",
                        technician: "Tekniker",
                        admin: "Administratör",
                        noDateKnown: "Inget datum känt",
                        command: "Kommando",
                        user: "Användare",
                        fillInSurvey: "Vänligen fyll i undersökningen",
                        tons: "ton",
                        count: "Räkna",
                        disabled: "Inaktiverad",
                        addField: "Lägg till fält",
                        deleteField: "Ta bort fält",
                        releases: "Utgåvor",
                        open: "Öppna",
                        close: "Stänga",
                    },
                    menu: {
                        dashboard: 'Instrumentbräda',
                        machines: 'Maskineri',
                        questionnaires: 'Frågeformulär',
                        banners: "Banderoller",
                        errors: "Fel och varningar",
                        pcb: "Tryckta kretskort",
                        tasks: "Mina uppgifter",
                        releases: "Utgivningsanteckningar",
                        resellers: "Återförsäljare",
                        users: "Användare",
                        recommendations: "Rekommendationer",
                        suggestions: "Rekommendationsmeddelanden",
                        language: "Språk",
                        reconnection: "Denna maskin är för närvarande offline. Försök att få en ny anslutning med knappen ovan.",
                        profile: "Profil",
                        logout: "Logga ut",
                        firmware: "Firmware",
                        sms: "SMS",
                        advanced: "Avancerad",
                        errors: "Fel",
                        warnings: "Varningar"
                    },
                    statistics: {
                        label: "Statistik",
                        group1: "Grupp 1",
                        group2: "Grupp 2",
                        addProperty: "Lägg till egenskap",
                        removeProperty: "Ta bort egenskap",
                        startTutorial: "Börja handledning",
                        exitTutorial: "Avsluta handledning",

                        welcomeStep: {
                            title: "Välkommen till statistiksidan",
                            content: "Här kan du enkelt jämföra olika värden och trender."
                        },
                        propertyStep: {
                            title: "Val av egenskap",
                            content: "För att komma igång, välj helt enkelt en egenskap som intresserar dig från rullgardinsmenyn."
                        },
                        addPropertyStep: {
                            title: "Lägga till och ta bort egenskaper",
                            content: "Prova att lägga till eller ta bort en egenskap. Du kan lägga till upp till 4 egenskaper per grupp."
                        },
                        groupStep: {
                            title: "Aktivera grupp 2",
                            content: "Klicka på knappen 'Lägg till egenskap' för att välja en egenskap för Grupp 2. Egenskaper i denna grupp kommer att visas på höger sida av grafen."
                        },
                        dateRangeStep: {
                            title: "Val av datumintervall",
                            content: "Använd den här knappen för att välja ett datumintervall."
                        },
                        zoomInStep: {
                            title: "Zooma in",
                            content: "Dra musen över grafen för att välja ett område att zooma in på."
                        },
                        zoomOutStep: {
                            title: "Zooma ut",
                            content: "Återställ till det tidigare valda datumintervallet genom att använda knappen 'Zooma ut'."
                        },
                        domainStep: {
                            title: "Justera domäner",
                            content: "Du kan anpassa den maximala och minimala domänen per grupp med hjälp av dessa knappar."
                        },
                        csvStep: {
                            title: "Ladda ner CSV-data",
                            content: "Ladda ner de för närvarande visade grafdata i CSV-format."
                        },
                        imageStep: {
                            title: "Ladda ner bild",
                            content: "Ladda ner den för närvarande visade grafen som en bild."
                        },
                        controlCardTemp: "Temperatur för kontrollkort",
                        lowTensionError: "Fel på låg spänning",
                        overTempFlueGasHeatExch: "Övertemperatur på rökgasväxlaren",
                        feedingCycleLevel: "Matningscykelnivå",
                        batteryVoltage: "Batterispänning",
                        waterPressure: "Vattentryck",
                        shortInterval: "10 Minuters intervaller",
                        dailyInterval: "Dagliga intervaller"
                    },
                    smart: {
                        label: "Smart",
                        heightOffset: "Höjdavvikelse",
                        active: "Aktiv",
                        passive: "Passiv",
                        preActive: "Föraktiv",
                        prePassive: "Förpassiv",
                        postActive: "Efteraktiv",
                        postPassive: "Efterpassiv",
                        activeDesc: "Under en aktiv period maximeras den planerade energiproduktionen. Före varje aktiv period tillhandahålls automatiskt en 'föraktiv' tidsperiod där gasbufferten byggs upp. Den maximala varaktigheten av en aktiv period bestäms av volymen av gasbufferten, antalet och typen av installerade CHP-enheter samt produktionsprofilen för biogasen vid installationen. När den minimala fyllnadsnivån för gasbufferten nås kommer energiproduktionen att stoppas (efter den aktiva perioden).",
                        passiveDesc: "Under en passiv period produceras endast energi om det finns ett överskott av gas. Före varje passiv period tillhandahålls automatiskt en tidsperiod där gasbufferten minskas. Den maximala varaktigheten av en passiv period bestäms av volymen av gasbufferten samt produktionsprofilen för biogasen vid installationen. När den maximala fyllnadsnivån för gasbufferten nås kommer energiproduktionen att starta (efter den passiva perioden).",
                        permanence: "Stimulera konstant produktion genom att dynamiskt köra på en motor med en icke-maximal gasbuffert",
                        permanency: "Beständighet",
                        activateStep: {
                            title: "Aktivera SMART",
                            content: "Aktivera SMART för att styra produktionsprofilen för installationen"
                        },
                        timeslotStep: {
                            title: "Lägg till tidsperiod",
                            content: "Lägg till en aktiv eller passiv tidsperiod"
                        },
                        fromToStep: {
                            title: "Från / Till",
                            content: "Du anger både start- och slutdagen samt tidpunkten"
                        },
                        activePassiveStep: {
                            title: "Aktiv eller Passiv",
                            content: "Välj om du vill ange en aktiv eller passiv tidsperiod"
                        },
                        saveStep: {
                            title: "Spara tidsperioder",
                            content: "Spara dina ändrade inställningar"
                        },
                        removeStep: {
                            title: "Ta bort tidsperioder",
                            content: "Ta bort din tidsperiod här"
                        },
                        visualStep: {
                            title: "Visuell representation",
                            content: "Denna graf jämför SMART-inställningarna med produktionsprofilen från föregående vecka"
                        },
                        daySwitchStep: {
                            title: "Visuell representation",
                            content: "Navigera enkelt till nästa eller föregående dag"
                        },
                    },
                    gas: {
                        label: "Gas",
                        minGasBufferLevel: "Min Nivå gasbuffert",
                        maxGasBufferLevel: "Max Nivå gasbuffert",
                        gasBufferLevel: "Nivå gasbuffert",
                        defaultMinGasBufferLevel: "Förinställd min gasbuffert",
                        defaultMaxGasBufferLevel: "Förinställd max gasbuffert",
                        airInjectionTime: "Luftinpumpning",
                        maxAirInjectionTime: "Max luftinpumpning",
                        torch: "Flare",
                        torchType: "Flare type",
                        torchStatus: "Flare status",
                        torchError: "Flare fel",
                        h2s: "H₂S",
                        h2sLevelReactor: "H₂S rötkammare",
                        h2sLevelMotor: "H₂S Motor",
                        filterLoad: "Mättningsgrad filter",
                        sample: "Provtagning",
                        carbonFilterSize: "Storlek på kolfilter",
                        h2sMeasurements: "H₂S-mätningar",
                        measurements: "Mätningar",
                        changeTo: "Byt till",
                        none: "Ingen",
                        container: "Behållare",
                        extern: "Extern",
                        foamBeaterInstalled: "Skumslagare installerad",
                        antiFoamInjectionsInstalled: "Antiskum-injektioner installerade",

                    },
                    engine: {
                        label: "motor",
                        startEngine: "Start motor",
                        stopEngine: "Stop motor",
                        power: "Effekt",
                        desiredPower: "Ställ in effekt",
                        maxPower: "Max effekt",
                        rpm: "RPM",
                        lambda: "Lambda",
                        throttleValve: "Position servo",
                        engineType: "Motortyp",
                        engineStatus: "Status motor",
                        engineError: "Motorfel",
                        producedEnergy: "Producerad förnybar energi",
                        operatingHours: "Drifttimmar motor",
                        totalOperatingHours: "Total antal drifttimmar",
                        gasUsage: "Gasförbrukning",
                        throttlePosition: "Position throttle",
                        noErrors: "Alla system i drift.",
                        production: "Produktion",
                        servoAuto: "Servot ställs in automatiskt",
                        servoControl: "Servo kontroll",
                        temperature: "Motor temperatur",
                        restart: "Automatisk återstart när gas är tillgängligt.",
                        idle: "Tomgång",
                        running: "Körning",
                        waiting: "Väntar på uppstart",
                        booting: "Startar upp",
                        shuttingDown: "Stänger ner",
                        advanced: "Avancerad",
                        numberOfEngines: "Antal motorer",
                        predeterminedRunHours: "Förutbestämda körtimmar",
                        easyStart1: "Easy start motor 1",
                        easyStart2: "Easy start motor 2",
                        setError1: "Inställt fel motor 1",
                        setError2: "Inställt fel motor 2",
                        fuel: "Bränsle",
                        airIntakeOpen: "Luftintag öppet",
                        airIntakeClosed: "Luftintag stängt",
                    },
                    service: {
                        label: "Underhåll",
                        lastMaintenance: "Driftstimmar till nästa oljebyte",
                        currentlyInstalled: "Driftstimmar för närvarande installerade",
                        total: "Totalt antal driftstimmar",
                        mixer: "Mixer",
                        foamBeater: "Skumslagare",
                        intakePump: "Inloppspump",
                        digestatePump: "Digestatpump",
                        newMixer: "Ny mixer installerad",
                        newEngine: "Ny motor installerad",
                        newFoamBeater: "Ny skumslagare installerad",
                        newIntakePump: "Ny inloppspump installerad",
                        newDigestatePump: "Ny digestatpump installerad",
                        confirmationMessage: "Är du säker på att du vill återställa {{type}}",
                        maintenanceMessage: "Underhållstimmar har återställts för {{type}}",
                        newEquipmentMessage: "Ny utrustning installerad:",
                        prints: "Prints",
                        firstOnline: "Först online",
                        lastOnline: "Senast online",
                        serial: "Serienummer",
                        type: "Typ",
                        engine: "Motor",
                        engine1: "Motor 1",
                        engine2: "Motor 2",
                        h2s1: "H₂S Motor 1",
                        h2s2: "H₂S Motor 2",
                        resetTo: "Återställ till x timmar (standard 0)",
                        timeFilterReplace: "Tid sedan filterbyte",
                        daysSinceOil: "Dagar sedan oljebyte",
                        newGenerator: "Ny generator installerad",
                        generator: "Generator",
                        generator1: "Generator 1",
                        generator2: "Generator 2",
                        daysSinceOilChange: "Dagar sedan oljebyte",
                        videoMessage: "Video om oljebyte => Dokumentsida, under Allmänna videor.",
                        videoRoom: "En servicetekniker har öppnat ett videorum för denna maskin. Klicka här för att gå med.",
                        videoButton: "Starta ett videorum för denna maskin",
                        videoButtonSub: "Användare med åtkomst kan delta i samtalet från sin service-sida.",
                        videoClose: "Stäng videorum"
                    },
                    net: {
                        net: "Net",
                        meterSettings: "Inställningar mätare",
                        voltagePerPhase1: "Spänning per fas (1)",
                        voltagePerPhase2: "Spänning per fas (2)",
                        voltagePerPhase3: "Spänning per fas (3)",
                        frequencyPerPhase: "Frekvens per fas",
                        angleBetweenPhase1And2: "Vinkel mellan fas 1 och 2",
                        ownPowerConsumption: "Egen effektförbrukning",
                        ownEnergyConsumption: "Egen energiförbrukning",
                        bioguardVersion: "Bioguard version",
                        brutoProducedEnergy: "Brutto producerad energi",
                        nettoProducedEnergy: "Netto producerad energi",
                        producedEnergy: "Producerad energi",
                        producedEnergyEngine1: "Producerad energi motor 1",
                        producedEnergyEngine2: "Producerad energi motor 2",
                        monitoringRelay: "Övervakningsrelä",
                    },
                    reactor: {
                        label: "Rötkammare",
                        reactorTemp: "Temperatur rötkammare",
                        reactorTempTarget: "Inställning temperatur rötkammare",
                        reactorLevel: "Inställning nivå",
                        hydrostaticPressure: "Rötkammare nivå",
                        siloType: "Typ rötkammare",
                        extraHeating: "Extra värme",
                        antiFrost: "Anti-frost",
                        antiFrostIndex: "Anti-frost program",
                        antiFrostAuto: "Frostindexet sätts automatiskt baserad lokal temperatur.",
                        antiFrostMessageNew: "Varje frost -index cykel aktivera inpumpning i 4 sekunder och utpumpningen pumpar cirka 100 L av rötrest, baserat på uppskattat pumpflöde.",
                        antiFrostMessageOld: "Varje frost-index cykel kommer aktivera inpumpning i 4 sek och utpumpning i 120 sek.",
                        coolwater: "Kylvatten",
                        statusCoolingValve: "Kylvattenventil",
                        waterTempIn: "Temperatur vatten rötkammare in",
                        waterTempOut: "Temperatur vatten rötkammare retur",
                        waterTempMotorOut: "Temperatur vatten motor 1 ut",
                        waterTempMotorOut2: "Temperatur vatten motor 2 ut",
                        coolingWaterTemp: "Inställningar kylvatten temperatur",
                        maxCoolingWaterTemp: "Max temperatur kylvatten",
                        waterPressure: "Tryck kylvattensystem",
                        foamBeater: "Skumm",
                        foambeatEveryXMinutes: "Bekämpa skumm varje",
                        foambeatForXSeconds: "I",
                        mixer: "Omrörare",
                        mixerMaxPower: "Mixer maximal effekt",
                        mixerActive: "Omrörare aktiva",
                        mixEveryXMinutes: "Omrörning varje",
                        mixForXSeconds: "I",
                        blockMixingFrom: "Ingen omrörning från",
                        blockMixingTo: "Till",
                        mixerInfo: "Mixern slås på automatiskt under vald tid med valt tidsintervall. Under mixerblockets tidsfönster stoppas mixern när den installerade mixereffekten överstiger installationens nuvarande kraftproduktion. Blandaren stoppas tillfälligt under kraftvärmestart tills 50 % av nominell effekt uppnås för att tillåta startström",
                        mix1Hour: "Omrörning 1 timme",
                        coolwaterValveInstalled: "Gradvis trevägsventil",
                        bypassFan: "Kontroll ventil by-pass / fläkt",
                        digester: "Kontroll ventil Rötrest / extern värmekonsumtion ",
                        silo: "Silo",
                        bag: "Påse",
                        plateHeatExchanger: "Plattvärmeväxlare",
                        timesPerDay: "gånger / dag",
                        intern: "Internt",
                        extern: "Externt",
                        tempHigh: "Hög temperatur",
                        externMessage: "Följande fel kommer inte att utlösas om mixern är inställd på externt: Mixer fungerar inte, vänligen återställ termiskt.",
                        bypass: "Bypass",
                        ventilator: "Fläkt",
                    },
                    efficiency: {
                        label: "Verkningsgrad",
                        production: "Energi producerad senaste 30 dagarna",
                        consumption: "Egenförbrukning senaste 30 dagarna",
                        nettoProduction: "Netto produktion senaste 30 dagarna",
                        averageEfficiency: "Genomsnitt effektivitet senaste 30 dagarna",
                        averageTechnicalEfficiency: "Genomsnitt teknisk tillgänglighet senaste 30 dagarna",
                        bgp: "Gödselkvalitet (BGP batch)",
                        top10: "Top 10 anläggningar",
                        realised: "Realiserad effektivitet",
                        technical: "Teknisk tillgänglighet",
                        bgpInfo: "Uppskattad gaspotential BGP (m3/ton). Tar ej med i beräkning förluster vid nedstängning, överproduktion eller fackling.",
                        pointPerDay: "%/dag",
                        trendline: "Trendlinje",
                    },
                    general: {
                        label: "Allmänt",
                        h2sSensorFirmwareVersion: "H₂S-sensor firmware-version",
                        idH2s: "S/N H₂S-ID",
                        bioId: "S/N Biotronic-ID",
                        identifier: "Installationsidentifierare",
                        collabContract: "Samarbetskontrakt",
                        own: "Underhållskontrakt",
                        sold: "Såld installation",
                        productionDate: "Produktionsdatum",
                        commissioningDate: "Drifttagningsdatum",
                        controlCardTemp: "Styrkortstemperatur",
                        batteryVoltage: "Batterispänning",
                        motorVoltage: "Motorspänning",
                        overtempFlueGasHeatExch: "Övertemperatur rökgasvärmeväxlare motor",
                        rebootMainBoard: "Starta om huvudkortet",
                        rebootNow: "Starta om nu",
                        utcOffset: "UTC-offset",
                        location: "Plats",
                        network: "Nätverk",
                        ip: "IP",
                        mask: "Mask",
                        gateway: "Gateway",
                        dns: "DNS",
                        pcb: "PCB",
                        firmware: "Firmware",
                        mainControlUnit: "Huvudkontrollenhet",
                        bioguard: "Bioguard",
                        biotronicEngine1: "Biotronic motor 1",
                        biotronicEngine2: "Biotronic motor 2",
                        h2sEngine1: "H₂S motor 1",
                        h2sEngine2: "H₂S motor 2",
                        bioIdEngine1: "Bio ID motor 1",
                        bioIdEngine2: "Bio ID motor 2",
                        modificationsToMachine: "Ändringar på maskinen",
                        name: "Namn",
                        normal: "Normal",
                        lowError: "Låg (fel)",
                    },
                    feeding: {
                        label: "Matning",
                        feedingVolume: "Volym inpumpning",
                        feedingCyclesPerDay: "Matningscykler per dag",
                        feedingBalance: "Matning saldo",
                        autoFeedingActive: "Automatisk matning",
                        autoFeedingStartHour: "Automatisk matning",
                        exitPumpRunning: "Starta manuell utpumpning",
                        feedingCycle: "Matningscykel",
                        onlyInput: "Bara inpumpning",
                        onlyOutput: "Bara utpumpning",
                        startFeeding: "Start av inpumpning",
                        stopFeeding: "Stäng av inpumpning",
                        pumpOutFlowRate: "Flöde utpumpning",
                        kiloPerPulse: "Puls flödesmätare",
                        intakeTimeout: "Matning: Time-out inpumpning",
                        averageDailyFeeding: "Genomsnittlig daglig utfodring förra månaden",
                        retentionTime: "Bibehållande",
                        statusValvePumpIn: "Ventil substrat in -2",
                        statusValvePumpOut: "Ventil rötkammare ut -3",
                        statusValveRetourReactor: "Ventil retur rötkammare -4",
                        valveCurrentActuator: "Spänning ventilstyrning",
                        lastFeeding: "Nuvarande - senaste",
                        startHourLastFeeding: "Start senaste matning",
                        feedingStatus: "Status matning",
                        feedingError: "Fel rötkammare",
                        intakePumpTime: "Tid inpumpning",
                        intakePumpAmount: "Total volym inpumpning",
                        exitPumpTime: "Tid utpumpning",
                        exitPumpAmount: "Volym utpumpning",
                        feedingHistory: "Matningshistorik",
                        startedOn: "Tidpunkt uppstart",
                        temperature: "Temperatur",
                        reactorLevel: "Nivå",
                        balance: "Saldo",
                        acknowledge: "Kvitterat larm",
                        intakeSpeed: "Pumphastighet",
                        balanceWarning: "Varning, detta kommer inte att starta en normal matningscykel utan istället pumpa in återstående balansvärde i reaktorn",
                        automaticFeedingSettings: "Automatiska matningsinställningar",
                        manualFeeding: "Manuell matning",
                        pumpSettings: "Pumpinställningar",
                        status: {
                            "0": "Tomgång",
                            "1": "Nivådetektering",
                            "2": "Utgång",
                            "3": "Intag",
                            "5": "Avfrostning",
                            "6": "Gasfrisättning",
                            "7": "Manuell utgång",
                            "10": "Startar upp",
                            "2.001": "Utgång: Förblandning",
                            "2.002": "Utgång",
                            "2.003": "Utgång: Öppna utgångsventil",
                            "2.004": "Utgång: Öppna utgångsventil",
                            "2.005": "Utgång: Startar utgångspump",
                            "2.006": "Utgång",
                            "2.007": "Utgång: Pumpar gödsel",
                            "2.008": "Utgång: Stoppa utgång",
                            "2.009": "Utgång: Stoppa utgång",
                            "2.010": "Utgång: Stäng utgångsventil",
                            "2.011": "Utgång: Stäng utgångsventil",
                            "2.012": "Utgång: Stoppa utgång",
                            "3.001": "Intag: Förbereder",
                            "3.002": "Intag: Förbereder",
                            "3.003": "Intag: Förbereder",
                            "3.004": "Intag: Pumpar gödsel",
                            "3.005": "Intag: Pumpar gödsel",
                            "3.006": "Intag: Avslutar",
                            "3.007": "Intag: Avslutar",
                            "3.008": "Intag: Stoppa pump",
                            "3.009": "Intag: Stoppa pump",
                            "3.010": "Intag: Stoppa pump",
                            "3.011": "Intag: Stoppa pump",
                            "3.012": "Intag: Avslutar",
                            "5.001": "Avfrostning: Testar intag",
                            "5.002": "Avfrostning: Testar intag",
                            "5.003": "Avfrostning: Testar intag",
                            "5.004": "Avfrostning: Testar intag",
                            "5.005": "Avfrostning: Stoppa intag",
                            "5.006": "Avfrostning: Öppna utgångsventil",
                            "5.007": "Avfrostning: Öppna utgångsventil",
                            "5.008": "Avfrostning: Testar utgång",
                            "5.009": "Avfrostning: Testar utgång",
                            "5.010": "Avfrostning: Testar utgång",
                            "5.011": "Avfrostning: Testar utgång",
                            "5.012": "Avfrostning: Testar utgång",
                            "5.013": "Avfrostning: Stänger utloppsventil",
                            "5.014": "Avfrostning: Stänger utloppsventil",
                            "5.015": "Avfrostning: Avslutar",
                            "6.001": "Gasutsläpp: Öppnar utloppsventil",
                            "6.002": "Gasutsläpp: Öppnar utloppsventil",
                            "6.003": "Gasutsläpp: Stänger utloppsventil",
                            "6.004": "Gasutsläpp: Stänger utloppsventil",
                            "6.005": "Gasutsläpp",
                            "7.001": "Manuell utlopp: Öppnar utloppsventil",
                            "7.002": "Manuell utlopp: Öppnar utloppsventil",
                            "7.003": "Manuell utlopp: Pumpar gödsel",
                            "7.004": "Manuell utlopp: Stoppa pumpen",
                            "7.005": "Manuell utlopp: Stänger utloppsventil",
                            "7.006": "Manuell utlopp: Stänger utloppsventil",
                            "7.007": "Manuell utlopp: Avslutar",
                            "7.008": "Manuell utlopp: Avslutar",
                            "10.001": "Startar: Stänger ventil 2",
                            "10.002": "Startar: Stänger ventil 2",
                            "10.003": "Startar: Stänger utloppsventil",
                            "10.004": "Startar: Stänger utloppsventil",
                            "10.005": "Startar: Stänger ventil 4",
                            "10.006": "Startar: Stänger ventil 4",
                            "10.007": "Startar: Kontrollerar elektrisk anslutning",
                            "10.008": "Startar: Kontrollerar elektrisk anslutning",
                            "10.009": "Startar"
                        }
                    },
                    users: {
                        label: "Användare",
                        customers: "Kopplad kund ",
                        technicians: "Kopplad tekniker",
                        resellers: "Kopplad Säljare",
                    },
                    recommendations: {
                        label: "Rekommendationer",
                    },
                    documents: {
                        label: "Dokument"
                    },
                    warnings: {
                        "W001": "Energiförbrukning som överskrider tröskeln de senaste 24 timmarna (72 kWh för 10-44kW installation, 250 kWh för 60-74kW installation)",
                        "W002": "Temperatur rötkammaren har sjunkit",
                        "W003": "Service inom 48 timmar",
                        "W004": "Bytt karbonfilter motor 1",
                        "W005": "Bytt karbonfilter motor 2",
                        "W006": "Gasbuffertmätning har frusit",
                        "W007": "Gasbuffertmätning trasig",
                        "W008": "Ingen matning igår",
                        "W009": "Flöde inpumpning 50% snabbare än genomsnittet från senaste månad",
                        "W010": "H₂S koncentration för hög",
                        "W011": "Luftinpumpning är 60 sekunder",
                        "W012": "Luftinpumpning är 3 sekunder eller lägre",
                        "W013": "Effektivitet igår var 0 men automatisk matning är aktiverad",
                        "W014": "Avvikelse H₂S mätningar i rötkammaren är större än 400ppm",
                        "W015": "Luftinpumpning väldigt hög, tänk på gaskvalitén",
                        "W016": "H₂S mätningar har inte fungerat de senaste 24 timmarna",
                        "W017": "Oljenivå omröraren för låg",
                        "W018": "Nivå motorolja för låg",
                        "W020": "Tryck kylvattensystem för låg",
                        "W104": "H₂S för hög (>200) eller luftinpumpning är 60 eller 3",
                        "W105": "Karbonfilter mättad",
                        "W114": "Effektivitet var inte 0 igår men automatisk matning är avstängd",
                    }
                }
            },
            pl: {
                translation: {
                    dashboard: {
                        nameIdentifier: "Nazwa lub identyfikator",
                        efficiencyAll: "Wydajność wszystkich maszyn",
                        onlyErrors: "Tylko błędy",
                        toggleDisconnected: "Przełącz odłączone maszyny",
                        productionCombined: "Łączna produkcja (Wh)",
                        realisedTechnicalEfficiency: "Zrealizowana wydajność / Wydajność techniczna (%)",
                        efficiencyCombined: "Zrealizowana wydajność (%)",
                        technicalEfficiencyCombined: "Wydajność techniczna (%)",
                        bgp: "Bgp (m³/ton)",
                        consumption: "Zużycie (Wh)",
                        manureLifeTime: "Czas życia obornika (dni)",
                        feeding: "Karmienie (kg)"
                    },
                    common: {
                        on: "Włączone",
                        off: "Wyłączone",
                        add: "Dodaj",
                        save: "Zapisz",
                        monday: "Poniedziałek",
                        tuesday: "Wtorek",
                        wednesday: "Środa",
                        thursday: "Czwartek",
                        friday: "Piątek",
                        saturday: "Sobota",
                        sunday: "Niedziela",
                        from: "Od",
                        to: "Do",
                        hours: "godziny",
                        reset: "Resetuj",
                        confirmation: "Potwierdzenie",
                        cancel: "Anuluj",
                        set: "Dostosuj",
                        auto: "Automatycznie",
                        manual: "Ręcznie",
                        minutes: "Minuty",
                        seconds: "Sekundy",
                        turnOn: "Włączyć coś",
                        turnOff: "Wyłączyć coś",
                        graphLoading: "Ładowanie wykresów...",
                        noData: "Brak dostępnych danych...",
                        hoursPerYear: "godzin / rok",
                        datetime: "Data i czas",
                        warning: "Ostrzeżenie",
                        error: "Błąd",
                        type: "Typ",
                        startdate: "Data rozpoczęcia",
                        enddate: "Data zakończenia",
                        all: "Wszystkie",
                        engine1: "Silnik 1",
                        engine2: "Silnik 2",
                        ongoing: "Trwający",
                        both: "Oba",
                        days: "Dni",
                        today: "Dzisiaj",
                        sendAnswers: "Wyślij odpowiedzi",
                        employee: "Pracownik",
                        customer: "Klient",
                        sales: "Sprzedaż",
                        technician: "Technik",
                        admin: "Administrator",
                        noDateKnown: "Brak daty",
                        command: "Polecenie",
                        user: "Użytkownik",
                        fillInSurvey: "Proszę wypełnić ankietę",
                        tons: "tona",
                        count: "Liczba",
                        disabled: "Wyłączony",
                        addField: "Dodaj pole",
                        deleteField: "Usuń pole",
                        releases: "Wydania",
                        open: "Otwórz",
                        close: "Zamknij",
                    },
                    menu: {
                        dashboard: 'Panel',
                        machines: 'maszyneria',
                        questionnaires: 'Kwestionariusze',
                        banners: "Banery",
                        errors: "Błędy i ostrzeżenia",
                        pcb: "Płytki drukowane",
                        tasks: "Moje zadania",
                        releases: "Informacje o wydaniu",
                        resellers: "Sprzedawcy",
                        users: "Użytkownicy",
                        recommendations: "Zalecenia",
                        suggestions: "Wiadomości zaleceń",
                        language: "Język",
                        reconnection: "Ta maszyna jest obecnie offline. Spróbuj nawiązać nowe połączenie za pomocą przycisku powyżej.",
                        profile: "Profil",
                        logout: "Wyloguj się",
                        firmware: "Oprogramowanie",
                        sms: "SMS",
                        advanced: "Zaawansowane",
                        errors: "Błędy",
                        warnings: "Ostrzeżenia"
                    },
                    statistics: {
                        label: "Statystyki",
                        group1: "Grupa 1",
                        group2: "Grupa 2",
                        addProperty: "Dodaj właściwość",
                        removeProperty: "Usuń właściwość",
                        startTutorial: "Rozpocznij samouczek",
                        exitTutorial: "Wyjdź ze samouczka",

                        welcomeStep: {
                            title: "Witaj na stronie statystyk",
                            content: "Tutaj możesz łatwo porównywać różne wartości i trendy."
                        },
                        propertyStep: {
                            title: "Wybór właściwości",
                            content: "Aby rozpocząć, po prostu wybierz właściwość, która cię interesuje z listy rozwijanej."
                        },
                        addPropertyStep: {
                            title: "Dodawanie i usuwanie właściwości",
                            content: "Spróbuj dodać lub usunąć właściwość. Możesz dodać maksymalnie 4 właściwości na grupę."
                        },
                        groupStep: {
                            title: "Włączenie grupy 2",
                            content: "Kliknij przycisk 'Dodaj właściwość', aby wybrać właściwość do Grupy 2. Właściwości w tej grupie będą wyświetlane po prawej stronie wykresu."
                        },
                        dateRangeStep: {
                            title: "Wybór zakresu dat",
                            content: "Użyj tego przycisku, aby wybrać zakres dat."
                        },
                        zoomInStep: {
                            title: "Powiększanie",
                            content: "Przeciągnij myszką po wykresie, aby wybrać obszar do powiększenia."
                        },
                        zoomOutStep: {
                            title: "Pomniejszanie",
                            content: "Zresetuj poprzednio wybrany zakres dat, używając przycisku 'Pomniejszanie'."
                        },
                        domainStep: {
                            title: "Dostosowanie dziedzin",
                            content: "Możesz dostosować maksymalną i minimalną dziedzinę na grupę, używając tych przycisków."
                        },
                        csvStep: {
                            title: "Pobierz dane CSV",
                            content: "Pobierz aktualnie wyświetlane dane wykresu w formacie CSV."
                        },
                        imageStep: {
                            title: "Pobierz obraz",
                            content: "Pobierz aktualnie wyświetlany wykres jako obraz."
                        },
                        controlCardTemp: "Temperatura karty sterowania",
                        lowTensionError: "Błąd niskiego napięcia",
                        overTempFlueGasHeatExch: "Przekroczenie temperatury wymiennika ciepła gazów spalinowych",
                        feedingCycleLevel: "Poziom cyklu karmienia",
                        batteryVoltage: "Napięcie baterii",
                        waterPressure: "Ciśnienie wody",
                        shortInterval: "Interwały 10 minut",
                        dailyInterval: "Codzienne interwały"
                    },
                    smart: {
                        label: "Smart",
                        heightOffset: "Wysokość przesunięcia",
                        active: "Aktywny",
                        passive: "Pasywny",
                        preActive: "Przedaktywny",
                        prePassive: "Przepasywny",
                        postActive: "Poaktywny",
                        postPassive: "Popasywny",
                        activeDesc: "Podczas aktywnego okresu planowana produkcja energii jest maksymalizowana. Przed każdym aktywnym okresem automatycznie przewidziany jest okres 'przedaktywny', podczas którego bufor gazu jest budowany. Maksymalny czas trwania aktywnego okresu jest określany przez objętość bufora gazu, liczbę i rodzaj zainstalowanych jednostek kogeneracji oraz profil produkcji biogazu w instalacji. Gdy osiągnięty zostanie minimalny poziom napełnienia bufora gazu, produkcja energii zostanie zatrzymana (okres poaktywny).",
                        passiveDesc: "Podczas pasywnego okresu energia jest produkowana tylko w przypadku nadmiaru gazu. Przed każdym pasywnym okresem automatycznie przewidziany jest okres, podczas którego bufor gazu jest redukowany. Maksymalny czas trwania pasywnego okresu jest określany przez objętość bufora gazu oraz profil produkcji biogazu w instalacji. Gdy osiągnięty zostanie maksymalny poziom napełnienia bufora gazu, rozpocznie się produkcja energii (okres popasywny).",
                        permanence: "Stymulowanie stałej produkcji przez dynamiczne działanie na jednym silniku przy nie maksymalnym buforze gazu",
                        permanency: "Trwałość",
                        activateStep: {
                            title: "Aktywacja SMART",
                            content: "Aktywuj SMART, aby kontrolować profil produkcji instalacji"
                        },
                        timeslotStep: {
                            title: "Dodaj przedział czasowy",
                            content: "Dodaj aktywny lub pasywny przedział czasowy"
                        },
                        fromToStep: {
                            title: "Od / Do",
                            content: "Ustaw zarówno dzień rozpoczęcia, zakończenia oraz godzinę"
                        },
                        activePassiveStep: {
                            title: "Aktywny lub Pasywny",
                            content: "Wybierz, czy chcesz ustawić aktywny czy pasywny przedział czasowy"
                        },
                        saveStep: {
                            title: "Zapisz przedziały czasowe",
                            content: "Zapisz wprowadzone zmiany"
                        },
                        removeStep: {
                            title: "Usuń przedziały czasowe",
                            content: "Usuń swój przedział czasowy tutaj"
                        },
                        visualStep: {
                            title: "Reprezentacja wizualna",
                            content: "Ten wykres porównuje ustawienia SMART z profilem produkcji z ostatniego tygodnia"
                        },
                        daySwitchStep: {
                            title: "Reprezentacja wizualna",
                            content: "Łatwo nawiguj do następnego lub poprzedniego dnia"
                        },
                    },
                    gas: {
                        label: "Gaz",
                        minGasBufferLevel: "Min. poziom gazu",
                        maxGasBufferLevel: "Maks. poziom gazu",
                        gasBufferLevel: "Aktualny poziom gazu",
                        defaultMinGasBufferLevel: "Domyślny min poziom gazu",
                        defaultMaxGasBufferLevel: "Domyślny max poziom gazu",
                        airInjectionTime: "Poziom napowietrzenia",
                        maxAirInjectionTime: "Maks. napowietrzanie",
                        torch: "Flare",
                        torchType: "Flare type",
                        torchStatus: "Flare status",
                        torchError: "Flare silnika",
                        h2s: "H₂S",
                        h2sLevelReactor: "H₂S w reaktorze",
                        h2sLevelMotor: "H₂S Silnik",
                        filterLoad: "Poziom wykorzystania filtra",
                        sample: "Próbka",
                        carbonFilterSize: "Rozmiar filtra węglowego",
                        h2sMeasurements: "Pomiary H₂S",
                        measurements: "Pomiary",
                        changeTo: "Zmień na",
                        none: "Brak",
                        container: "Pojemnik",
                        extern: "Zewnętrzny",
                        foamBeaterInstalled: "Zainstalowano bijak do piany",
                        antiFoamInjectionsInstalled: "Zainstalowano wtryski przeciwpieniące",

                    },
                    engine: {
                        label: "silnik",
                        startEngine: "Włącz silnik",
                        stopEngine: "Wyłącz silnik",
                        power: "Moc",
                        desiredPower: "Ustawiona moc",
                        maxPower: "Maks. moc",
                        rpm: "RPM",
                        lambda: "Lambda",
                        throttleValve: "Pozycja serwomechanizmu	",
                        engineType: "Typ silnika",
                        engineStatus: "Status silnika",
                        engineError: "Błąd silnika",
                        producedEnergy: "Produkcja zielonej energii",
                        operatingHours: "Liczba godzin silnika",
                        totalOperatingHours: "Całkowity czas pracy silnika",
                        gasUsage: "Zużycie gazu",
                        throttlePosition: "Pozycja throttle",
                        noErrors: "Wszystkie systemy działają poprawnie.",
                        production: "Produkcja",
                        servoAuto: "Serwo jest ustawiane automatycznie",
                        servoControl: "Kontrola serwomechanizmu",
                        temperature: "Temperatura silnika",
                        restart: "Automatycznie ponowneuruchomienie, gdy ilość gazu będzie wystarczająca",
                        idle: "Bezczynność",
                        running: "Uruchomiony",
                        waiting: "Oczekiwanie na uruchomienie",
                        booting: "Uruchamianie",
                        shuttingDown: "Wyłączanie",
                        advanced: "Zaawansowane",
                        numberOfEngines: "Liczba silników",
                        predeterminedRunHours: "Określone godziny pracy",
                        easyStart1: "Easy start silnika 1",
                        easyStart2: "Easy start silnika 2",
                        setError1: "Ustaw błąd silnika 1",
                        setError2: "Ustaw błąd silnika 2",
                        fuel: "Paliwo",
                        airIntakeOpen: "Wlot powietrza otwarty",
                        airIntakeClosed: "Wlot powietrza zamknięty",
                    },
                    service: {
                        label: "Konserwacja",
                        lastMaintenance: "Godziny pracy do następnej wymiany oleju",
                        currentlyInstalled: "Aktualnie zainstalowane godziny pracy",
                        total: "Łączna liczba godzin pracy",
                        mixer: "Mikser",
                        foamBeater: "Ubijak piany",
                        intakePump: "Pompa ssąca",
                        digestatePump: "Pompa odwadniania",
                        newMixer: "Nowy mikser zainstalowany",
                        newEngine: "Nowy silnik zainstalowany",
                        newFoamBeater: "Nowy ubijak piany zainstalowany",
                        newIntakePump: "Nowa pompa ssąca zainstalowana",
                        newDigestatePump: "Nowa pompa odwadniania zainstalowana",
                        confirmationMessage: "Czy na pewno chcesz zresetować {{type}}",
                        maintenanceMessage: "Godziny konserwacji zostały zresetowane dla {{type}}",
                        newEquipmentMessage: "Nowy sprzęt zainstalowany:",
                        prints: "Prints",
                        firstOnline: "Pierwsze online",
                        lastOnline: "Ostatnie online",
                        serial: "Numer seryjny",
                        type: "Typ",
                        engine: "Silnik",
                        engine1: "Silnik 1",
                        engine2: "Silnik 2",
                        h2s1: "Silnik H₂S 1",
                        h2s2: "Silnik H₂S 2",
                        resetTo: "Resetuj do x godzin (domyślnie 0)",
                        timeFilterReplace: "Czas od wymiany filtra",
                        daysSinceOil: "Dni od wymiany oleju",
                        newGenerator: "Przekaźnik monitorujący",
                        generator: "Generator",
                        generator1: "Generator 1",
                        generator2: "Generator 2",
                        daysSinceOilChange: "Dni od wymiany oleju",
                        videoMessage: "Film o wymianie oleju => Strona Dokumenty, w sekcji Filmy ogólne.",
                        videoRoom: "Technik serwisowy otworzył pokój wideo dla tej maszyny. Kliknij tutaj, aby dołączyć.",
                        videoButton: "Rozpocznij pokój wideo dla tej maszyny",
                        videoButtonSub: "Użytkownicy z dostępem mogą dołączyć do połączenia z ich strony serwisowej.",
                        videoClose: "Zamknij pokój wideo"
                    },
                    net: {
                        net: "Sieć elektryczna",
                        meterSettings: "Ustawienia miernika",
                        voltagePerPhase1: "Napięcie na fazę (1)",
                        voltagePerPhase2: "Napięcie na fazę (2)",
                        voltagePerPhase3: "Napięcie na fazę (3)",
                        frequencyPerPhase: "Częstotliwość na fazę",
                        angleBetweenPhase1And2: "Kąt pomiędzy fazą 1 i 2",
                        ownPowerConsumption: "Bieżące zużycie energii",
                        ownEnergyConsumption: "Zużycie energii na potrzeby własne",
                        bioguardVersion: "Wersja bioguard",
                        brutoProducedEnergy: "Wyprodukowana energia brutto",
                        nettoProducedEnergy: "Wyprodukowana energia netto",
                        producedEnergy: "Wyprodukowana energia",
                        producedEnergyEngine1: "Wyprodukowana energia silnik 1",
                        producedEnergyEngine2: "Wyprodukowana energia silnik 2",
                        monitoringRelay: "Przekaźnik monitorujący",
                    },
                    reactor: {
                        label: "Reaktor",
                        reactorTemp: "Temperatura reaktora",
                        reactorTempTarget: "Docelowa temperatura reaktora",
                        reactorLevel: "Ustawiony poziom",
                        hydrostaticPressure: "Aktualny poziom reaktora",
                        siloType: "Typ reaktora",
                        extraHeating: "Dodatkowe ogrzewanie",
                        antiFrost: "Mrozu",
                        antiFrostIndex: "Indeks mrozu",
                        antiFrostAuto: "Wskaźnik zamarzania jest ustawiany automatycznie na podstawie temperatury lokalnej",
                        antiFrostMessageNew: "Każdy cykl zabezpieczenia przed zamarzaniem uruchomi pompę zasilania gnojowicą na 4 s, a pompa do pofermentu wypompuje ok. 100 l, w zależności od ustawionego parametru.",
                        antiFrostMessageOld: "Każdy cykl wskaźnika mrozu aktywuje pompę karmiącą na 4 sekundy, a pompę do pofermentu na 120 sekund.",
                        coolwater: "Woda chłodząca",
                        statusCoolingValve: "Zawór obiegu chłodzącego",
                        waterTempIn: "Temperatura wody wchodzącej do reaktora",
                        waterTempOut: "Temperatura wody wychodzącej z reaktora",
                        waterTempMotorOut: "Temperatura wody wychodzącej z silnika 1",
                        waterTempMotorOut2: "Temperatura wody wychodzącej z silnika 2",
                        coolingWaterTemp: "Ustawienia temperatury obiegu chłodzącego",
                        maxCoolingWaterTemp: "Limit temperatury obiegu chłodzącego",
                        waterPressure: "Ciśnienie wody w obiegu chłodzenia",
                        foamBeater: "Antyspieniacza",
                        foambeatEveryXMinutes: "Rozbijaj pianę co",
                        foambeatForXSeconds: "Przez",
                        mixer: "Mieszadło",
                        mixerMaxPower: "Maksymalna moc miksera",
                        mixerActive: "Mieszadło aktywny",
                        mixEveryXMinutes: "Mieszaj co",
                        mixForXSeconds: "Przez",
                        blockMixingFrom: "Brak mieszania od",
                        blockMixingTo: "Do",
                        mixerInfo: "Mikser włącza się automatycznie na wybrany czas z wybranym przedziałem czasowym. W oknie czasowym blokady mieszacza mieszacz jest zatrzymywany, gdy moc zainstalowana mieszacza przekroczy aktualną moc wytwarzaną przez instalację. Mikser jest tymczasowo zatrzymywany podczas rozruchu CHP do momentu osiągnięcia 50% mocy znamionowej, aby umożliwić prąd rozruchowy",
                        mix1Hour: "Mieszaj 1 godz",
                        coolwaterValveInstalled: "Regulowany zawór trójdrożny",
                        bypassFan: "Obejście zaworu sterującego / wentylator",
                        digester: "Zawór sterujący komory fermentacyjnej / zewnętrzne zużycie ciepła ",
                        silo: "Silo",
                        bag: "Worek",
                        plateHeatExchanger: "Płytowy wymiennik ciepła",
                        timesPerDay: "razy / dzień",
                        intern: "Wewnętrznie",
                        extern: "Zewnętrznie",
                        tempHigh: "Wysoka temperatura",
                        externMessage: "Następujący błąd nie zostanie wywołany, jeśli mikser jest ustawiony na zewnętrzny: Mikser nie działa, proszę zresetować termicznie.",
                        bypass: "Obejście",
                        ventilator: "Wentylator",
                    },
                    efficiency: {
                        label: "Sprawność",
                        production: "Produkcja energii w ostatnich 30 dniach",
                        consumption: "Zużycie energii w ostatnich 30 dniach",
                        nettoProduction: "Produkcja energii netto w ostatnich 30 dniach",
                        averageEfficiency: "Średnia sprawność w ostatnich 30 dniach",
                        averageTechnicalEfficiency: "Średnia dostępność techniczna w ostatnich 30 dniach",
                        bgp: "Jakość obornika (BGP batch)",
                        top10: "Top 10 najlepszych biogazowni",
                        realised: "Zrealizowana wydajność",
                        technical: "Dostępność techniczna",
                        bgpInfo: "Szacowane BGP (Ocena Potencjału Biogazu) [m³/tonę]. Nie uwzględnia strat spowodowanych przestojami, nadprodukcją lub zużyciem biogazu przez flarę.",
                        pointPerDay: "%/dzień",
                        trendline: "Linia trendu",
                    },
                    general: {
                        label: "Ogólne",
                        h2sSensorFirmwareVersion: "Wersja oprogramowania czujnika H₂S",
                        idH2s: "S/N ID H₂S",
                        bioId: "S/N ID Biotronic",
                        identifier: "Identyfikator instalacji",
                        collabContract: "Umowa o współpracy",
                        own: "Umowa serwisowa",
                        sold: "Sprzedana instalacja",
                        productionDate: "Data produkcji",
                        commissioningDate: "Data uruchomienia",
                        controlCardTemp: "Temperatura karty sterującej",
                        batteryVoltage: "Napięcie baterii",
                        motorVoltage: "Napięcie silnika",
                        overtempFlueGasHeatExch: "Przegrzanie silnika wymiennika ciepła spalin",
                        rebootMainBoard: "Restartuj płytę główną",
                        rebootNow: "Zrestartuj teraz",
                        utcOffset: "Offset UTC",
                        location: "Lokalizacja",
                        network: "Sieć",
                        ip: "IP",
                        mask: "Maska",
                        gateway: "Brama",
                        dns: "DNS",
                        pcb: "PCB",
                        firmware: "Oprogramowanie",
                        mainControlUnit: "Główna jednostka sterująca",
                        bioguard: "Bioguard",
                        biotronicEngine1: "Silnik Biotronic 1",
                        biotronicEngine2: "Silnik Biotronic 2",
                        h2sEngine1: "Silnik H₂S 1",
                        h2sEngine2: "Silnik H₂S 2",
                        bioIdEngine1: "Silnik Bio ID 1",
                        bioIdEngine2: "Silnik Bio ID 2",
                        modificationsToMachine: "Modyfikacje maszyny",
                        name: "Nazwa",
                        normal: "Normalny",
                        lowError: "Niski (błąd)",
                    },
                    feeding: {
                        label: "Karmienie",
                        feedingVolume: "Wpompowywana ilość na cykl",
                        feedingCyclesPerDay: "Liczba cykli na dzień",
                        feedingBalance: "Bilans karmienia",
                        autoFeedingActive: "Automatyczne karmienie",
                        autoFeedingStartHour: "Automatyczne karmienie",
                        exitPumpRunning: "Rozpocznij ręczne wypompowywanie",
                        feedingCycle: "Cykl karmienia",
                        onlyInput: "Wyłącznie wpompowywanie",
                        onlyOutput: "Wyłącznie wypompowywanie",
                        startFeeding: "Startkarmienie",
                        stopFeeding: "Wyłącz karmienie",
                        pumpOutFlowRate: "Ustawienie prędkości wypompowywania",
                        kiloPerPulse: "Impuls przepływomierza",
                        intakeTimeout: "Karmienie: Limit czasu pompowania",
                        averageDailyFeeding: "Średnie dzienne karmienie w ostatnim miesiącu",
                        retentionTime: "Zatrzymywanie",
                        statusValvePumpIn: "Zawór wejściowy do - 2",
                        statusValvePumpOut: "Zawór wyjściowy reaktora - 3",
                        statusValveRetourReactor: "Zawór zwrotny reaktora - 4",
                        valveCurrentActuator: "Zawór aktualnie",
                        lastFeeding: "Aktualne - Ostatnie",
                        startHourLastFeeding: "Czas rozpoczęcia ostatniego cyklu karmienia",
                        feedingStatus: "Status karmienia",
                        feedingError: "Błąd karmienia",
                        intakePumpTime: "Czas pompowania",
                        intakePumpAmount: "Wpompowywana ilość",
                        exitPumpTime: "Czas wypompowywania",
                        exitPumpAmount: "Ilość wypompowana",
                        feedingHistory: "Historia karmienia",
                        startedOn: "Rozpoczęto",
                        temperature: "Temperatura",
                        reactorLevel: "Poziom",
                        balance: "Bilans",
                        acknowledge: "Potwierdzenie błędu",
                        intakeSpeed: "Prędkość pompowania",
                        balanceWarning: "Ostrzeżenie, to nie rozpocznie normalnego cyklu karmienia, ale zamiast tego wpompuje pozostałą wartość salda do reaktora",
                        automaticFeedingSettings: "Ustawienia automatycznego karmienia",
                        manualFeeding: "Ręczne karmienie",
                        pumpSettings: "Ustawienia pompy",
                        status: {
                            "0": "Bezczynność",
                            "1": "Wykrywanie poziomu",
                            "2": "Wyjście",
                            "3": "Wlot",
                            "5": "Odszranianie",
                            "6": "Uwolnienie gazu",
                            "7": "Wyjście manualne",
                            "10": "Uruchamianie",
                            "2.001": "Wyjście: mieszanie",
                            "2.002": "Wyjście",
                            "2.003": "Wyjście: otwarcie zaworu",
                            "2.004": "Wyjście: otwarcie zaworu",
                            "2.005": "Wyjście: włączenie pompy",
                            "2.006": "Wyjście",
                            "2.007": "Wyjście: opróżnianie zbiornika",
                            "2.008": "Wyjście: zatrzymanie pompy",
                            "2.009": "Wyjście: zatrzymanie pompy",
                            "2.010": "Wyjście: zamknięcie zaworu",
                            "2.011": "Wyjście: zamknięcie zaworu",
                            "2.012": "Wyjście: zatrzymanie pompy",
                            "3.001": "Wlot: przygotowanie",
                            "3.002": "Wlot: przygotowanie",
                            "3.003": "Wlot: przygotowanie",
                            "3.004": "Wlot: włączenie pompy",
                            "3.005": "Wlot: włączenie pompy",
                            "3.006": "Wlot: zakończenie",
                            "3.007": "Wlot: zakończenie",
                            "3.008": "Wlot: zatrzymanie pompy",
                            "3.009": "Wlot: zatrzymanie pompy",
                            "3.010": "Wlot: zatrzymanie pompy",
                            "3.011": "Wlot: zatrzymanie pompy",
                            "3.012": "Wlot: zakończenie",
                            "5.001": "Odszranianie: testowanie wlotu",
                            "5.002": "Odszranianie: testowanie wlotu",
                            "5.003": "Odszranianie: testowanie wlotu",
                            "5.004": "Odszranianie: testowanie wlotu",
                            "5.005": "Odszranianie: zatrzymanie wlotu",
                            "5.006": "Odszranianie: otwarcie zaworu wyjścia",
                            "5.007": "Odszranianie: otwarcie zaworu wyjścia",
                            "5.008": "Odszranianie: testowanie wyjścia",
                            "5.009": "Odszranianie: testowanie wyjścia",
                            "5.010": "Odszranianie: testowanie wyjścia",
                            "5.011": "Odszranianie: Testowanie poboru",
                            "5.012": "Odszranianie: Testowanie poboru",
                            "5.013": "Odszranianie: Zamykanie zaworu wyjściowego",
                            "5.014": "Odszranianie: Zamykanie zaworu wyjściowego",
                            "5.015": "Odszranianie: Kończenie",
                            "6.001": "Uwolnienie gazu: Otwieranie zaworu wyjściowego",
                            "6.002": "Uwolnienie gazu: Otwieranie zaworu wyjściowego",
                            "6.003": "Uwolnienie gazu: Zamykanie zaworu wyjściowego",
                            "6.004": "Uwolnienie gazu: Zamykanie zaworu wyjściowego",
                            "6.005": "Uwolnienie gazu",
                            "7.001": "Ręczne opróżnianie: Otwieranie zaworu wyjściowego",
                            "7.002": "Ręczne opróżnianie: Otwieranie zaworu wyjściowego",
                            "7.003": "Ręczne opróżnianie: Pompowanie gnojowicy",
                            "7.004": "Ręczne opróżnianie: Zatrzymanie pompy",
                            "7.005": "Ręczne opróżnianie: Zamykanie zaworu wyjściowego",
                            "7.006": "Ręczne opróżnianie: Zamykanie zaworu wyjściowego",
                            "7.007": "Ręczne opróżnianie: Kończenie",
                            "7.008": "Ręczne opróżnianie: Kończenie",
                            "10.001": "Uruchamianie: Zamykanie zaworu 2",
                            "10.002": "Uruchamianie: Zamykanie zaworu 2",
                            "10.003": "Uruchamianie: Zamykanie zaworu wyjściowego",
                            "10.004": "Uruchamianie: Zamykanie zaworu wyjściowego",
                            "10.005": "Uruchamianie: Zamykanie zaworu 4",
                            "10.006": "Uruchamianie: Zamykanie zaworu 4",
                            "10.007": "Uruchamianie: Sprawdzanie połączenia elektrycznego",
                            "10.008": "Uruchamianie: Sprawdzanie połączenia elektrycznego",
                            "10.009": "Uruchamianie"
                        }
                    },
                    users: {
                        label: "Użytkownicy",
                        customers: "Podłączeni klienci",
                        technicians: "Podłączeni technicy",
                        resellers: "Podłączeni sprzedawcy",
                    },
                    recommendations: {
                        label: "Rekomendacje",
                    },
                    documents: {
                        label: "Dokumenty"
                    },
                    warnings: {
                        "W001": "Zużycie energii przekraczające próg w ciągu ostatnich 24 godzin (72 kWh dla instalacji 10-44 kW, 250 kWh dla instalacji 60-74 kW)",
                        "W002": "Temperatura w reaktorze znacznie spadła",
                        "W003": "Pozostało 48h do wymiany serwisowej",
                        "W004": "Należy wymienić węgiel aktywny w filtrze węglowym przy silniku 1",
                        "W005": "Należy wymienić węgiel aktywny w filtrze węglowym przy silniku 2",
                        "W006": "Zamarzł miernik poziomu gazu w reaktorze",
                        "W007": "Błąd pomiaru poziomu gazu w reaktorze",
                        "W008": "Brak podania świeżej dawki gnojowicy od wczoraj",
                        "W009": "Prędkość ostatniego pompowania była większa o 50% niż średnia w ostatnim miesiącu",
                        "W010": "Za wysoki poziom H₂S (>1500)",
                        "W011": "Poziom napowietrzania wynosi 60",
                        "W012": "Napowietrzanie jest mniejsze lub równe 3",
                        "W013": "Wczorajsza wydajność wynosi 0, natomiast automatyczne pompowanie jest włączone",
                        "W014": "Pomiar poziomu H₂S w reaktorze - różnica ponad 400ppm",
                        "W015": "Wysokie napowietrzanie - średnia jakość gazu",
                        "W016": "Nieudany pomiar H₂S w ciągu ostatnich 24h",
                        "W017": "Niski poziom oleju w mieszadle",
                        "W018": "Niski poziom oleju w silniku",
                        "W020": "Niski poziom ciśnienia wody w obiegu",
                        "W104": "Poziom H₂S za wysoki (>200) napowietrzanie = 60 lub 3",
                        "W105": "Zbyt wysokie wyeksploatowanie filtra węglowego",
                        "W114": "Wczorajsza wydajność wynosi 0, natomiast automatyczne pompowanie jest wyłączone",
                    }
                }
            },
            ja: {
                translation: {
                    dashboard: {
                        nameIdentifier: "プラント名・番号",
                        efficiencyAll: "プラントの総合効率",
                        onlyErrors: "異常のみ",
                        toggleDisconnected: "オフラインプラントを含む",
                        productionCombined: "エネルギー生産量（Wh）",
                        realisedTechnicalEfficiency: "生産及び技術効率（％）",
                        efficiencyCombined: "生産効率(％)",
                        technicalEfficiencyCombined: "技術効率（％）",
                        bgp: "バイオガス発生量（m³/t）",
                        consumption: "消費量（Wh）",
                        manureLifeTime: "滞留時間（日）",
                        feeding: "投入量（kg）"
                    },
                    common: {
                        on: "オン",
                        off: "オフ",
                        add: "追加",
                        save: "変更を保存",
                        monday: "月曜日",
                        tuesday: "火曜日",
                        wednesday: "水曜日",
                        thursday: "木曜日",
                        friday: "金曜日",
                        saturday: "土曜日",
                        sunday: "日曜日",
                        from: "変更前",
                        to: "変更後",
                        hours: "時間",
                        reset: "リセット",
                        confirmation: "確認",
                        cancel: "戻る",
                        set: "設定",
                        auto: "自動",
                        manual: "手動",
                        minutes: "分",
                        seconds: "秒",
                        turnOn: "始動",
                        turnOff: "停止",
                        graphLoading: "読み込み中",
                        noData: "データがありません",
                        hoursPerYear: "時間／年",
                        datetime: "日付と時刻",
                        warning: "警告",
                        error: "異常",
                        type: "書類",
                        startdate: "開始の日付と時刻",
                        enddate: "完了の日付と時刻",
                        all: "全部",
                        engine1: "エンジン１",
                        engine2: "エンジン２",
                        ongoing: "進行中",
                        both: "全部",
                        days: "日間",
                        today: "今日",
                        sendAnswers: "回答を送信",
                        employee: "従業員",
                        customer: "オペレーター",
                        sales: "セールス",
                        technician: "テクニシャン",
                        admin: "アドミン",
                        noDateKnown: "日付不明",
                        command: "コマンド",
                        user: "ユーザー",
                        fillInSurvey: "情報を入力してください。",
                        tons: "トン",
                        count: "数",
                        disabled: "無効",
                        addField: "フィールドを追加",
                        deleteField: "フィールドを削除",
                        releases: "リリース",
                        open: "開く",
                        close: "閉じる",
                    },
                    menu: {
                        dashboard: "ダッシュボード",
                        machines: "プラント",
                        questionnaires: "アンケート",
                        banners: "通知",
                        errors: "異常・警告",
                        pcb: "プリント基板",
                        tasks: "メモ",
                        releases: "リリースノート",
                        resellers: "代理店",
                        users: "ユーザー",
                        recommendations: "ヒント",
                        suggestions: "ヒントメッセージ",
                        language: "言語",
                        reconnection: "プラントはオフラインです。接続を再試行するには上記のボタンを押してください。",
                        profile: "会員情報",
                        logout: "ログアウト",
                        firmware: "ファームウェア",
                        sms: "SMS",
                        advanced: "上級機能",
                        errors: "異常",
                        warnings: "警告"
                    },
                    statistics: {
                        label: "データ解析",
                        group1: "グループ１",
                        group2: "グループ２",
                        addProperty: "物性を追加",
                        removeProperty: "物性を削除",
                        startTutorial: "チュートリアルを開始",
                        exitTutorial: "チュートリアルを停止",
                        welcomeStep: {
                            title: "データ解析画面へようこそ！",
                            content: "この画面では簡単に様々な物性とその傾向を比較できます。"
                        },
                        propertyStep: {
                            title: "物性の選択",
                            content: "物性を監視するには、\nこの選択リストから物性を選択してください。"
                        },
                        addPropertyStep: {
                            title: "物性の追加及び削除",
                            content: "物性を追加・削除するには、\nこれらのボタンを押してください。\nグループごとに最大４つの物性を追加できます。"
                        },
                        groupStep: {
                            title: "グループ２の有効化",
                            content: "グループ２を有効にするにはグループ２の\n「物性を追加」ボタンを押してください。\nグループ２の物性はグラフの右側に表示されています。"
                        },
                        dateRangeStep: {
                            title: "日付範囲の選択",
                            content: "日付範囲を選択するにはこのボタンを押してください。\n２ヶ月前までの履歴を確認できます。"
                        },
                        zoomInStep: {
                            title: "グラフの拡大",
                            content: "グラフを拡大するには領域をマウスの左ボタンで\nドラッグしながら囲むと拡大してください。"
                        },
                        zoomOutStep: {
                            title: "グラフの縮小",
                            content: "選択した日付範囲に戻るには、\n「縮小」ボタンを押してください。"
                        },
                        domainStep: {
                            title: "グラフの最小値・最大値の調整",
                            content: "グループごとの最小値・最大値を調整するにはこれらの\n青いペンボタンを押してください。"
                        },
                        csvStep: {
                            title: "CSVデータのダウンロード",
                            content: "現在表示されているグラフをCSVデータとして（エクセル）\nダウンロードするにはこのボタンを押してください。"
                        },
                        imageStep: {
                            title: "写真のダウンロード",
                            content: "現在表示されているグラフを写真として\nダウンロードするにはこの「写真」ボタンを\n押してください。"
                        },
                        controlCardTemp: "制御基板の温度（℃）",
                        lowTensionError: "低電圧異常",
                        overTempFlueGasHeatExch: "排気ガス熱交換器の過熱",
                        feedingCycleLevel: "発酵槽レベル（cm）",
                        batteryVoltage: "バッテリー電圧（V）",
                        waterPressure: "温水循環回路の水圧（mbar）",
                        shortInterval: "10分間あたり",
                        dailyInterval: "毎日の間隔"
                    },
                    smart: {
                        label: "スマート",
                        heightOffset: "ガスホルダーの高さオフセット",
                        active: "パワーモード",
                        passive: "エコモード",
                        preActive: "パワーモードの準備",
                        prePassive: "エコモードの準備",
                        postActive: "パワーモードの終了",
                        postPassive: "エコモードの終了",
                        activeDesc: "パワーモードの期間には、計画された発電が最大化されます。各パワーモード期間の前に、ガスホルダーが膨張されるように「パワーモードの準備」期間が自動的に行われます。\nパワーモードの最大期間は、ガスホルダーの容量及び発電機の電力、プラントのバイオガス生産傾向によって計算されています。\nガスホルダーレベルの最小値に達すると、発電は停止されます (「パワーモードの終了」期間)。",
                        passiveDesc: "エコモードの期間には、ガスが余っている場合のみ発電されます。各エコモード期間の前に、ガスホルダーが減少されるように「エコモードの準備」期間が自動的に行われます。\nエコモードの最大期間は、ガスホルダーの容量及びプラントのバイオガス生産傾向によって計算されています。\nガスホルダーレベルの最大値に達すると、発電は開始されます (「エコモードの終了」期間)。",
                        permanence: "ガスホルダーレベルが最大に達していない時に動的に１台の\nエンジンで運転することにより、一定の発電が最適化されます。",
                        permanency: "継続発電",
                        activateStep: {
                            title: "【スマート】の有効化",
                            content: "プラントの発電量を制御する為に、\n【スマート】を有効化してください。"
                        },
                        timeslotStep: {
                            title: "期間を追加",
                            content: "パワーかエコモードの期間を追加するには、\n「追加」のボタンを押してください。"
                        },
                        fromToStep: {
                            title: "開始・終了",
                            content: "ここは期間の開始・終了の\n日付と時刻を入力してください。"
                        },
                        activePassiveStep: {
                            title: "パワーモードかエコモード",
                            content: "パワーモードかエコモードを選択するには、\nこれらのラジオボタンで選択してください"
                        },
                        saveStep: {
                            title: "期間を保存",
                            content: "期間を保存するには「保存」のボタンを押してください。"
                        },
                        removeStep: {
                            title: "期間を削除",
                            content: "期間を削除するには、ゴミ箱アイコンを押して下さい。"
                        },
                        visualStep: {
                            title: "グラフ",
                            content: "グラフは、\n【スマート】設定と１週間前の発電量を比較します。"
                        },
                        daySwitchStep: {
                            title: "グラフ",
                            content: "次・前の日に移動するには、\nこれらの矢印を押してください。"
                        },
                    },
                    gas: {
                        label: "ガス",
                        minGasBufferLevel: "ガスホルダーレベルの設定最低値",
                        maxGasBufferLevel: "ガスホルダーレベルの設定最大値",
                        gasBufferLevel: "ガスホルダーレベルの実際値",
                        defaultMinGasBufferLevel: "ガスホルダーレベルのデフォルト最小値",
                        defaultMaxGasBufferLevel: "ガスホルダーレベルのデフォルト最大値",
                        airInjectionTime: "生物脱硫のエア注入",
                        maxAirInjectionTime: "生物脱硫の最大エア注入",
                        torch: "ガスフレア",
                        torchType: "ガスフレアモデル",
                        torchStatus: "ガスフレア状態",
                        torchError: "ガスフレア異常",
                        h2s: "硫化水素",
                        h2sLevelReactor: "硫化水素濃度・発酵槽",
                        h2sLevelMotor: "硫化水素濃度・エンジン",
                        filterLoad: "活性炭塔の破過率",
                        sample: "サンプル",
                        carbonFilterSize: "活性炭塔のサイズ",
                        h2sMeasurements: "硫化水素濃度測定",
                        measurements: "測定",
                        changeTo: "測定回数変更",
                        none: "未設置",
                        container: "コンテナ型",
                        extern: "スタンドアロン型",
                        foamBeaterInstalled: "泡消ミキサー",
                        antiFoamInjectionsInstalled: "泡消剤投入",
                    },
                    engine: {
                        label: "エンジン",
                        startEngine: "エンジン始動",
                        stopEngine: "エンジン停止",
                        power: "出力",
                        desiredPower: "設定電力",
                        maxPower: "最大電力",
                        rpm: "回転数",
                        lambda: "ラムダ",
                        throttleValve: "サーボモーター閉度",
                        engineType: "エンジンモデル",
                        engineStatus: "エンジン状態",
                        engineError: "エンジン異常",
                        producedEnergy: "総合エネルギー生産量",
                        operatingHours: "エンジンの運転時間",
                        totalOperatingHours: "エンジンの総合運転時間",
                        gasUsage: "バイオガス消費量",
                        throttlePosition: "スロットルバルブ開度",
                        noErrors: "エンジンは運転中です",
                        production: "生産",
                        servoManually: "サーボモーター閉度の設定は手動的です。",
                        servoAuto: "サーボモーター閉度の設定は自動的です。",
                        servoControl: "サーボモーター制御",
                        temperature: "エンジン温度",
                        restart: "ガス生産量が十分な時自動的に再始動されます",
                        idle: "待機",
                        running: "運転",
                        waiting: "始動準備",
                        booting: "始動",
                        shuttingDown: "停止",
                        advanced: "上級機能",
                        numberOfEngines: "エンジン数量",
                        predeterminedRunHours: "目標運転時間",
                        easyStart1: "イージースタート・エンジン１",
                        easyStart2: "イージースタート・エンジン２",
                        setError1: "エラー設定・エンジン１",
                        setError2: "エラー設定・エンジン２",
                        fuel: "燃料",
                        airIntakeOpen: "エアインテーク開放",
                        airIntakeClosed: "エアインテーク締切",
                    },
                    service: {
                        label: "機器",
                        lastMaintenance: "定期的なメンテナンス",
                        currentlyInstalled: "本機器の運転時間",
                        total: "総合運転時間",
                        mixer: "撹拌機",
                        foamBeater: "泡消ミキサー",
                        intakePump: "投入ポンプ",
                        digestatePump: "排出ポンプ",
                        newMixer: "撹拌機交換",
                        newEngine: "エンジン交換",
                        newFoamBeater: "泡消ミキサー交換",
                        newIntakePump: "投入ポンプ交換",
                        newDigestatePump: "排出ポンプ交換",
                        confirmationMessage: "{{type}} の運転時間をリセットします、よろしいでしょうか", // The name of the equipment needs to be BEFORE the sentence.
                        maintenanceMessage: "{{type}} の運転時間がリセットされました", // The name of the equipment needs to be BEFORE the sentence.
                        newEquipmentMessage: "機器が交換されました",
                        prints: "プリント基板",
                        firstOnline: "最新日付",
                        lastOnline: "最古日付",
                        serial: " シリアル番号",
                        type: "書類",
                        engine: "エンジン",
                        engine1: "エンジン 1",
                        engine2: "エンジン 2",
                        h2s1: "硫化水素エンジン1",
                        h2s2: "硫化水素エンジン2",
                        resetTo: "運転時間のリセットを選択してください（デフォルト＝０）",
                        timeFilterReplace: "活性炭交換後の経過時間",
                        daysSinceOil: "定期的なメンテナンスの経過時間",
                        daysSinceOilChange: "定期的なメンテナンスの経過時間",
                        newGenerator: "ジェネレーター交換",
                        generator: "ジェネレーター",
                        generator1: "ジェネレーター 1",
                        generator2: "ジェネレーター 2",
                        videoMessage: "定期的なメンテナンスのビデオは「書類」ページ・一般的なビデオにあります。",
                        videoRoom: "サービス技術者がこの機械のためにビデオルームを開設しました。参加するにはここをクリックしてください。",
                        videoButton: "本プラントに関するビデオ通話を開始",
                        videoButtonSub: "ユーザーは「機器」ページからビデオ通話を参加できます",
                        videoClose: "ビデオルームを閉じる"
                    },
                    net: {
                        net: "系統",
                        meterSettings: "エネルギーメーターの設定",
                        voltagePerPhase1: "第１相の電圧",
                        voltagePerPhase2: "第２相の電圧",
                        voltagePerPhase3: "第３相の電圧",
                        frequencyPerPhase: "周波数",
                        angleBetweenPhase1And2: "第１相と第２相の間の位相差",
                        ownPowerConsumption: "消費電力",
                        ownEnergyConsumption: "エネルギー消費量",
                        bioguardVersion: "BIOGUARDバージョン",
                        brutoProducedEnergy: "エネルギー生産量",
                        nettoProducedEnergy: "正味エネルギー生産量",
                        producedEnergy: "エネルギー生産量",
                        producedEnergyEngine1: "エネルギー生産量・エンジン１",
                        producedEnergyEngine2: "エネルギー生産量・エンジン２",
                        monitoringRelay: "プラント保護リレー",
                    },
                    reactor: {
                        label: "発酵槽",
                        reactorTemp: "発酵槽温度",
                        reactorTempTarget: "発酵槽設定温度",
                        reactorLevel: "発酵槽設定レベル",
                        hydrostaticPressure: "発酵槽実際レベル",
                        siloType: "発酵槽モデル",
                        extraHeating: "追加加温",
                        antiFrost: "凍結防止",
                        antiFrostIndex: "凍結防止レベル",
                        antiFrostAuto: "凍結防止レベルは敷地気温に基づいて自動的に設定されます。",
                        antiFrostMessageNew: "各凍結防止サイクルには、投入ポンプが４秒間作動され、「排出ポンプ流量」の設定に基づいて排出ポンプが約100lの消化液が排出されます。",
                        antiFrostMessageOld: "各凍結防止サイクルには、投入ポンプが４秒間作動され、排出ポンプが120秒間作動されます。",
                        coolwater: "温水",
                        statusCoolingValve: "温水の三方弁",
                        waterTempIn: "発酵槽上流の温水温度",
                        waterTempOut: "発酵槽下流の温水温度",
                        waterTempMotorOut: "エンジン１下流の温水温度",
                        waterTempMotorOut2: "エンジン２下流の温水温度",
                        coolingWaterTemp: "温水設定温度",
                        maxCoolingWaterTemp: "温水最大温度",
                        waterPressure: "温水循環回路の水圧",
                        foamBeater: "泡消ミキサー",
                        foambeatEveryXMinutes: "運転頻度",
                        foambeatForXSeconds: "内運転時間",
                        mixer: "撹拌機",
                        mixerMaxPower: "撹拌機最大電力",
                        mixerActive: "撹拌機状態",
                        mixEveryXMinutes: "運転頻度",
                        mixForXSeconds: "内運転時間",
                        blockMixingFrom: "撹拌禁止:", // "No mixing from" 9 "to" 10 = "撹拌禁止：" 9 "～" 10
                        blockMixingTo: "～", // See above
                        mixerInfo: "撹拌機は、設定した「運転頻度」及び「オン時間」で自動的に始動されます。\n「撹拌禁止」の期間中、「撹拌機最大電力」がプラントの現在発電量を超える場合、撹拌機が停止されます。\n発電機が始動されるからエンジン定格電力の50％に達するまで撹拌機が一時的に停止されます。",
                        mix1Hour: "１時間撹拌",
                        coolwaterValveInstalled: "制御式三方弁",
                        bypassFan: "三方弁（バイパス・空冷式熱交換器）",
                        digester: "三方弁（発酵槽・余剰熱利用の熱交換器）",
                        silo: "発酵槽",
                        bag: "ガスホルダー",
                        plateHeatExchanger: " プレート式熱交換器",
                        timesPerDay: "回/日",
                        intern: "内部接続",
                        extern: "外部接続",
                        tempHigh: "発酵槽最大温度",
                        externMessage: "（撹拌機が「外部接続」に設定された場合、次のエラーは発生されません）サーマルリレーをリセットしてください。",
                        bypass: "バイパス",
                        ventilator: "空冷式熱交換器",
                    },
                    efficiency: {
                        label: "効率",
                        production: "エネルギー生産量・30日間",
                        consumption: "エネルギー消費量・30日間",
                        nettoProduction: "正味エネルギー生産量・30日間",
                        averageEfficiency: "平均生産効率・30日間",
                        averageTechnicalEfficiency: "平均技術効率・30日間",
                        bgp: "バイオガス発生量",
                        top10: "ランキングトップ10",
                        realised: "生産効率",
                        technical: "技術効率",
                        bgpInfo: "この「バイオガス発生量」【m³/t】は過小推定値です。停止期間及び過剰ガス生産、ガスフレア運転による生産ロスは考慮されません。",
                        pointPerDay: "％/日",
                        trendline: "傾向線",
                    },
                    general: {
                        label: "一般情報",
                        h2sSensorFirmwareVersion: "H₂Sバージョン",
                        idH2s: "H₂Sシリアル番号",
                        bioId: "BIOTRONICシリアル番号",
                        identifier: "プラント番号",
                        collabContract: "共同経営契約",
                        own: "メンテナンス契約",
                        sold: "共同経営終了",
                        productionDate: "製造日",
                        commissioningDate: "試運転日",
                        controlCardTemp: "制御基板の温度",
                        batteryVoltage: "バッテリー電圧",
                        motorVoltage: "エンジン電圧",
                        overtempFlueGasHeatExch: "排気ガス熱交換器の過熱",
                        rebootMainBoard: "制御基板の再起動",
                        rebootNow: "再起動",
                        utcOffset: "UTCとの偏差",
                        location: "住所",
                        network: "ネットワーク",
                        ip: "IP",
                        mask: "マスク",
                        gateway: "ゲートウェイ",
                        dns: "DNS",
                        pcb: "プリント基板",
                        firmware: "ファームウェア",
                        mainControlUnit: "制御基板",
                        bioguard: "BIOGUARD",
                        biotronicEngine1: "BIOTRONIC・エンジン１",
                        biotronicEngine2: "BIOTRONIC・エンジン２",
                        h2sEngine1: "H₂S・エンジン１",
                        h2sEngine2: "H₂S・エンジン２",
                        bioIdEngine1: "BioID・エンジン１",
                        bioIdEngine2: "BioID・エンジン２",
                        modificationsToMachine: "変更履歴",
                        name: "プラント名",
                        normal: "普通",
                        lowError: "低（異常）"
                    },
                    feeding: {
                        label: "投入・排出",
                        feedingVolume: "サイクルあたりの投入量",
                        feedingCyclesPerDay: "1日あたり投入回数",
                        feedingBalance: "投入バランス",
                        autoFeedingActive: "自動投入",
                        autoFeedingStartHour: "1日の最初自動投入",
                        exitPumpRunning: "手動排出",
                        feedingCycle: "投入サイクル",
                        onlyInput: "投入のみ",
                        onlyOutput: "排出のみ",
                        startFeeding: "開始",
                        stopFeeding: "停止",
                        pumpOutFlowRate: "排出ポンプ流量",
                        kiloPerPulse: "流量計パルス",
                        intakeTimeout: "投入タイムアウト",
                        averageDailyFeeding: "先月の平均1日あたり投入量",
                        retentionTime: "滞留時間",
                        statusValvePumpIn: "投入自動弁",
                        statusValvePumpOut: "排出自動弁",
                        statusValveRetourReactor: "循環自動弁",
                        valveCurrentActuator: "排出自動弁電流",
                        lastFeeding: "現在・最新",
                        startHourLastFeeding: "サイクルの開始時間",
                        feedingStatus: "サイクル状態",
                        feedingError: "発酵槽異常",
                        intakePumpTime: "投入時間",
                        intakePumpAmount: "投入量",
                        exitPumpTime: "排出時間",
                        exitPumpAmount: "排出量",
                        feedingHistory: "投入履歴",
                        startedOn: "開始時間",
                        temperature: "発酵槽温度",
                        reactorLevel: "発酵槽レベル",
                        balance: "バランス",
                        acknowledge: "異常を消去",
                        intakeSpeed: "投入流量",
                        balanceWarning: "注意：このボタンを押すと、普通の投入サイクルが開始されるのではなく、バランス量の原料が発酵槽に投入されます。",
                        automaticFeedingSettings: "自動投入設定",
                        manualFeeding: "手動制御",
                        pumpSettings: "ポンプ設定",
                        status: {
                            "0": "待機",
                            "1": "レベル測定",
                            "2": "排出",
                            "3": "投入",
                            "5": "凍結防止",
                            "6": "ガス排気",
                            "7": "手動排出",
                            "10": "起動",
                            "2.001": "排出・撹拌",
                            "2.002": "排出",
                            "2.003": "排出・排出自動弁の開放",
                            "2.004": "排出・排出自動弁の開放",
                            "2.005": "排出・排出ポンプの始動",
                            "2.006": "排出",
                            "2.007": "排出・投入開始",
                            "2.008": "排出・停止",
                            "2.009": "排出・停止",
                            "2.010": "排出・排出自動弁の締切",
                            "2.011": "排出・排出自動弁の締切",
                            "2.012": "排出・停止",
                            "3.001": "投入・用意",
                            "3.002": "投入・用意",
                            "3.003": "投入・用意",
                            "3.004": "投入・開始",
                            "3.005": "投入・開始",
                            "3.006": "投入・完了",
                            "3.007": "投入・完了",
                            "3.008": "投入・投入ポンプの停止",
                            "3.009": "投入・投入ポンプの停止",
                            "3.010": "投入・投入ポンプの停止",
                            "3.011": "投入・投入ポンプの停止",
                            "3.012": "投入・完了",
                            "5.001": "凍結防止・投入側開始",
                            "5.002": "凍結防止・投入側開始",
                            "5.003": "凍結防止・投入側開始",
                            "5.004": "凍結防止・投入側開始",
                            "5.005": "凍結防止・投入側停止",
                            "5.006": "凍結防止・排出自動弁の開放",
                            "5.007": "凍結防止・排出自動弁の開放",
                            "5.008": "凍結防止・排出側開始",
                            "5.009": "凍結防止・排出側開始",
                            "5.010": "凍結防止・排出側開始",
                            "5.011": "凍結防止・排出側開始",
                            "5.012": "凍結防止・排出側開始",
                            "5.013": "凍結防止・排出自動弁の締切",
                            "5.014": "凍結防止・排出自動弁の締切",
                            "5.015": "凍結防止・完了",
                            "6.001": "ガス排気・排出自動弁の開放",
                            "6.002": "ガス排気・排出自動弁の開放",
                            "6.003": "ガス排気・排出自動弁の締切",
                            "6.004": "ガス排気・排出自動弁の締切",
                            "6.005": "ガス排気",
                            "7.001": "手動排出・排出自動弁の開放",
                            "7.002": "手動排出・排出自動弁の開放",
                            "7.003": "手動排出・投入開始",
                            "7.004": "手動排出・投入ポンプの停止",
                            "7.005": "手動排出・排出自動弁の締切",
                            "7.006": "手動排出・排出自動弁の締切",
                            "7.007": "手動排出・完了",
                            "7.008": "手動排出・完了",
                            "10.001": "起動・投入自動弁の締切",
                            "10.002": "起動・投入自動弁の締切",
                            "10.003": "起動・排出自動弁の締切",
                            "10.004": "起動・排出自動弁の締切",
                            "10.005": "起動・循環自動弁の締切",
                            "10.006": "起動・循環自動弁の締切",
                            "10.007": "起動・電気接続確認",
                            "10.008": "起動・電気接続確認",
                            "10.009": "起動"
                        }
                    },
                    users: {
                        label: "ユーザー",
                        customers: "オペレーター",
                        technicians: "テクニシャン",
                        resellers: "ディストリビューター",
                    },
                    recommendations: {
                        label: "ヒント",
                    },
                    documents: {
                        label: "書類"
                    },
                    warnings: {
                        "W001": "過去24時間のエネルギー消費量が閾値を超えました（10～44kWプラント＝72 kWh・60～74kWプラント＝250 kWh）",
                        "W002": "発酵槽温度が非常に低下しました",
                        "W003": "定期的なメンテナンスは48時間以内に行う必要です",
                        "W004": "活性炭塔１の活性炭を交換する必要です",
                        "W005": "活性炭塔２の活性炭を交換する必要です",
                        "W006": "ガスホルダーレベル測定のチューブが凍結しました（圧力が高過）",
                        "W007": "ガスホルダーレベル測定が非論理的です（圧力が低過）",
                        "W008": "昨日投入がありませんでした",
                        "W009": "最新投入の時に投入ポンプの流量は先月の平均より50％高かったです",
                        "W010": "硫化水素濃度は高いです（＞1500 ppm）",
                        "W011": "生物脱硫のエア注入は最大になりました（60 秒/分）",
                        "W012": "生物脱硫のエア注入は最低になりました（＜3 秒/分）",
                        "W013": "昨日自動投入はオンでしたが生産効率は0％でした。",
                        "W014": "発酵槽側の硫化水素濃度の両方測定の差は大き過ぎます（＞400 ppm）",
                        "W015": "生物脱硫のエア注入は長時に高いです",
                        "W016": "過去24時間に全ての硫化水素濃度測定は失敗しました",
                        "W017": "撹拌機のオイルレベルは低過ぎます",
                        "W018": "エンジンのオイルレベルは低過ぎます",
                        "W020": "温水の水圧は低過ぎます",
                        "W104": "硫化水素濃度は高過ぎます（＞200 ppm）又は生物脱硫のエア注入は最大・最低です",
                        "W105": "活性炭塔の破過率は高過ぎます",
                        "W114": "昨日生産効率は＞0％でしたが自動投入はオフでした。",
                    }
                }
            },
            lt: {
                translation: {
                    dashboard: {
                        nameIdentifier: "Vardas arba identifikatorius",
                        efficiencyAll: "Visų mašinų efektyvumas",
                        onlyErrors: "Tik klaidos",
                        toggleDisconnected: "Perjungti atsijungusias mašinas",
                        productionCombined: "Kombinuota produkcija (Wh)",
                        realisedTechnicalEfficiency: "Realizuotas/Techninis našumas (%)",
                        efficiencyCombined: "Realizuotas efektyvumas (%)",
                        technicalEfficiencyCombined: "Techninis našumas (%)",
                        bgp: "Bgp (m³/ton))",
                        consumption: "Suvartojimas (Wh)",
                        manureLifeTime: "Mėšlo gyvavimo laikas (dienos)",
                        feeding: "Maitinimas (kg)"
                    },
                    common: {
                        on: "Įjungti",
                        off: "šjungti",
                        add: "Pridėti",
                        save: "Išsaugoti",
                        monday: "Pirmadienis",
                        tuesday: "Antradienis",
                        wednesday: "Trečiadienis",
                        thursday: "Ketvirtadienis",
                        friday: "Penktadienis",
                        saturday: "Šeštadienis",
                        sunday: "Sekmadienis",
                        from: "Nuo",
                        to: "Kam",
                        hours: "valandos",
                        reset: "reset",
                        confirmation: "patvirtinimas",
                        cancel: "atšaukti",
                        set: "nustatyti",
                        auto: "auto",
                        manual: "manual",
                        minutes: "minutės",
                        seconds: "sekundės",
                        turnOn: "įjungti",
                        turnOff: "išjungti",
                        graphLoading: "grafa kraunasi...",
                        noData: "duomenų nerasta...",
                        hoursPerYear: "valandos/metus",
                        datetime: "data/laikas",
                        warning: "įspėjimas",
                        error: "klaida",
                        type: "tipas",
                        startdate: "Pradžios data",
                        enddate: "Pabaiga data",
                        all: "visi",
                        engine1: "variklis 1",
                        engine2: "variklis 2",
                        ongoing: "tęsiasi",
                        both: "abu",
                        days: "Dienos",
                        today: "Šiandiena",
                        sendAnswers: "Siųsti atsakymus",
                        employee: "Darbuotojas",
                        customer: "Klientas",
                        sales: "Pardavimai",
                        technician: "Technikas",
                        admin: "Admin",
                        noDateKnown: "No date known",
                        command: "Command",
                        user: "Vartotojas",
                        fillInSurvey: "Prašome užpildyti klausimyną",
                        tons: "tonos",
                        count: "Skaičiuoti",
                        disabled: "Disabled",
                        addField: "Pridėti laukelį",
                        deleteField: "Ištrinti laukelį",
                        releases: "Releases",
                        open: "Atidaryti",
                        close: "Uždaryti",
                    },
                    menu: {
                        dashboard: 'Dashboard',
                        machines: 'Instaliacijos',
                        questionnaires: 'Klausimynai',
                        banners: "Banners",
                        errors: "Klaidos ir įspėjimai",
                        pcb: "Spausdintinės plokštės",
                        tasks: "Mano užduotys",
                        releases: "Release notes",
                        resellers: "Perpardavėjai",
                        users: "Vartotojai",
                        recommendations: "Rekomendacijos",
                        suggestions: "Rekomendacijų žinutės",
                        language: "Kalba",
                        reconnection: "Ši instaliacija yra neprisijungusi. Pamėginkite gauti naują prisijungimą paspausdami mygtuką esantį žemiau.",
                        profile: "Profile",
                        logout: "Log out",
                        firmware: "Firmware",
                        sms: "SMS",
                        advanced: "Advanced",
                        errors: "Errors",
                        warnings: "Warnings",
                    },
                    statistics: {
                        label: "pavadinimas",
                        group1: "Grupė 1",
                        group2: "Grupė 2",
                        addProperty: "Pridėti nuosavybę",
                        removeProperty: "Ištrinti nuosavybę",
                        startTutorial: "Pradėti pamoką",
                        exitTutorial: "Baigti pamoką",

                        welcomeStep: {
                            title: "Sveiki atvykę į statistikos puslapį.",
                            content: "Čia galite palyginti skirtingas vertes ir tendencijas. "
                        },
                        propertyStep: {
                            title: "Nuosavybės pasirinkimas.",
                            content: "Norėdami pradėti, pasirinkite jus dominančią nuosavybę iš pasirinkimo laukelio."
                        },
                        addPropertyStep: {
                            title: "Nuosavybių pridėjimas ir pašalinimas",
                            content: "Pabandykite pridėti arba pašalinti nuosavybę, vienoje grupėje galite pridėti iki 4 nuosavybių."
                        },
                        groupStep: {
                            title: "Įjungiama 2 grupė.",
                            content: "Paspauskite pridėti nuosavybę, jog galėtumėte pasirinkti nuosavybę grupei 2. Nuosavybės šioje grupėje bus rodomos dešinėje grafiko pusėje."
                        },
                        dateRangeStep: {
                            title: "Pasirinkite datas",
                            content: "Naudokite šį mygtuką norėdami pasirinkti datas. Šiuo metu turite prieigą prie dviejų mėnesių istorijos."
                        },
                        zoomInStep: {
                            title: "Priartinti",
                            content: "Vilkite pelės žymeklį virš grafiko, kad pasirinktumėte sritį, kurią norite priartinti."
                        },
                        zoomOutStep: {
                            title: "Nutolinti",
                            content: "Iš naujo nustatykite anksčiau pasirinktas datas naudodami mygtuką Nutolinti."
                        },
                        domainStep: {
                            title: "Domenų koregavimas",
                            content: "Galite rinktis max ir min duomenis naudodami šiuos mygtukus."
                        },
                        csvStep: {
                            title: "Parsisiųsti csv duomenis",
                            content: "Atsisiųskite šiuo metu rodomus diagramos duomenis CSV formatu."
                        },
                        imageStep: {
                            title: "Parsisiųsti paveikslėlį",
                            content: "Atsisiųskite šiuo metu rodomą diagramą kaip paveikslėlį."
                        },
                        controlCardTemp: "Kontrolės kortelės temperatūra (°C)",
                        lowTensionError: "Žemos įtampos klaida",
                        overTempFlueGasHeatExch: "Perkaitęs išmetamųjų dujų šilumokaitis",
                        feedingCycleLevel: "Maitinimo ciklo lygis (cm)",
                        batteryVoltage: "Akumuliatoriaus įtampa (V)",
                        waterPressure: "Vandens slėgis (mbar)",
                        shortInterval: "10 Minute Intervals",
                        dailyInterval: "Daily Intervals",
                    },
                    smart: {
                        label: "Smart",
                        heightOffset: "Aukščio poslinkis",
                        active: "Active",
                        passive: "Passive",
                        preActive: "Pre Active",
                        prePassive: "Pre Passive",
                        postActive: "Post Active",
                        postPassive: "Post Passive",
                        activeDesc: "Aktyvaus laikotarpio metu planuojama energijos gamyba maksimaliai padidinama. Prieš kiekvieną aktyvų laikotarpį automatiškai suteikiamas „ikiaktyvus“ laiko tarpas, kuriame kaupiamas dujų buferis. Maksimali aktyvaus laikotarpio trukmė nustatoma pagal dujų buferio tūrį, įrengtų CHP skaičių ir tipą bei įrenginio biodujų gamybos profilį. Pasiekus minimalų dujų buferio užpildymo lygį, energijos gamyba bus sustabdyta (poaktyvusis laikotarpis).",
                        passiveDesc: "Pasyviuoju periodu energija gaminama tik esant dujų pertekliui. Prieš kiekvieną pasyvųjį periodą automatiškai suteikiamas laiko tarpas, kurio metu sumažinamas dujų buferis. Maksimali pasyvaus laikotarpio trukmė nustatoma pagal dujų buferio tūrį ir įrenginio biodujų gamybos profilį. Pasiekus maksimalų dujų buferio užpildymo lygį, prasidės energijos gamyba (postpasyvus laikotarpis).",
                        permanence: "Stimulate constant production by dynamically running on one engine with a non-maximum gas buffer",
                        permanency: "Permanence",
                        activateStep: {
                            title: "SMART aktyvinimas",
                            content: "Suaktyvinkite SMART, kad galėtumėte valdyti įrenginio gamybos profilį"
                        },
                        timeslotStep: {
                            title: "Pridėkite laiko tarpą",
                            content: "Pridėkite aktyvų arba pasyvų laiko tarpą"
                        },
                        fromToStep: {
                            title: "Nuo / Iki",
                            content: "Jūs nustatote pradžios ir pabaigos dieną bei laiką"
                        },
                        activePassiveStep: {
                            title: "Actyvus ar pasyvus",
                            content: "Pasirinkite, ar norite nustatyti aktyvų ar pasyvų laiko tarpą"
                        },
                        saveStep: {
                            title: "Išsaugoti laiko tarpu",
                            content: "Išsaugokite pakeitimų nustatymus"
                        },
                        removeStep: {
                            title: "Pašalinkite laiko tarpus",
                            content: "Pašalinkite savo laiko tarpą čia"
                        },
                        visualStep: {
                            title: "Vizuali reprezentacija",
                            content: "Šioje diagramoje SMART nustatymai lyginami su praėjusios savaitės gamybos profiliu"
                        },
                        daySwitchStep: {
                            title: "Vizuali reprezentacija",
                            content: "Lengvai naviguokite į kitą ar ankstesnę dieną"
                        },
                    },
                    gas: {
                        label: "Dujos",
                        minGasBufferLevel: "Min dujų lygis buferyje",
                        maxGasBufferLevel: "Max dujų lygis buferyje",
                        gasBufferLevel: "Dujų lygis buferyje",
                        defaultMinGasBufferLevel: "Numatytasis min. dujų lygis buferyje ",
                        defaultMaxGasBufferLevel: "Numatytasis max. dujų lygis buferyje",
                        airInjectionTime: "Aeracija",
                        maxAirInjectionTime: "Max aeracija",
                        torch: "Degiklis",
                        torchType: "Degiklio tipas",
                        torchStatus: "Degiklio statusas",
                        torchError: "Degiklio klaida",
                        h2s: "H₂S",
                        h2sLevelReactor: "H₂S lygis reaktoriuje",
                        h2sLevelMotor: "H₂S lygis variklyje",
                        filterLoad: "Anglies filtro apkrova",
                        sample: "Mėginys",
                        carbonFilterSize: "Anglies filtro dydis",
                        h2sMeasurements: "H₂S matavimai",
                        measurements: "Matavimai",
                        changeTo: "Pakeisti į",
                        none: "Jokios",
                        container: "Konteineris",
                        extern: "Išorinis",
                        foamBeaterInstalled: "Putų plakiklis įdiegtas",
                        antiFoamInjectionsInstalled: "Antiputų injekcijos įdiegtos",
                    },
                    engine: {
                        label: "Variklis",
                        startEngine: "Užvesti variklį",
                        stopEngine: "Sustabdyti variklį",
                        power: "Galia",
                        desiredPower: "Nustatyta galia",
                        maxPower: "Max galia",
                        rpm: "Apsisukimai per minutę (RPM)",
                        lambda: "Lambda",
                        throttleValve: "Servo padėtis",
                        engineType: "Variklio tipas",
                        engineStatus: "Variklio statusas",
                        engineError: "Variklio klaidos",
                        producedEnergy: "Visa pagaminta energija",
                        operatingHours: "Darbo valandos",
                        totalOperatingHours: "Visos darbo valandos",
                        gasUsage: "Dujų sunaudojimas",
                        throttlePosition: "Droselio pozicija",
                        noErrors: "Visos sistemos veikia.",
                        production: "Produkcija",
                        servoManually: "Servo nustatytas rankiniu būdu",
                        servoAuto: "Servo nustatytas automatiniu būdu",
                        servoControl: "Servo valdymas",
                        temperature: "Variklio temperatūra",
                        restart: "Perkraukite automatiškai kai bus dujų",
                        idle: "Idle",
                        running: "Veikia",
                        waiting: "Laukiama paleidimo",
                        booting: "Paleidžiama",
                        shuttingDown: "Išjungiama",
                        advanced: "Pažengęs",
                        numberOfEngines: "variklių kiekis",
                        predeterminedRunHours: "Nustatytos veikimo valandos",
                        easyStart1: "Easy start engine 1",
                        easyStart2: "Easy start engine 2",
                        setError1: "Set error engine 1",
                        setError2: "Set error engine 2",
                        fuel: "Kuras",
                        airIntakeOpen: "Oro įsiurbimas atidarytas",
                        airIntakeClosed: "Oro įsiurbimas uždarytas",
                    },
                    service: {
                        label: "Servisas",
                        lastMaintenance: "Darbo valandos iki kito tepalų keitimo",
                        currentlyInstalled: "Šiuo metu įdiegtos darbo valandos",
                        total: "Visos darbo valandos",
                        mixer: "Maišytuvas",
                        foamBeater: "Putų plakiklis",
                        intakePump: "Įsiurbimo pompa",
                        digestatePump: "Digestato pompa",
                        newEngine: "Sumontuotas naujas variklis",
                        newMixer: "Sumontuotas naujas maišytuvas",
                        newEngine: "Sumontuotas naujas variklis",
                        newFoamBeater: "Sumontuotas naujas putų plaktuvas",
                        newIntakePump: "Sumontuotas naujas įsiurbimo siurblys",
                        newDigestatePump: "Sumontuota nauja digestato pompa",
                        confirmationMessage: "Ar tikrai norite iš naujo nustatyti",
                        maintenanceMessage: "Techninės priežiūros valandos buvo nustatytos iš naujo",
                        newEquipmentMessage: "Sumontuota nauja įranga:",
                        prints: "spaudiniai",
                        firstOnline: "First online",
                        lastOnline: "Last online",
                        serial: "Serijinis",
                        type: "Tipas",
                        engine: "Variklis",
                        resetTo: "Reset to x hours (default 0)",
                        timeFilterReplace: "Laikas nuo filtro pakeitimo",
                        daysSinceOil: "Dienos nuo tepalų keitimo",
                        newGenerator: "Sumontuotas naujas generatorius",
                        generator: "Generatorius",
                        daysSinceOilChange: "Dienos nuo tepalų keitimo",
                        videoMessage: "Vaizdo įrašas apie tepalų keitimą => Dokumentų puslapis, skiltyje. Bendrieji vaizdo įrašai.",
                        videoRoom: "A service technician has opened a video room for this machine, Click here to join",
                        videoButton: "Start a video room for this machine",
                        videoButtonSub: "Users with access can join the call from their service page",
                        videoClose: "Close video room"
                    },
                    net: {
                        net: "Tinklas",
                        meterSettings: "Skaitiklio nustatymai",
                        voltagePerPhase1: "Fazės įtampa 1",
                        voltagePerPhase2: "Fazės įtampa 2",
                        voltagePerPhase3: "Fazės įtampa 3",
                        frequencyPerPhase: "Fazės dažnis",
                        angleBetweenPhase1And2: "Kampas tarp 1 ir 2 fazės",
                        ownPowerConsumption: "Savos galios suvartojimas",
                        ownEnergyConsumption: "Savos energijos sunaudojimas",
                        bioguardVersion: "Bioguard versija",
                        brutoProducedEnergy: "Bruto pagaminta energija",
                        nettoProducedEnergy: "Neto pagaminta energija",
                        producedEnergy: "Pagaminta energija",
                        producedEnergyEngine1: "Pagaminta energija varikls 1",
                        producedEnergyEngine2: "Pagaminta energija variklis 2",
                        monitoringRelay: "Stebėjimo rėlė",
                    },
                    reactor: {
                        label: "Reaktorius",
                        reactorTemp: "Reaktoriaus temperatūra",
                        reactorTempTarget: "Reaktoriaus temperatūros tikslas",
                        reactorLevel: "Norimas reaktoriaus lygis",
                        hydrostaticPressure: "Dabartinis reaktoriaus lygis",
                        siloType: "Silo tipas",
                        extraHeating: "Papildomas šildymas aktyvus",
                        antiFrost: "Anti frost",
                        antiFrostIndex: "AntiFrost indeksas",
                        antiFrostAuto: "Užšalimo indeksas nustatomas automatiškai, atsižvelgiant į vietinę temperatūrą",
                        antiFrostMessageNew: "Kiekvienas užšalimo indekso ciklas suaktyvins įsiurbimo siurblį 4 s, o išėjimo siurblys išsikraus apytiksliai. 100 l digestato, remiantis srauto greičiu. ",
                        antiFrostMessageOld: "Kiekvienas užšalimo indekso ciklas suaktyvins įsiurbimo siurblį 4 s, o išleidimo siurblį 120 s.",
                        coolwater: "Šaltas vanduo",
                        statusCoolingValve: "Šalto vandens vožtuvas",
                        waterTempIn: "Įeinančio šalto vandens temperatūra",
                        waterTempOut: "Išeinančio šalto vandens temperatūra",
                        waterTempMotorOut: "Vandens temperatūra išeinanti iš 1 variklio",
                        waterTempMotorOut2: "Vandens temperatūra išeinanti iš 2 varklio",
                        coolingWaterTemp: "Pageidaujama vėsaus vandens temperatūra",
                        maxCoolingWaterTemp: "Max aušinimo vandens temp",
                        waterPressure: "Vandens slėgis šalto vandens grandinėje",
                        foamBeater: "Putos",
                        foambeatEveryXMinutes: "Maišyti putas kas",
                        foambeatForXSeconds: "For",
                        mixer: "Maišytuvas",
                        mixerMaxPower: "Max maišytuvo jėga",
                        mixerActive: "Maišytuvas aktyvus",
                        mixEveryXMinutes: "Maišyti kas",
                        mixForXSeconds: "For",
                        blockMixingFrom: "Jokio maišymo nuo",
                        blockMixingTo: "To",
                        mixerInfo: "Maišytuvas automatiškai įsijungia pasirinktam laikui su pasirinktu laiko intervalu. Maišytuvo bloko laiko lango metu maišytuvas sustabdomas, kai sumontuota maišytuvo galia viršija esamą įrenginio galią. Maišytuvas laikinai sustabdomas paleidžiant CHP, kol pasiekiama 50% vardinės galios, kad būtų užtikrinta įsijungimo srovė.",
                        mix1Hour: "Maišyti 1val. ",
                        coolwaterValveInstalled: "Control valve by-pass / fan",
                        bypass: "Valdymo vožtuvo apėjimas/ventiliatorius",
                        digester: "Control valve digester / external heat consumption",
                        silo: "Silo",
                        bag: "Maišas",
                        plateHeatExchanger: "Plokštelinis šilumokaitis",
                        timesPerDay: "laikas / dieną",
                        intern: "Vidinis",
                        extern: "Išorinis",
                        tempHigh: "Aukšta temperatūra",
                        externMessage: "Jei maišytuvas nustatytas išoriškai, ši klaida nebus suaktyvinta: Maišytuvas neveikia, iš naujo nustatykite thermal.",
                        bypass: "By-pass",
                        ventilator: "Ventiliatorius"
                    },
                    efficiency: {
                        label: "Našumas",
                        production: "Energijos produkcija per paskutines 30 dienų",
                        consumption: "Energijos suvartojimas per paskutines 30 dienų",
                        nettoProduction: "Neto energijos produkcija per paskutines 30 dienų",
                        averageEfficiency: "30 dienų produktyvumo vidurkis",
                        averageTechnicalEfficiency: "30 dienų techninio prieinamumo vidurkis",
                        bgp: "Mėšlo kokybė (BGP batch)",
                        top10: "Top 10 projektų",
                        realised: "Realizuotas efektyvumas",
                        technical: "Techninis prieinamumas",
                        bgpInfo: "BGP įvertinimas [m³/t]. Neatsižvelgiama į nuostolius, atsiradusius dėl prastovos, perprodukcijos ar deginimo.",
                        pointPerDay: "%/dieną",
                        trendline: "Tendencijos linija",
                    },
                    general: {
                        label: "Pagrindini",
                        h2sSensorFirmwareVersion: "H₂S programinės įrangos versija",
                        idH2s: "S/N H₂S ID",
                        bioId: "S/N Biotronic ID",
                        identifier: "Instaliacijos identifikatorius",
                        collabContract: "Bendradarbiavimo sutartis",
                        own: "Techninės priežiūros sutartis",
                        sold: "Parduota instaliacija",
                        productionDate: "Pagaminimo data",
                        commissioningDate: "Paleidimo data",
                        controlCardTemp: "Kontroliuoti kortelės temperatūrą",
                        batteryVoltage: "Akumuliatoriaus įtampa",
                        motorVoltage: "Variklio įtampa",
                        overtempFlueGasHeatExch: "Perkaitęs išmetamųjų dujų šilumokaičio variklis",
                        rebootMainBoard: "Perkraukite pagrindinę plokštę",
                        rebootNow: "Perkrauti dabar",
                        utcOffset: "UTC offset",
                        location: "Lokacija",
                        network: "Tinklas",
                        ip: "IP",
                        mask: "Mask",
                        gateway: "Vartai",
                        dns: "DNS",
                        pcb: "PCB",
                        firmware: "Programinė įranga",
                        mainControlUnit: "Pagrindinis valdymo blokas",
                        bioguard: "Bioguard",
                        biotronicEngine1: "Biotronic variklis 1",
                        biotronicEngine2: "Biotronic variklis 2",
                        h2sEngine1: "H₂S variklis 1",
                        h2sEngine2: "H₂S variklis 2",
                        bioIdEngine1: "Bio id variklis 1",
                        bioIdEngine2: "Bio id variklis 2",
                        modificationsToMachine: "Mašinos modifikacijos",
                        name: "Vardas",
                        normal: "Normalus",
                        lowError: "Žemas (klaida)"
                    },
                    feeding: {
                        label: "Maitinimas",
                        feedingVolume: "maitinimo lygis",
                        feedingCyclesPerDay: "maitinimo dienos ciklas",
                        feedingBalance: "maitinimo balansas",
                        autoFeedingActive: "Auto maitinimas aktyvus",
                        autoFeedingStartHour: "Automatinio maitinimo pradžios laikas",
                        exitPumpRunning: "Išpumpuokite rankiniu būdu",
                        feedingCycle: "Maitinimo ciklas",
                        onlyInput: "Tik įsiurbimas",
                        onlyOutput: "Tik išsiurbimas",
                        startFeeding: "Pradėti maitinimą",
                        stopFeeding: "Sustabdyti maitinimą",
                        pumpOutFlowRate: "Pageidaujamas išpumpavimo srautas",
                        kiloPerPulse: "Srauto matuoklio impulsas",
                        intakeTimeout: "Įsiurbimas baigėsi",
                        averageDailyFeeding: "Kasdienio maitinimo mėnesio vidurkis",
                        retentionTime: "Sulaikymo laikas",
                        statusValvePumpIn: "Valve fresh in - 2",
                        statusValvePumpOut: "Išėjmo vožtuvas reaktoriuje - 3",
                        statusValveRetourReactor: "Valve retour reactor - 4",
                        valveCurrentActuator: "Vožtuvo srovė",
                        lastFeeding: "Dabartinis – paskutinis",
                        startHourLastFeeding: "Paskutinio maitinimo pradžios laikas",
                        feedingStatus: "Maitinimo būssena",
                        feedingError: "Maitinimo klaidos",
                        intakePumpTime: "Įsiurbimo laikas",
                        intakePumpAmount: "Įsiurbimo kiekis",
                        exitPumpTime: "Išėjimo laikas",
                        exitPumpAmount: "Išėjimo kiekis",
                        feedingHistory: "Maitinimo istorija",
                        startedOn: "Prasidėjo",
                        temperature: "Temperatūra",
                        reactorLevel: "reaktoriaus lygis",
                        balance: "Balansas",
                        acknowledge: "Klaidos patvirtinimas",
                        intakeSpeed: "įsiurbimo greitis",
                        balanceWarning: "Įspėjimas, tai nepradės normalaus maitinimo ciklo, o į reaktorių pumpuos likusią balanso vertę",
                        automaticFeedingSettings: "Automatinio maitinimo nustatymai",
                        manualFeeding: "Rankinis maitinimas",
                        pumpSettings: "Pumpavimo nustatymai",
                        status: {
                            "0": "Tuščia eiga",
                            "1": "Lygio jutimas",
                            "2": "Exit",
                            "3": "Įsiurbimas",
                            "5": "Defrost",
                            "6": "Dujų paleidimas",
                            "7": "Rankinis išėjimas",
                            "10": "Paleidimas",
                            "2.001": "Exit: Išankstinis maišymas",
                            "2.002": "Exit",
                            "2.003": "Exit: Atidarykite išėjimo vožtuvą",
                            "2.004": "Exit: Atidarykite išėjimo vožtuvą",
                            "2.005": "Exit: Paleidžiamas išėjimo siurblys",
                            "2.006": "Exit",
                            "2.007": "Exit: Mėšlo siurbimas",
                            "2.008": "Exit: Stop exit",
                            "2.009": "Exit: Stop exit",
                            "2.010": "Exit: Uždarykite išėjimo vožtuvą",
                            "2.011": "Exit: Uždarykite išėjimo vožtuvą",
                            "2.012": "Exit: Stop exit",
                            "3.001": "Įsiurbimas: Ruošiama",
                            "3.002": "Įsiurbimas: Ruošiama",
                            "3.003": "Įsiurbimas: Ruošiama",
                            "3.004": "Įsiurbimas: Mėšlo siurbimas",
                            "3.005": "Įsiurbimas: Mėšlo siurbimas",
                            "3.006": "Įsiurbimas: Baigiamas",
                            "3.007": "Įsiurbimas: Baigiamas",
                            "3.008": "Įsiurbimas: Stop pump",
                            "3.009": "Įsiurbimas: Stop pump",
                            "3.010": "Įsiurbimas: Stop pump",
                            "3.011": "Įsiurbimas: Stop pump",
                            "3.012": "Įsiurbimas: Finishing",
                            "5.001": "Defrost: Testuojamas įsiurbimas",
                            "5.002": "Defrost: Testuojamas įsiurbimas",
                            "5.003": "Defrost: Testuojamas įsiurbimas",
                            "5.004": "Defrost: Testuojamas įsiurbimas",
                            "5.005": "Defrost: Stabdomas siurbimas",
                            "5.006": "Defrost: Atidaromas išėjimo vožtuvas",
                            "5.007": "Defrost: Atidaromas išėjimo vožtuvas",
                            "5.008": "Defrost: Testing exit",
                            "5.009": "Defrost: Testing exit",
                            "5.010": "Defrost: Testing exit",
                            "5.011": "Defrost: Testing exit",
                            "5.012": "Defrost: Testing exit",
                            "5.013": "Defrost: Išėjimo vožtuvo uždarymas",
                            "5.014": "Defrost: Išėjimo vožtuvo uždarymas",
                            "5.015": "Defrost: Finishing",
                            "6.001": "Dujų paleidimas: Atidarykite išėjimo vožtuvą",
                            "6.002": "Dujų paleidimas: Atidarykite išėjimo vožtuvą",
                            "6.003": "Dujų paleidimas: Uždarykite išėjimo vožtuvą",
                            "6.004": "Dujų paleidimas: Uždarykite išėjimo vožtuvą",
                            "6.005": "Dujų paleidimas",
                            "7.001": "Rankinis išėjimas: Atidarykite išėjimo vožtuvą",
                            "7.002": "Rankinis išėjimas: Atidarykite išėjimo vožtuvą",
                            "7.003": "Rankinis išėjimas: Mėšlo siurbimas",
                            "7.004": "Rankinis išėjimas: Sustabdyti siurblį",
                            "7.005": "Rankinis išėjimas: Uždarykite išėjimo vožtuvą",
                            "7.006": "Rankinis išėjimas: Uždarykite išėjimo vožtuvą",
                            "7.007": "Rankinis išėjimas: Baigiamas",
                            "7.008": "Rankinis išėjimas: Baigiamas",
                            "10.001": "Paleidimas: Uždarykite vožtuvą 2",
                            "10.002": "Paleidimas: Uždarykite vožtuvą 2",
                            "10.003": "Paleidimas: Uždarykite išėjimo vožtuvą",
                            "10.004": "Paleidimas: Uždarykite išėjimo vožtuvą",
                            "10.005": "Paleidimas: Uždarykite vožtuvą 4",
                            "10.006": "Paleidimas: Uždarykite vožtuvą 4",
                            "10.007": "Paleidimas: Tikrinamas elektros ryšys",
                            "10.008": "Paleidimas: Tikrinamas elektros ryšys",
                            "10.009": "Paleidimas"

                        }
                    },
                    users: {
                        label: "Vartotojai",
                        customers: "Susieti klientai",
                        technicians: "Susieti technikai",
                        resellers: "Susieti perpardavėjai",
                    },
                    recommendations: {
                        label: "Rekomendacijos",
                    },
                    documents: {
                        label: "Dokumentai"
                    },
                    warnings: {
                        "W001": "W001: Energijos suvartojimas viršija ribą per pastarąsias 24 valandas (72 kWh 10-44kW įrenginiui, 250 kWh 60-74 kW įrenginiui)",
                        "W002": "W002: Reaktoriaus temperatūra smarkiai nukrito",
                        "W003": "W003: Servisas per 48 valandas",
                        "W004": "W004: Reikia pakeisti anglies filtrą 1 variklyje",
                        "W005": "W005: Reikia pakeisti anglies filtrą 2 variklyje",
                        "W006": "W006: Dujų buferio matavimas užšalo",
                        "W007": "W007: Dujų buferio matavimo defektas",
                        "W008": "W008: Vakar nemaitintas",
                        "W009": "W009: Siurblio greitis paskutinio šėrimo metu buvo 50% didenis nei praėjusio mėnesio vidurkis",
                        "W010": "W010: H₂S vertė per didelė (>1500)",
                        "W011": "W011: Aeracija yra 60",
                        "W012": "W012: Aeracija yra mažesnė arba lygi 3",
                        "W013": "W013: Vakar efektyvumas buvo 0, o automatinis tiekimas įjungtas",
                        "W014": "W014: H₂S reaktoriaus matavimas – skirtumas viršija 400ppm",
                        "W015": "W015: Oro tiekimas labai aukštas - turėti omenyje dujų kokybę",
                        "W016": "W016: H₂S matavimas nevyko 24 valandas",
                        "W017": "W017: Per mažai maišytuvo alyvos",
                        "W018": "W018: Mažai variklio alyvos",
                        "W020": "W020: Žemas vandens slėgis",
                        "W104": "W104: H₂S vertė per didelė (>200) arba aeracija = 60 arba 3",
                        "W105": "W105: Anglies filtro apkrova per didelė",
                        "W114": "W114: Efektyvumas vakar nebuvo 0, o automatinis tiekimas išjungtas",
                    }
                }
            }
        }
    });

export default i18n;
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new e.Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="100c5836-efc0-57f8-8add-c154d8c91dfd")}catch(e){}}();
//# debugId=100c5836-efc0-57f8-8add-c154d8c91dfd
