import './bootstrap';
import '../css/app.css';
import './i18n';

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

import { Provider } from 'react-redux'
import store from './store'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as Sentry from '@sentry/react';

// Sentry.init({
//     dsn: 'https://6b995fec9b4acbe64e3c8ae70ae8c1bc@o4506620086190080.ingest.us.sentry.io/4506620091432960', // Replace with your Sentry DSN
//     tracesSampleRate: 1.0, // Optional, adjust to control the percentage of transactions captured
// });

Sentry.init({
    dsn: "https://10873ef1c494f5bf56e4eba3fcd6485a@o4506620086190080.ingest.us.sentry.io/4508420729995264",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        "localhost",          // For local development
        /^https:\/\/admin\.biolectric\.be/ // Backend domain for production
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({ el, App, props }) {
        const root = createRoot(el);

        root.render(
            <Sentry.ErrorBoundary fallback={
                <div>
                    <h1>Something went wrong</h1>
                    <p>Please contact it@biolectric.be</p>
                    <button onClick={() => window.location.reload()}>Reload</button>
                </div>
            }>
                <Provider store={store}>
                    <App {...props} />
                    <ToastContainer
                        toastStyle={{ boxShadow: "none", border: '1px solid #F8F8F8' }}
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        closeOnClick={true}
                        pauseOnHover={true}
                        theme="light"
                        draggable={false}
                    />
                </Provider>
            </Sentry.ErrorBoundary>
        );
    },
    progress: {
        color: '#4B5563',
    },
});
