import { createSlice } from '@reduxjs/toolkit';

import produce from 'immer';

const initialState = {
  wkk: {},
  goz: {},
};

const machineSlice = createSlice({
  name: 'machine',
  initialState,
  reducers: {
    setWkkFasts(state, action) {
      const data = action.payload;

      return produce(state, draft => {
        draft.wkk = {
          ...draft.wkk,
          power: data[11],
          power2: data[12],
          throttleValve: data[18],
          throttleValve2: data[19],
          rpm: data[20],
          rpm2: data[21],
          lambda: data[38],
          lambda2: data[39],
          engineStatus: data[22],
          engineStatus2: data[23],
          engineError: data[24],
          engineError2: data[25],
          producedEnergy: data[15],
          producedEnergy2: data[16],
          gasUsage: data[48],
          gasUsage2: data[49],
          waterTempMotorOut: data[59],
          waterTempMotorOut2: data[60],
          throttlePosition: data[40],
          throttlePosition2: data[41],
          exitPumpRunning: data[28],
          feedingStatus: data[32],
          feedingError: data[46],
          feedingCycleLevel: data[33],
          exitPumpTime: data[34],
          exitPumpAmount: data[35],
          intakePumpTime: data[36],
          intakePumpAmount: data[37],
          statusValvePumpIn: data[26],
          statusValvePumpOut: data[27],
          statusValveRetourReactor: data[47],
          valveCurrentActuator: data[7],
          gasBufferLevel: data[5],
          voltagePerPhase1: data[8],
          voltagePerPhase2: data[9],
          voltagePerPhase3: data[10],
          frequencyPerPhase: data[44],
          angleBetweenPhase1And2: data[45],
          powerConsumption: data[13],
          consumedEnergy: data[17],
          reactorTemp: data[1],
          waterTempIn: data[2],
          waterTempOut: data[3],
          coolingWaterValvePosition: data[54],
          coolingWaterValvePosition2: data[55],
          hydrostaticPressure: data[4],
          mixer: data[30],
          batteryVoltage: data[14],
          controlCardTemperature: data[6],
          waterPressure: data[52],
          statusCoolingValve: data[31],
          torchStatus: data[61],
          servoAutoManual: data[62],
          servoAutoManual2: data[63],
          engineTemp: data[42],
          engineTemp2: data[43],
          valve2: data[26],
          valve3: data[27],
          valve4: data[47],
        };
      });
    },
    setWkkSlows(state, action) {
      const data = action.payload;
      return produce(state, draft => {
        draft.wkk = {
          ...draft.wkk,
          maxPower: data[78],
          maxPower2: data[79],
          desiredPower: data[23],
          desiredPower2: data[24],
          operatingHours: data[6],
          operatingHours2: data[7],
          totalOperatingHours: data[48],
          totalOperatingHours2: data[49],
          hoursUntilService: data[31],
          hoursUntilService2: data[32],
          engineType: data[80],
          engineType2: data[81],
          idBio: data[86],
          idBio2: data[87],
          idH2s: data[88],
          idH2s2: data[89],
          bioId: data[90],
          bioId2: data[91],
          h2sLevelReactor: data[4],
          h2sLevelReactor2: data[51],
          h2sLevelMotor: data[37],
          h2sLevelMotor2: data[38],
          filterLoad: data[39],
          filterLoad2: data[40],
          h2sSensorFirmwareVersion: data[56],
          h2sSensorFirmwareVersion2: data[57],
          biotronicFirmwareVersion: data[54],
          biotronicFirmwareVersion2: data[55],
          bioguardVersion: data[77],

          siloType: data[59],
          feedingVolume: data[12],
          feedingCyclesPerDay: data[47],
          reactorLevel: data[14],
          autoFeedingStartHour: data[30],
          autoFeedingActive: data[16],
          intakeTimeout: data[28],
          feedingBalance: data[35],
          pumpOutFlowRate: data[15],
          kiloPerPulse: data[25],
          startHourLastFeeding: data[73],

          minGasBufferLevel: data[34],
          maxGasBufferLevel: data[33],
          defaultMinGasBufferLevel: data[95],
          defaultMaxGasBufferLevel: data[94],
          airInjectionTime: data[22],
          maxAirInjectionTime: data[74],
          oilInjectionsEveryXMin: data[60],
          oilInjectionsForXSec: data[61],
          torchType: data[21],
          frostIndex: data[17],

          reactorTempTarget: data[13],
          coolingWaterTemp: data[50],
          antiFreezeIndex: data[17],
          extraHeating: data[18],
          mixEveryXMinutes: data[41],
          mixForXSeconds: data[42],
          blockMixingFrom: data[43],
          blockMixingTo: data[44],
          blockMixingFrom2: data[45],
          blockMixingTo2: data[46],

          installationIdentifier: data[1],
          firmwareVersion: data[2],
          printId: data[70],
          cooperationContract: data[27],
          ip: data[8],
          mask: data[9],
          gate: data[10],
          dns: data[11],
          slowTensionError: data[19],
          overTemperatureFlueGasHeatExchanger: data[20],
          rebootMainPrint: data[53],
          mixerMaxPower: data[82],
          utcOffset: data[83],
          amountOfEngines: data[5],
          upsEnabled: data[76],
          easyStart: data[64],
          easyStart2: data[65],
          foamBeaterInstalled: data[66],
          oilInjectionsInstalled: data[69],
          coolwaterValveInstalled: data[75],
          monitoringsRelais: data[71],

          hostname: data[3],
          permanence: data[96],

          // enableNcrfg: data[84],
          // droopSetting: data[67],
          // frequencyThreshold: data[68],
          // offDelay: data[90],
        };
      });
    },
    setGozFasts(state, action) {
      const data = action.payload;
      return produce(state, draft => {
        draft.goz = {
          ...draft.goz,
          alarm: data[38],
          gatekeeperValve: data[39],
          state: data[22],
          tempGas: data[59],
          tempOilCooler: data[60],
          tempReactor: data[1],
          TempReactorIn: data[2],
          TempReactorOut: data[3],
          gasBufferLevel: data[5],
          ownConsumption: data[17],
          heatPumpConsumption: data[15],
          waterPressure: data[52],
          reactorLevel: data[4],
          torch: data[61],
          foamBeater: data[18],
          release: data[19],
          gasValve1: data[20],
          gasValve2: data[21],
          airInjection: data[23],
          gasCooler: data[25],
          blower: data[31],
          intakePump: data[29],
          mixer: data[30],
          exitValve: data[27],
          ownConsumptionSetting: data[40],
          heatPump: data[41],
        };
      });
    },
    setGozSlows(state, action) {
      const data = action.payload;
      return produce(state, draft => {
        draft.goz = {
          ...draft.goz,
          carbonFilter: data[64],
          airInjectionTime: data[22],
          maxAirInjectionTime: data[74],
          h2sReactor: data[4],
          h2sCarbonFilter1: data[37],
          h2sCarbonFilter2: data[38],
          // h2sSkid: data[51],
          // h2sReactorHost: data[12],
          loadCarbonFilter1: data[39],
          loadCarbonFilter2: data[40],
        };
      });
    },
    setClearData: (state) => {
      // Reset the state to its initial value
      return initialState;
    },
    // #TODO this updates the machine variable, but its not showing changes on the page, only after fetch slow/fast
    updateMachine: (state, action) => {
      const { type, id, value } = action.payload;

      return produce(state, draft => {
        draft[type][id] = value;
      });
    },
  },
});

export const { setWkkFasts, setGozFasts , setWkkSlows, setGozSlows, setClearData } = machineSlice.actions;

export default machineSlice.reducer;
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new e.Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="23d7c03c-3718-5c42-8063-d99ec202fb66")}catch(e){}}();
//# debugId=23d7c03c-3718-5c42-8063-d99ec202fb66
