import { configureStore } from '@reduxjs/toolkit'
import machineReducer from '@/reducers/machine'

export default configureStore({
    reducer: {
        machine: machineReducer,
    },
})
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new e.Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="cb869946-bb87-5dea-b8ab-64f768c55449")}catch(e){}}();
//# debugId=cb869946-bb87-5dea-b8ab-64f768c55449
